import React from "react";
import {
  MDBFooter,
  MDBTypography,
  MDBCol,
  MDBRow,
  MDBIcon,
} from "mdb-react-ui-kit";
import { getYear } from "../../common/util";

const Footer = (props) => {
  return (
    <>
      {props.camara && (
        <MDBFooter
          className="text-center text-white mt-4"
          bgColor="secondary "
          style={{ position: "relative" }}
        >
          <div className="p-3">
            <MDBRow>
              <MDBCol size={12} className="fs-4">
                {props.camara.Lema}
              </MDBCol>
              <MDBCol size={12}>
                <MDBRow className="d-flex justify-content-center">
                  <MDBCol size={12} className="px-5">
                    <MDBTypography
                      tag="div"
                      className="display-1 pb-2 mb-2 border-bottom text-center"
                    ></MDBTypography>
                  </MDBCol>
                  <MDBCol size={8}>
                    <MDBIcon fas icon="map-marker" /> {props.camara.Endereco}
                  </MDBCol>
                  <MDBCol className="my-2" size={8}>
                    <MDBIcon fas icon="envelope" /> {props.camara.Email}
                  </MDBCol>
                  <MDBCol size={8}>
                    <MDBIcon fas icon="clock" />{" "}
                    {props.camara.HorarioFuncionamento}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </div>
          <div
            className="text-center p-3"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
          >
            © {getYear()} Copyright{" "}
            <a className="text-white" href="@">
              Lima Empreedimentos e Serviços.
            </a>
          </div>
        </MDBFooter>
      )}
    </>
  );
};

export default Footer;
