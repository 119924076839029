import React, { createContext, useContext, useState, useEffect } from "react";
import { getCityByUrl } from "../common/util";
import { getCamaraByCity, getSessaoByDataAndCamaraId } from "../services/api";
import { notify } from "../common/toastConfig";

const CamaraContext = createContext();

export const useCamaraContext = () => {
  return useContext(CamaraContext);
};

export const CamaraProvider = ({ children }) => {
  const [currentSessao, setCurrentSessao] = useState(null);
  const [camara, setCamara] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchCamaraData = async () => {
    try {
      const city = getCityByUrl();
      const data = await getCamaraByCity(city);
      setCamara(data);
    } catch (error) {
      notify("Erro ao buscar informações da câmara municipal", "danger");
    }
  };

  const fetchCurrentSessaoData = async () => {
    try {
      const moment = require("moment-timezone");
      const currentStringDate = moment()
        .tz("America/Sao_Paulo")
        .format("YYYY-MM-DD");

      if (camara) {
        const data = await getSessaoByDataAndCamaraId(
          currentStringDate,
          camara.id
        );
        setCurrentSessao(data);
      }
    } catch (error) {
      notify("Erro ao buscar informações da sessão atual", "danger");
    }
  };

  useEffect(() => {
    fetchCamaraData();
    fetchCurrentSessaoData();
    setLoading(false);
  }, []);

  const contextValue = {
    camara,
    currentSessao,
    loading,
    fetchCamaraData,
    fetchCurrentSessaoData,
  };

  return (
    <CamaraContext.Provider value={contextValue}>
      {children}
    </CamaraContext.Provider>
  );
};
