import "./ReadMateria.css";
import React, { useEffect, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBCarousel,
  MDBCarouselItem,
} from "mdb-react-ui-kit";
import { convertStringDateForBrDate } from "../../../common/util";
import CardParlamentar from "../../../components/CardParlamentar/CardParlamentar";
import { getMateriaSituationById } from "../../../services/api";

const ReadMateria = (props) => {
  const [materiaSituation, setMateriaSituation] = useState(null);
  const materia = props.materia;
  const sessao = props.sessao;

  useEffect(() => {
    if (props.materia) {
      const intervalId = setInterval(async () => {
        const materiaSituationResponse = await getMateriaSituationById(
          props.materia.id
        );
        setMateriaSituation(materiaSituationResponse.situation);

        if (
          materiaSituationResponse.situation === "Aprovada" ||
          materiaSituationResponse.situation === "Reprovada"
        )
          clearInterval(intervalId);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [props.materia]);

  return (
    materia && (
      <>
        <MDBContainer
          className={`d-flex flex-column justify-content-center align-items-center ms-3 ${
            props.IsParlamentar ? "" : "mt-4"
          }`}
          id="readMateria"
        >
          <MDBRow className="shadow-sm rounded-9 text-center text-white fw-bold justify-content-center align-items-center py-1 topbar-info">
            <MDBCol
              size="12"
              className="d-flex align-items-center justify-content-between"
            >
              <span>
                <MDBIcon
                  icon="book-reader"
                  color="white"
                  className="fs-3 m-2"
                />
                <a className="text-white fs-3">LEITURA DE MATÉRIA</a>
              </span>
              <a className="text-white fs-2">SESSÃO Nº: {sessao.Numero}</a>
            </MDBCol>
          </MDBRow>

          <MDBRow className="justify-content-center align-items-center">
            <MDBCol
              className="rounded-9 text-center text-white d-flex flex-column justify-content-center align-items-center fw-bold m-2 topContainers"
              size="5"
            >
              <MDBRow>
                <h1 className="fw-bold display-1">{materia.Numero}</h1>
              </MDBRow>
              <MDBRow>
                <h5 className="pt-5 fw-bold fs-1">
                  {materia.Tipo.toUpperCase()}
                </h5>
              </MDBRow>
              <MDBRow>
                <h5 className="pt-5 fs-2 fw-bold">
                  {convertStringDateForBrDate(materia.Data)}
                </h5>
              </MDBRow>
            </MDBCol>

            <MDBCol
              className="rounded-9 text-center text-white fw-bold mt-2 m-2 topContainers bg-white"
              size="6"
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center align-items-center containers-topBarInfo">
                  <MDBIcon
                    icon="info-circle"
                    color="white"
                    className="fs-3 m-2"
                  />
                  <p className="text-white fw-bold fs-3 mt-3">
                    SOBRE A MATÉRIA
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="sobreMateriaDescricao">
                  <p>{materia.Resumo}</p>
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <>
              {materiaSituation === null && (
                <MDBCol
                  className="d-flex justify-content-center align-items-center rounded-9 text-center text-white fw-bold mt-2 m-2 bg-primary bottom-Containers"
                  md={5}
                >
                  CARREGANDO...
                </MDBCol>
              )}

              {materiaSituation === "Reprovada" && (
                <MDBCol
                  className="d-flex justify-content-center align-items-center rounded-9 text-center text-white fw-bold mt-2 m-2 bg-danger bottom-Containers"
                  md={5}
                >
                  <MDBIcon
                    icon="info-circle"
                    color="white"
                    className="fs-1 m-2"
                  />
                  <a className="text-white fw-bold display-6">
                    MATÉRIA REPROVADA
                  </a>
                </MDBCol>
              )}

              {materiaSituation === "Aprovada" && (
                <MDBCol
                  className="d-flex justify-content-center align-items-center rounded-9 text-center text-white fw-bold mt-2 m-2 bg-success bottom-Containers"
                  md={5}
                >
                  <MDBIcon
                    icon="info-circle"
                    color="white"
                    className="fs-1 m-2"
                  />
                  <a className="text-white fw-bold display-6">
                    MATÉRIA APROVADA
                  </a>
                </MDBCol>
              )}

              {materiaSituation === "Em votação" && (
                <MDBCol
                  className="d-flex justify-content-center align-items-center rounded-9 text-center text-white fw-bold mt-2 m-2 bg-primary bottom-Containers"
                  md={5}
                >
                  <MDBIcon
                    icon="info-circle"
                    color="white"
                    className="fs-1 m-2"
                  />
                  <a className="text-white fw-bold display-6">
                    MATÉRIA EM VOTAÇÃO
                  </a>
                </MDBCol>
              )}

              {materiaSituation === "Não votada" && (
                <MDBCol
                  className="d-flex justify-content-center align-items-center rounded-9 text-center text-white fw-bold mt-2 m-2 bg-warning bottom-Containers"
                  md={5}
                >
                  <MDBIcon
                    icon="info-circle"
                    color="white"
                    className="fs-1 m-2"
                  />
                  <a className="text-white fw-bold display-6">
                    MATÉRIA NÃO VOTADA
                  </a>
                </MDBCol>
              )}
            </>

            <MDBCol
              className="d-flex bg-white rounded-9 text-center text-white fw-bold m-2 bottom-Containers flex-column"
              md={6}
            >
              <MDBRow>
                <MDBCol className="d-flex justify-content-center align-items-center containers-topBarInfo">
                  <MDBIcon
                    icon="user-friends"
                    color="white"
                    className="fs-3 m-2"
                  />
                  <p className="text-white fw-bold fs-3 mt-3">AUTORES</p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol
                  className="d-flex justify-content-center"
                  style={{
                    height: "15vh",
                  }}
                >
                  <MDBCarousel className="d-flex justify-content-center">
                    <MDBCol
                      className="carouselRead"
                      style={{
                        width: "35vw",
                      }}
                    >
                      {materia.ParlamentarMateria.map(
                        (groupedItem, nestedIndex) => (
                          <MDBCarouselItem
                            itemId={nestedIndex + 1}
                            key={nestedIndex + 1}
                            style={{
                              position: "absolute",
                            }}
                          >
                            <CardParlamentar
                              background={"#5BA3B9"}
                              width="50vw"
                              parlamentar={groupedItem.Parlamentar}
                            />
                          </MDBCarouselItem>
                        )
                      )}
                    </MDBCol>
                  </MDBCarousel>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </>
    )
  );
};

export default ReadMateria;
