import { useState, useEffect } from "react";

export const goToLogin = () => {
  const isControlador =
    window.location.pathname.split("/")[2] === "controlador";

  if (isControlador) goToLoginControlador();
  else goToLoginUser();
};

export const goToLoginUser = () => {
  const city = window.location.pathname.split("/")[1];
  if (window.location.pathname !== `/${city}/login`)
    window.location = `/${city}/login`;
};

export const goToLoginControlador = () => {
  const city = window.location.pathname.split("/")[1];
  if (window.location.pathname != `/${city}/controlador/login`)
    window.location = `/${city}/controlador/login`;
};

export const getCityByUrl = () => {
  return window.location.pathname.split("/")[1];
};

export const getCamaraIdFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("user")).camaraId;
};

export const getCamaraIdFromLocalStorageControlador = () => {
  return JSON.parse(localStorage.getItem("controllerUser")).camaraId;
};

export function useLocalStorageState(key, defaultValue) {
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export function getCurrentStringDate() {
  const moment = require("moment-timezone");
  const currentStringDate = moment()
    .tz("America/Sao_Paulo")
    .format("YYYY-MM-DD");
  return currentStringDate;
}

export function convertStringDateForBrDate(inputDate) {
  const dateParts = inputDate.split("-");

  if (dateParts.length !== 3) {
    return "Invalid date format";
  }

  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

export function getYear() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return currentYear;
}
export const useFullScreenToggle = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const enterFullScreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
    setIsFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setIsFullScreen(false);
  };

  const toggleFullScreen = () => {
    const elem = document.documentElement;
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const fullScreenCheck = async () => {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      )
        setIsFullScreen(!!isFullScreen);

      return isFullScreen;
    };

    fullScreenCheck();
    document.addEventListener("DOMContentLoaded", fullScreenCheck);

    document.addEventListener("fullscreenchange", fullScreenCheck);
    document.addEventListener("mozfullscreenchange", fullScreenCheck);
    document.addEventListener("webkitfullscreenchange", fullScreenCheck);
    document.addEventListener("msfullscreenchange", fullScreenCheck);

    return () => {
      document.removeEventListener("DOMContentLoaded", fullScreenCheck);
      document.removeEventListener("fullscreenchange", fullScreenCheck);
      document.removeEventListener("mozfullscreenchange", fullScreenCheck);
      document.removeEventListener("webkitfullscreenchange", fullScreenCheck);
      document.removeEventListener("msfullscreenchange", fullScreenCheck);
    };
  }, [isFullScreen]);

  return { isFullScreen, toggleFullScreen };
};

export function removeSpecialCharacters(phrase) {
  return phrase.replace(/[^\w\s]/gi, "");
}

export function checkParameterBooleanIsEnabled(parameter) {
  return parameter && parameter.Data == "1";
}

export function returnToHomePage() {
  window.location.href = "/";
}

export function formatNumber(number, width) {
  return number.toString().padStart(width, "0");
}

export const isPainel = () => {
  return window.location.pathname.split("/")[2] === "painel";
};

export const formatSecondsToTimer = (time) => {
  time = time || 0;

  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, "0");
  const seconds = (time % 60).toString().padStart(2, "0");
  return `${minutes}:${seconds}`;
};

export const reloadScreen = () => {
  return window.location.reload();
};

export const getStoredEmailUser = () => {
  const storedUser = localStorage.getItem("user");

  if (storedUser) {
    const loggedUser = JSON.parse(storedUser);

    const userEmail = loggedUser.email;

    return userEmail;
  } else {
    return null;
  }
};

export const loadParlamentarName = (parlamentar) => {
  return parlamentar.nickname ?? parlamentar.name;
};
