import { useWakeLock } from "react-screen-wake-lock";
import {
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";

function Component() {
  const { isSupported, released, request, release } = useWakeLock({});
  const [modal, setModal] = useState(false);

  function handleClick() {
    released === false ? release() : request();
    setModal(false);

    const appElement = document.documentElement;

    if (appElement.requestFullscreen) {
      appElement.requestFullscreen();
    } else if (appElement.mozRequestFullScreen) {
      appElement.mozRequestFullScreen();
    } else if (appElement.webkitRequestFullscreen) {
      appElement.webkitRequestFullscreen();
    } else if (appElement.msRequestFullscreen) {
      appElement.msRequestFullscreen();
    }
  }

  useEffect(() => {
    if (isSupported) {
      if (!document.fullscreenElement && 
          !document.mozFullScreenElement && 
          !document.webkitFullscreenElement && 
          !document.msFullscreenElement) {
          setModal(true);
      }
    }
  }, []);

  return (
    <MDBModal
      show={modal}
      className="modalPresence"
      nonInvasive={true}
      tabIndex="-1"
    >
      <MDBModalDialog centered size="sm">
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>
              <MDBIcon fas icon="expand-arrows-alt" /> TELA CHEIA
            </MDBModalTitle>
            <MDBBtn
              className="btn-close"
              color="none"
              onClick={() => setModal(false)}
            ></MDBBtn>
          </MDBModalHeader>
          <MDBModalBody>Habilitar modo tela cheia?</MDBModalBody>

          <MDBModalFooter className="justify-content-center">
            <MDBBtn onClick={handleClick} color="success">
              <MDBIcon fas icon="check-circle" /> HABILITAR
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default Component;
