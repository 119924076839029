import "../../index.css";
import "./Sessoes.css";
import React, { useState, useEffect } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import {
  getSessoesControlador,
  createSessaoControlador,
  getSessaoByDataAndCamaraId,
  getComissaoByCamaraId,
  getAutoFillSessao,
  getParameterByCamaraIdAndName,
} from "../../services/api";
import { notify } from "../../common/toastConfig";
import Card from "../../components/Card/Card";
import Pagination from "../../components/Pagination/Pagination";
import { getCityByUrl } from "../../common/util";
import Spinner from "../../components/Spinner/Spinner";
import { useCamaraContext } from "../../context/camaraContext";
import { ParameterName } from "../../Enums/ParameterEnums";

const Sessoes = () => {
  const { camara } = useCamaraContext();
  const [loading, setLoading] = useState(true);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [sessoes, setSessoes] = useState([]);
  const [sessaoAtual, setSessaoAtual] = useState(null);
  const [filterValues, setfilterValues] = useState({
    numero: "",
    tipo: "",
    situacao: "",
    data: "",
    comissaoId: "",
    pagina: 0,
  });
  const [formValues, setformValues] = useState({
    numero: "",
    tipo: "",
    descricao: "",
    data: "",
    comissaoId: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [comissoes, setcomissoes] = useState([]);
  const [parameterSessionNameSuggestion, setParameterSessionNameSuggestion] =
    useState(null);

  const moment = require("moment-timezone");
  const currentStringDateBrazil = moment()
    .tz("America/Sao_Paulo")
    .format("DD/MM/YYYY");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setfilterValues({ ...filterValues, [name]: value });
  };

  const handleformCriarSessaoChange = (event) => {
    const { name, value } = event.target;
    setformValues({ ...formValues, [name]: value });
  };

  async function handlePaginaChange(value) {
    const name = "pagina";
    setfilterValues({ ...filterValues, [name]: value });
  }

  async function handleBtnFilter(event) {
    fetchData();
  }

  function handleBtnCriarSessao() {
    setShowForm(!showForm);
  }

  function handleBtnClearNovaSessao() {
    setformValues({
      numero: "",
      tipo: "",
      descricao: "",
      data: "",
      comissaoId: "",
    });
  }

  async function handleBtnSalvarSessao() {
    setLoading(true);
    const response = await createSessaoControlador(formValues);

    if (response.data.status === "success") {
      notify(response.data.message, "success");

      setTimeout(() => {
        window.location.href = `/${getCityByUrl()}/controlador/sessoes/${
          response.data.sessao.id
        }`;
      }, 1000);
    } else notify(response.data.message, "danger");

    setLoading(false);
  }

  async function fetchData() {
    if (camara) {
      setLoading(true);
      try {
        const response = await getSessoesControlador(filterValues);
        setSessoes(response.data.sessoes);
        setTotalPaginas(response.data.totalPaginas);

        if (response.data.sessoes && response.data.sessoes.length > 0) {
          const moment = require("moment-timezone");
          const currentStringDate = moment()
            .tz("America/Sao_Paulo")
            .format("YYYY-MM-DD");
          const responseSessaoAtual = await getSessaoByDataAndCamaraId({
            data: currentStringDate,
            camaraId: response.data.sessoes[0].CamaraId,
          });

          if (responseSessaoAtual.data.sessao) {
            setSessaoAtual(responseSessaoAtual.data.sessao);
          }
        }

        const comissoesResponse = await getComissaoByCamaraId(camara.id);
        setcomissoes(comissoesResponse);

        const parameterSessionNameSuggestionResponse =
          await getParameterByCamaraIdAndName(
            camara.id,
            ParameterName.SessionNameSuggestion
          );

        setParameterSessionNameSuggestion(
          parameterSessionNameSuggestionResponse
        );
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [camara]);

  useEffect(() => {
    fetchData();
  }, [filterValues.pagina]);

  let enableCreateSessaoInput = formValues.tipo;

  useEffect(() => {
    loadAutoFill();
  }, [formValues.tipo]);

  async function loadAutoFill() {
    if (parameterSessionNameSuggestion) {
      if (formValues.tipo != "") {
        const autoFillResponde = await getAutoFillSessao(
          formValues.tipo,
          camara.id
        );
        setformValues({ ...formValues, ["numero"]: autoFillResponde });
      } else {
        setformValues({ ...formValues, ["numero"]: "" });
      }
    }
  }

  return (
    <div id="sessoes">
      <Spinner show={loading} />
      <div className="filterSessoes">
        <h6 className="title">
          <MDBIcon fas icon="filter" /> Campos para pesquisa
        </h6>
        <MDBRow>
          <MDBCol sm="4" md="3">
            <label>
              <MDBIcon fas icon="spell-check" /> N° da sessão
            </label>
            <input
              value={filterValues.numero}
              onChange={handleInputChange}
              name="numero"
              type="text"
              class="form-control"
              placeholder="00/00"
            />
          </MDBCol>
          <MDBCol sm="4" md="3">
            <label>
              <MDBIcon fas icon="caret-down" /> Tipo
            </label>
            <select
              value={filterValues.tipo}
              onChange={handleInputChange}
              name="tipo"
              class="form-select"
              aria-label="Default select example"
            >
              <option value="" selected>
                Selecione
              </option>
              <option value="1">Extra-Ordinária</option>
              <option value="2">Audiência pública</option>
              <option value="3">Solene</option>
              <option value="4">Abertura de período legislativo</option>
              <option value="5">Encerramento de período legislativo</option>
              <option value="6">Intinerante</option>
              <option value="7">Sessão administrativa</option>
              <option value="8">Sessão especial</option>
              <option value="9">Ordinária</option>
            </select>
          </MDBCol>
          <MDBCol sm="4" md="3">
            <label>
              <MDBIcon fas icon="caret-down" /> Situação
            </label>
            <select
              value={filterValues.situacao}
              onChange={handleInputChange}
              name="situacao"
              class="form-select"
            >
              <option value="" selected>
                Selecione
              </option>
              <option value="1">Aberta</option>
              <option value="2">Fechada</option>
            </select>
          </MDBCol>
          <MDBCol sm="6" md="3">
            <label>
              <MDBIcon far icon="calendar-check" /> Data
            </label>
            <input
              value={filterValues.data}
              onChange={handleInputChange}
              name="data"
              type="date"
              class="form-control"
              placeholder="00/00"
            />
          </MDBCol>
          <MDBCol sm="6" md="3">
            <label>
              <MDBIcon icon="users" /> Comissão
            </label>
            <select
              value={filterValues.comissaoId}
              onChange={handleInputChange}
              name="comissaoId"
              class="form-select"
              aria-label="Default select example"
            >
              <option value="" selected>
                Selecione
              </option>
              {comissoes.map((item, index) => (
                <option value={item.id}>{item.Name}</option>
              ))}
            </select>
          </MDBCol>
          <MDBCol sm="6" md="9" className="d-flex justify-content-end">
            <MDBBtn
              href={`/${getCityByUrl()}/controlador/sessoes`}
              className="me-1 mt-4"
              color="warning"
            >
              <MDBIcon fas icon="times" /> Limpar filtros
            </MDBBtn>
            <MDBBtn
              onClick={handleBtnFilter}
              className="me-1 mt-4"
              color="info"
            >
              <MDBIcon fas icon="search" /> Filtrar
            </MDBBtn>
          </MDBCol>
        </MDBRow>
      </div>

      {sessaoAtual && (
        <>
          <MDBRow>
            <MDBCol md="8" className="mt-3">
              <h5 className="title pt-4">
                <MDBIcon fas icon="satellite-dish" /> Sessão Atual (
                {currentStringDateBrazil})
              </h5>
            </MDBCol>
          </MDBRow>
          <MDBCol md="12" className="mt-3">
            <Card
              color={"#0dcaf0"}
              url={`/${getCityByUrl()}/controlador/sessoes/${sessaoAtual.id}`}
              title={sessaoAtual.Numero}
              description={sessaoAtual.Descricao}
              notes={[
                sessaoAtual.Comissao && {
                  icon: "users",
                  value: sessaoAtual.Comissao.Name,
                },
                {
                  icon: "calendar-check",
                  value: new Date(sessaoAtual.Data).toLocaleDateString(
                    "pt-BR",
                    {
                      timeZone: "UTC",
                    }
                  ),
                },
                {
                  icon: "",
                  value: sessaoAtual.Tipo,
                },
                {
                  icon: "",
                  value: sessaoAtual.Situacao,
                },
              ]}
            />
          </MDBCol>
        </>
      )}

      <MDBRow>
        <MDBCol md="8" className="mt-3">
          <h5 className="title pt-4">
            <MDBIcon fas icon="poll" /> Sessões
          </h5>
        </MDBCol>
        <MDBCol md="4" className="mt-3 d-flex justify-content-end">
          <MDBBtn
            onClick={handleBtnCriarSessao}
            className="mt-4"
            color={showForm ? "danger" : "success"}
          >
            <MDBIcon fas icon={showForm ? "close" : "plus"} />
            {showForm ? " Fechar" : " Criar sessão"}
          </MDBBtn>
        </MDBCol>

        {showForm && (
          <div className="criarSessoes">
            <MDBRow>
              <MDBCol sm="3" md="3">
                <label>
                  <MDBIcon fas icon="caret-down" /> Tipo
                </label>
                <select
                  value={formValues.tipo}
                  onChange={handleformCriarSessaoChange}
                  name="tipo"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="" selected>
                    Selecione
                  </option>
                  <option value="1">Extra-Ordinária</option>
                  <option value="2">Audiência pública</option>
                  <option value="3">Solene</option>
                  <option value="4">Abertura de período legislativo</option>
                  <option value="5">Encerramento de período legislativo</option>
                  <option value="6">Intinerante</option>
                  <option value="7">Sessão administrativa</option>
                  <option value="8">Sessão especial</option>
                  <option value="9">Ordinária</option>
                </select>
              </MDBCol>
              <MDBCol sm="3" md="3">
                <label>
                  <MDBIcon fas icon="spell-check" /> N° da sessão
                </label>
                <input
                  value={formValues.numero}
                  onChange={handleformCriarSessaoChange}
                  name="numero"
                  type="text"
                  class="form-control"
                  placeholder="00/00"
                  disabled={enableCreateSessaoInput === ""}
                />
              </MDBCol>
              <MDBCol sm="3" md="3">
                <label>
                  <MDBIcon far icon="calendar-check" /> Data
                </label>
                <input
                  value={formValues.data}
                  onChange={handleformCriarSessaoChange}
                  name="data"
                  type="date"
                  class="form-control"
                  placeholder="00/00"
                />
              </MDBCol>
              <MDBCol sm="3" md="3">
                <label>
                  <MDBIcon icon="users" /> Comissão (opcional)
                </label>
                <select
                  value={formValues.comissaoId}
                  onChange={handleformCriarSessaoChange}
                  name="comissaoId"
                  class="form-select"
                  aria-label="Default select example"
                >
                  <option value="" selected>
                    Selecione
                  </option>
                  {comissoes.map((item, index) => (
                    <option value={item.id}>{item.Name}</option>
                  ))}
                </select>
              </MDBCol>
              <MDBCol sm="12" md="12">
                <label>
                  <MDBIcon far icon="comment-alt" /> Descrição
                </label>
                <textarea
                  value={formValues.descricao}
                  onChange={handleformCriarSessaoChange}
                  name="descricao"
                  class="form-control"
                  rows="2"
                />
              </MDBCol>
              <MDBCol sm="6" md="12" className="d-flex justify-content-end">
                <MDBBtn
                  onClick={handleBtnClearNovaSessao}
                  className="me-1 mt-4"
                  color="warning"
                >
                  <MDBIcon fas icon="times" /> Limpar Campos
                </MDBBtn>
                <MDBBtn
                  onClick={handleBtnSalvarSessao}
                  className="me-1 mt-4"
                  color="success"
                >
                  <MDBIcon fas icon="save" /> CRIAR
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </div>
        )}

        {sessoes.map((item, index) => (
          <MDBCol md="12" className="mt-3">
            <Card
              color={item.Situacao == "Aberta" ? "#14a44d" : "#6C757D"}
              url={`/${getCityByUrl()}/controlador/sessoes/${item.id}`}
              title={item.Numero}
              description={item.Descricao}
              notes={[
                item.Comissao && {
                  icon: "users",
                  value: item.Comissao.Name,
                },
                {
                  icon: "calendar-check",
                  value: new Date(item.Data).toLocaleDateString("pt-BR", {
                    timeZone: "UTC",
                  }),
                },
                {
                  icon: "",
                  value: item.Tipo,
                },
                {
                  icon: "",
                  value: item.Situacao,
                },
              ]}
            />
          </MDBCol>
        ))}
      </MDBRow>
      <MDBRow style={{ marginBottom: "8vh" }}>
        <Pagination
          totalPaginas={totalPaginas}
          pagina={filterValues.pagina}
          onPaginaChange={handlePaginaChange}
        />
      </MDBRow>
    </div>
  );
};

export default Sessoes;
