import "./Parameters.css";
import React, { useState, useContext, useEffect } from "react";
import { MDBRow, MDBCol, MDBTypography, MDBIcon } from "mdb-react-ui-kit";
import { getAllParameters, updateParameter } from "../../services/api";
import { useCamaraContext } from "../../context/camaraContext";
import { notify } from "../../common/toastConfig";
import { removeSpecialCharacters } from "../../common/util";

const Parameters = (props) => {
  const { camara } = useCamaraContext();
  const [parameters, setParameters] = useState([]);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);

  const handleInputChange = (index, value) => {
    if (/^[1-9][0-9]?$|^99$/.test(value) || value === "") {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
    }
  };

  async function fetchData() {
    const parametersResponse = await getAllParameters(camara.id);

    parametersResponse.forEach((item) => {
      if (item.Type === "MultipleText") {
        if (item.Data) {
          setInputValues(item.Data.split(";"));
        }
      }
    });

    setParameters(parametersResponse ?? []);
  }

  async function handleParameter(parameter, indexParameter) {
    try {
      if (parameter.Type === "MultipleText") {
        if (parameter.id) {
          parameter.Data = false;
          setInputValues(["", "", "", "", "", ""]);
        } else {
          const inputValuesValidated = inputValues.filter(
            (item) => item !== ""
          );

          if (inputValuesValidated.length != 6) {
            return notify(
              "preencha todos os campos para habilitar este parâmetro",
              "warning"
            );
          }

          parameter.Data = inputValues.filter((item) => item !== "").join(";");
        }
      } else {
        parameter.Data = !parameter.Data;
      }

      await updateParameter(camara.id, parameter);
      fetchData();
    } catch {
      notify("Erro ao tentar salvar alteração", "danger");
    }
  }

  useEffect(() => {
    if (camara) {
      fetchData();
    }
  }, [camara]);

  return (
    <MDBRow className="mt-4 parametersGlobal">
      {parameters.map((value, index) => {
        switch (value.Type) {
          case "MultipleText":
            return (
              <MDBCol key={index} size="6" className="parameterItem">
                <MDBRow
                  className="shadow p-3 rounded-7 m-1"
                  style={{ backgroundColor: "#F4F4F1", width: "100%" }}
                >
                  <MDBCol size="10" className="d-flex align-items-center">
                    <MDBRow>
                      <MDBCol size="12">
                        <span style={{ fontSize: "1.1rem" }}>{value.Name}</span>
                      </MDBCol>
                      <MDBCol size="12" className="mt-2">
                        {inputValues.map((value, index) => (
                          <input
                            key={index}
                            type="number"
                            className="inputMultipleMinutes"
                            value={value}
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            min={1}
                            max={99}
                            style={{ marginRight: "0.6rem" }}
                          />
                        ))}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                  <MDBCol
                    size="2"
                    className="parameter d-flex align-items-center d-flex justify-content-end"
                  >
                    <input
                      type="checkbox"
                      id="switchSpeakingTimeOptions"
                      checked={value.Data ? true : false}
                      onChange={() => handleParameter(value, index)}
                    />
                    <label htmlFor="switchSpeakingTimeOptions">Toggle</label>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            );
          default:
            return (
              <MDBCol
                key={index}
                size="6"
                className="d-flex align-items-stretch parameterItem"
              >
                <MDBRow
                  className="shadow p-3 rounded-7 m-1"
                  style={{ backgroundColor: "#F4F4F1", width: "100%" }}
                >
                  <MDBCol size="10" className="d-flex align-items-center">
                    <span style={{ fontSize: "1.1rem" }}>{value.Name}</span>
                  </MDBCol>
                  <MDBCol
                    size="2"
                    className="parameter d-flex align-items-center d-flex justify-content-end"
                  >
                    <input
                      type="checkbox"
                      id={removeSpecialCharacters(value.Name)}
                      checked={value.Data ? true : false}
                      onChange={() => handleParameter(value)}
                    />
                    <label htmlFor={removeSpecialCharacters(value.Name)}>
                      Toggle
                    </label>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            );
        }
      })}
    </MDBRow>
  );
};

export default Parameters;
