import "./SidebarLeft.css";
import React, { useState, useContext } from "react";
import {
  MDBBtn,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { getCityByUrl } from "../../common/util";
import { AuthContextController } from "../../context/controllerAuth";

const SidebarLeft = (props) => {
  const [logoutModal, setLogoutModal] = useState(false);

  const { logout } = useContext(AuthContextController);

  const links = [
    {
      title: "Sessões",
      url: `/${getCityByUrl()}/controlador/sessoes`,
      icon: "microphone",
    },
    {
      title: "Usuário",
      url: `/${getCityByUrl()}/controlador/conta`,
      icon: "user",
    },
    {
      title: "Parlamentares",
      url: `/${getCityByUrl()}/controlador/parlamentarControlador`,
      icon: "users",
    },
    {
      title: "Sobre a Câmara",
      url: `/${getCityByUrl()}/controlador/camara`,
      icon: "info-circle",
    },
    {
      title: "Configurações",
      url: `/${getCityByUrl()}/controlador/configuracoes`,
      icon: "cog",
    },
  ];

  const toggleShowLogoutModal = () => setLogoutModal(!logoutModal);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <div
        className={`sidebar-left ${!logoutModal && props.show ? "open" : ""}`}
      >
        <div className="sidebar__links h-75">
          {links.map((link, index) => (
            <a key={index} href={link.url}>
              <div className="sidebar__link">
                <MDBIcon fas icon={link.icon} style={{ color: "green" }} />{" "}
                {link.title}
              </div>
            </a>
          ))}
        </div>
        <div className="row footerSideBar p-3 d-flex justify-content-center h-25">
          <div className="col-12 d-flex justify-content-center h-25">
            <MDBBtn
              onClick={toggleShowLogoutModal}
              className="me-1 mb-1"
              color="danger"
            >
              Sair da conta
            </MDBBtn>
          </div>
        </div>
      </div>
      {/* MODAL INIT*/}
      <MDBModal show={logoutModal} setShow={setLogoutModal} tabIndex="">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Sair da conta</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowLogoutModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>Realmente deseja sair?</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn onClick={handleLogout} color="danger">
                <MDBIcon fas icon="sign-out-alt" /> Sair
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      {/* MODAL END */}
    </>
  );
};

export default SidebarLeft;
