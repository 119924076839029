import "./RelatorioTempoReal.css";
import React, { useState, useEffect } from "react";
import { useCamaraContext } from "../../context/camaraContext";
import {
  getSessaoByDataAndCamaraId,
  getMateriaSituationById,
} from "../../services/api";
import Spinner from "../../components/Spinner/Spinner";
import { getCurrentStringDate } from "../../common/util";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import SessaoInfo from "../../components/RealTime/SessaoInfo/SessaoInfo";
import Presenca from "../../components/RealTime/Presenca/Presenca";
import MateriaInfo from "../../components/RealTime/MateriaInfo/MateriaInfo";

const RelatorioTempoReal = () => {
  const { camara } = useCamaraContext();

  const secondsToChangePage = 10;

  const [loading, setLoading] = useState(true);
  const [currentComponent, setCurrentComponent] = useState(1);
  const [animationClass, setAnimationClass] = useState("slide-in");
  const [sessao, setSessao] = useState(null);
  const [materias, setMaterias] = useState([]);
  const [totalPages, setTotalPages] = useState(2);
  const [infosLoaded, setInfosLoaded] = useState(false);

  async function updateStateSessao() {
    const sessaoResponse = await getSessaoByDataAndCamaraId({
      data: getCurrentStringDate(),
      camaraId: camara.id,
    });

    let sessaoFinded = sessaoResponse.data.sessao;

    if (sessaoFinded) {
      const totalMaterias = sessaoFinded.Materia.length ?? 0;

      _setApprovedMaterias(sessaoFinded.Materia);
      setSessao(sessaoFinded);
      setTotalPages(2 + totalMaterias);
      setInfosLoaded(true);
    }
  }

  async function _setApprovedMaterias(materias) {
    setMaterias([]);

    materias.forEach(async (materia) => {
      const approved = await getMateriaSituationById(materia.id);
      materia.Approved = approved;

      setMaterias([...materias, materia]);
    });
  }

  async function fetchData() {
    setLoading(true);
    updateStateSessao();
    setLoading(false);
  }

  useEffect(() => {
    if (sessao === null) {
      const interval = setInterval(() => {
        fetchData();
      }, 5000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [sessao, camara]);

  useEffect(() => {
    if (camara) {
      fetchData();
    }
  }, [camara]);

  useEffect(() => {
    if (infosLoaded) {
      const interval = setInterval(() => {
        setAnimationClass("slide-out");
        setTimeout(() => {
          updateStateSessao();

          setCurrentComponent(
            (prevComponent) => (prevComponent % totalPages) + 1
          );
          setAnimationClass("slide-in");
        }, 500);
      }, secondsToChangePage * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [infosLoaded]);

  return (
    <div className="relatorioTempoReal">
      <Spinner show={loading} />
      <MDBRow className="logo-container">
        <MDBCol className="image-centralize">
          <img className="image-camara" src={camara && camara.LogoPath} />
        </MDBCol>
      </MDBRow>
      {sessao ? (
        <div className={`${animationClass}`}>
          {currentComponent === 1 ? (
            <SessaoInfo sessao={sessao} />
          ) : currentComponent === 2 ? (
            <div>
              <Presenca presenca={sessao.SessaoChamadas} />
            </div>
          ) : (
            <div>
              {materias.map(
                (item, index) =>
                  index === currentComponent - 3 && (
                    <MateriaInfo
                      key={index}
                      materia={item}
                      presenca={sessao.SessaoChamadas}
                    />
                  )
              )}
            </div>
          )}
        </div>
      ) : (
        <div>{!loading && <SessaoInfo />}</div>
      )}
    </div>
  );
};

export default RelatorioTempoReal;
