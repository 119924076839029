import "./Sidebar.css";
import React, { useState, useEffect, useRef } from "react";
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBBtn,
  MDBInput,
} from "mdb-react-ui-kit";
import {
  getParlamentars,
  getWebSocketHistoricByCamaraId,
  getParameterByCamaraIdAndName,
  getSessaoByDataAndCamaraId,
} from "../../services/api";
import {
  getCamaraIdFromLocalStorageControlador,
  getCityByUrl,
  getCurrentStringDate,
} from "../../common/util";
import { notify } from "../../common/toastConfig";
import { socketEmit, socketOn } from "../../services/websockets";
import { useCamaraContext } from "../../context/camaraContext";
import { ParameterName } from "../../Enums/ParameterEnums";

const Sidebar = (props) => {
  const { camara } = useCamaraContext();
  const CamaraId = getCamaraIdFromLocalStorageControlador();
  const [selectedItems, setSelectedItems] = useState([]);
  const [newConvidado, setNewConvidado] = useState(false);
  const [guestItems, setGuestItems] = useState([]);
  const [guestData, setGuestData] = useState({
    name: "",
    occupation: "",
  });
  const [availableItems, setAvailableItems] = useState([]);
  const [activeSelectedItem, setactiveSelectedItem] = useState(null);
  const [itemToTimerStarted, setItemToTimerStarted] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isCountdown, setIsCountdown] = useState(true);
  const [fetchDataExecuted, setFetchDataExecuted] = useState(false);
  const [currentMinutes, setCurrentMinutes] = useState(null);
  const intervalRef = useRef(null);

  const [sharedSeconds, setSharedSeconds] = useState(0);
  const [itemToSharedSeconds, setItemToSharedSeconds] = useState(null);
  const [isRunningSharedSeconds, setIsRunningSharedSeconds] = useState(false);
  const [isCountdownSharedSeconds, setIsCountdownSharedSeconds] =
    useState(true);
  const [parameterMinutesToTimer, setParameterMinutesToTimer] = useState(null);
  const [disableMinutes, setDisableMinutes] = useState(false);
  const [sessaoAtual, setSessaoAtual] = useState([]);
  const [parlamentarSharedTimer, setParlamentarSharedTimer] = useState({});

  const intervalSharedSecondsRef = useRef(null);

  const handleItemClick = (item) => {
    if (sessaoAtual) {
      setSelectedItems([...selectedItems, item]);
      setAvailableItems(availableItems.filter((i) => i !== item));
    } else
      notify(
        `Erro ao adicionar fala. Motivo: não há sessão cadastrada para a data ${new Date().toLocaleDateString(
          "pt-BR"
        )}`,
        "warning"
      );
  };

  const handleItemRemove = (item) => {
    if (isRunningSharedSeconds && parlamentarSharedTimer.id === item.id) {
      notify("Não é possível remover o parlamentar enquanto o aparte estiver ativo.", "warning");
      return;
    }
    setAvailableItems([...availableItems, item]);
    setSelectedItems(selectedItems.filter((i) => i !== item));
  };

  const handleItemToSharedSeconds = (item) => {
    let newSharedSeconds = 0;
    let newIsCountdownSharedSeconds = false;
    let newIsRunning = true;

    if (getCityByUrl() === process.env.REACT_APP_APARTE_CAMARA) {
      newSharedSeconds = process.env.REACT_APP_APARTE_SECONDS;
      newIsCountdownSharedSeconds = true;
      newIsRunning = false;
      setDisableMinutes(true);
    }

    setSharedSeconds(newSharedSeconds);
    setItemToSharedSeconds(item);
    setIsCountdownSharedSeconds(newIsCountdownSharedSeconds);
    setIsRunningSharedSeconds(true);
    setIsRunning(newIsRunning);

    const IsParlamentarSharedTimer = {
      nome: item.nickname ?? item.name,
      cargo: item.occupation,
      id: item.id,
      imagem: item.image,
      partido: item.partido,
    };

    setParlamentarSharedTimer(IsParlamentarSharedTimer);

    socketEmit("StartSharedTimer", {
      CamaraId,
      Content: {
        currentSeconds: seconds,
        seconds: newSharedSeconds,
        isCountdown: newIsCountdownSharedSeconds,
        parlamentar: IsParlamentarSharedTimer,
        initDate: new Date(),
      },
    });
  };

  const handleRemoveItemToSharedSeconds = (item) => {
    setDisableMinutes(false);
    setSharedSeconds(0);
    setItemToSharedSeconds(null);
    setIsCountdownSharedSeconds(false);
    setIsRunningSharedSeconds(false);
    if (seconds > 0) setIsRunning(true);

    socketEmit("EndSharedTimer", {
      CamaraId,
      Content: {
        endDate: new Date(),
      },
    });
  };

  const handleSelectedItemClick = (item) => {
    if (itemToTimerStarted === item || itemToTimerStarted === null)
      setactiveSelectedItem(item);
  };

  const handleConvidado = () => {
    setNewConvidado(!newConvidado);
    setGuestData({ name: "", occupation: "" });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setGuestData({ ...guestData, [name]: value });
  };

  const handleTimerStartedClick = (item, value) => {
    setIsCountdown(true);
    setItemToTimerStarted(item);
    setSeconds(parseInt(value) * 60);
    setCurrentMinutes(value);

    if (!isRunning) {
      setIsRunning(true);
    }
    if (value == 0) setIsCountdown(false);

    if (itemToSharedSeconds) setIsRunningSharedSeconds(true);

    const parlamentar = {
      nome: item.nickname ?? item.name,
      cargo: item.occupation,
      id: item.id,
      imagem: item.image,
      partido: item.partido,
    };

    socketEmit("StartTimer", {
      CamaraId,
      Content: {
        currentSeconds: seconds,
        seconds: value * 60,
        parlamentar,
        isCountdown,
        initDate: new Date(),
      },
    });
  };

  async function fetchData() {
    let response;
    try {
      response = await getParlamentars();

      let parlamentares = response.data.parlamentares;

      setAvailableItems(parlamentares);
    } catch (error) {
      console.error(error);
      setAvailableItems([]);
    }

    const parameterMinutesToTimerResponse = await getParameterByCamaraIdAndName(
      camara.id,
      ParameterName.SpeakingTimeOptions
    );

    if (parameterMinutesToTimerResponse) {
      const values = parameterMinutesToTimerResponse.Data.split(";");

      if (values.length == 6) {
        setParameterMinutesToTimer(values);
      }
    }

    const sessaoResponse = await getSessaoByDataAndCamaraId({
      data: getCurrentStringDate(),
      camaraId: camara.id,
    });

    if (sessaoResponse) setSessaoAtual(sessaoResponse.data.sessao);

    try {
      const webSocketsHistoric = await getWebSocketHistoricByCamaraId(CamaraId);

      webSocketsHistoric.forEach((webSocketHistoric) => {
        switch (webSocketHistoric.Event) {
          case "StartTimer":
            setIsCountdown(true);

            let currentParlamentarTimer;
            if (webSocketHistoric.Data.parlamentar.id < 0) {
              currentParlamentarTimer = webSocketHistoric.Data.parlamentar;
            } else {
              currentParlamentarTimer = response.data.parlamentares.find(
                (item) => item.id == webSocketHistoric.Data.parlamentar.id
              );
            }

            setItemToTimerStarted(currentParlamentarTimer);
            setactiveSelectedItem(currentParlamentarTimer);

            let currentSeconds;
            try {
              currentSeconds =
                webSocketHistoric.Data.seconds -
                Math.floor(
                  (new Date().getTime() -
                    new Date(webSocketHistoric.Data.initDate).getTime()) /
                    1000
                );

              if (currentSeconds < 0) currentSeconds = 0;
            } catch (ex) {
              currentSeconds = 0;
            }
            
            setSeconds(
              parseInt(currentSeconds == seconds ? seconds + 1 : currentSeconds)
            );

            if (!isRunning) {
              setIsRunning(true);
            }
            setIsRunningSharedSeconds(isRunning);

            setIsCountdown(webSocketHistoric.Data.isCountdown);
            break;
          case "AddOneMinuteToTimer":
            setSeconds(webSocketHistoric.Data.currentSeconds);
            if (!isRunning) {
              setIsRunning(true);
            }
            break;
          case "PauseTimer":
            clearInterval(intervalRef.current);
            setIsRunning(!webSocketHistoric.Data);
            break;
          case "TimerQueue":
            if (webSocketHistoric.Data && webSocketHistoric.Data.length > 0) {
              setSelectedItems(webSocketHistoric.Data);
              const newAvailableItems = (availableItems) => {
                const itemIdsToRemove = webSocketHistoric.Data.map(
                  (item) => item.id
                );
                const updatedList = availableItems.filter(
                  (item) => !itemIdsToRemove.includes(item.id)
                );
                return updatedList;
              };

              setAvailableItems(newAvailableItems);
            }
            break;
          case "StartSharedTimer":
            let currentSecondsSharedTimer;
            try {
              currentSecondsSharedTimer = Math.floor(
                (new Date().getTime() -
                  new Date(webSocketHistoric.Data.initDate).getTime()) /
                  1000
              );

              if (currentSecondsSharedTimer < 0) currentSecondsSharedTimer = 0;

              if (webSocketHistoric.Data.isCountdown) {
                currentSecondsSharedTimer =
                  process.env.REACT_APP_APARTE_SECONDS -
                  currentSecondsSharedTimer;
              }
            } catch (ex) {
              currentSecondsSharedTimer = 0;
            }

            setSeconds(webSocketHistoric.Data.currentSeconds);
            setSharedSeconds(currentSecondsSharedTimer);
            setItemToSharedSeconds(webSocketHistoric.Data.parlamentar);
            setIsCountdownSharedSeconds(webSocketHistoric.Data.isCountdown);
            setIsRunningSharedSeconds(webSocketHistoric.Data.isCountdown);
            setIsRunning(!webSocketHistoric.Data.isCountdown);
            if (getCityByUrl() === process.env.REACT_APP_APARTE_CAMARA) {
              setDisableMinutes(true);
            }
            break;
        }
      });
    } catch (error) {
      console.error(error);
    }

    setFetchDataExecuted(true);
  }

  const addGuestToAvailable = () => {
    if (guestData.name && guestData.occupation) {
      const lastItem = guestItems[guestItems.length - 1];
      const newItem = {
        CamaraId,
        id: lastItem ? lastItem.id - 1 : -1,
        name: guestData.name,
        occupation: guestData.occupation,
      };
      setGuestItems([...guestItems, newItem]);
      setAvailableItems([...availableItems, newItem]);
      setGuestData({ name: "", occupation: "" });
      handleConvidado();
    } else
      return notify("Preencha todos os campos antes de continuar.", "warning");
  };

  const closeTimer = (item) => {
    handleItemRemove(item);
    setIsRunning(false);
    setSeconds(0);
    setItemToTimerStarted(null);
    setactiveSelectedItem(null);
    setItemToSharedSeconds(null);
    clearInterval(intervalRef.current);

    socketEmit("CloseTimer", { CamaraId, Content: true });
    socketEmit("EndSharedTimer", {
      CamaraId,
      Content: {
        endDate: new Date(),
      },
    });
  };

  const pauseTimer = () => {
    setIsRunning(!isRunning);
    setIsRunningSharedSeconds(!isRunningSharedSeconds);
    clearInterval(intervalRef.current);

    socketEmit("PauseTimer", { CamaraId, Content: isRunning });
  };

  const addMinuteTimer = (value) => {
    setSeconds((prevSeconds) => prevSeconds + parseInt(value) * 60);
    if (!isRunning) {
      setIsRunning(true);
    }

    if (itemToSharedSeconds) setIsRunningSharedSeconds(true);

    socketEmit("AddOneMinuteToTimer", {
      CamaraId,
      Content: { currentSeconds: seconds },
    });
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    if (fetchDataExecuted) {
      socketEmit("TimerQueue", { CamaraId, Content: selectedItems });
    }
  }, [selectedItems, fetchDataExecuted]);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        if (isCountdown) setSeconds((prevSeconds) => prevSeconds - 1);
        else setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [isRunning, isCountdown]);

  useEffect(() => {
    if (seconds === 0 && isCountdown) {
      setIsRunning(false);
      clearInterval(intervalRef.current);
    }
  }, [seconds]);

  useEffect(() => {
    if (isRunningSharedSeconds) {
      intervalSharedSecondsRef.current = setInterval(() => {
        if (isCountdownSharedSeconds)
          setSharedSeconds((prevSeconds) => prevSeconds - 1);
        else setSharedSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
    return () => clearInterval(intervalSharedSecondsRef.current);
  }, [isRunningSharedSeconds, isCountdownSharedSeconds]);

  useEffect(() => {
    if (seconds === 0 && !isCountdownSharedSeconds) {
      setIsRunningSharedSeconds(false);
      clearInterval(intervalSharedSecondsRef.current);
    }

    if (sharedSeconds == 0 && isCountdownSharedSeconds) {
      setIsRunningSharedSeconds(false);
      clearInterval(intervalSharedSecondsRef.current);
      setIsRunning(true);
    }
  }, [sharedSeconds]);

  useEffect(() => {
    if (camara) {
      socketEmit("JoinCamara", { CamaraId });
      fetchData();
    }
  }, [camara]);

  useEffect(() => {
    if (seconds >= 0) {
      socketEmit("Timer", {
        CamaraId,
        Content: {
          seconds: seconds,
          parlamentar: activeSelectedItem,
          sharedSeconds: sharedSeconds,
          sharedParlamentar: itemToSharedSeconds,
        },
      });
    }
  }, [seconds]);

  useEffect(() => {
    setParlamentarSharedTimer(parlamentarSharedTimer);
  }, [parlamentarSharedTimer]);

  return (
    <div className={`fw-bold sidebar ${!props.show ? "hidden" : ""}`}>
      <MDBListGroup light>
        {selectedItems.map((item, index) => (
          <>
            <MDBListGroupItem
              key={item.id}
              active
              noBorders
              className="px-2 mb-1 "
              color="red"
            >
              <MDBRow>
                <MDBCol md={8} onClick={() => handleSelectedItemClick(item)}>
                  <MDBBadge className="ms-2 me-1" color="primary">
                    {index + 1}
                  </MDBBadge>
                  <img
                    src={
                      item.image
                        ? `${process.env.REACT_APP_API_URL}/${item.image}`
                        : "/user.png"
                    }
                    className="me-2 rounded-circle"
                    style={{ width: "2.5rem" }}
                    alt="Avatar"
                  />
                  {item.nickname ?? item.name}
                </MDBCol>
                <MDBCol
                  md={2}
                  className="d-flex align-items-center justify-content-center"
                >
                  {itemToTimerStarted && itemToTimerStarted.id !== item.id && (
                    <MDBIcon
                      onClick={
                        itemToSharedSeconds &&
                        itemToSharedSeconds.id === item.id
                          ? () => handleRemoveItemToSharedSeconds(item)
                          : () => handleItemToSharedSeconds(item)
                      }
                      {...(itemToSharedSeconds &&
                      itemToSharedSeconds.id === item.id
                        ? { fas: true }
                        : { far: true })}
                      far
                      icon={
                        itemToSharedSeconds &&
                        itemToSharedSeconds.id === item.id
                          ? "clock text-warning"
                          : "clock text-secondary"
                      }
                      size="3x"
                      className="me-3"
                    />
                  )}
                </MDBCol>
                {itemToTimerStarted !== item && (
                  <MDBCol
                    md={2}
                    className={
                      item == itemToSharedSeconds
                        ? `disabled d-flex align-items-center justify-content-center`
                        : `d-flex align-items-center justify-content-center`
                    }
                  >
                    <MDBIcon
                      onClick={() => handleItemRemove(item)}
                      fas
                      icon="minus-circle text-danger"
                      size="3x"
                      className="me-3"
                    />
                  </MDBCol>
                )}
                {itemToSharedSeconds && itemToSharedSeconds.id === item.id && (
                  <MDBCol
                    md={12}
                    style={{ color: "black" }}
                    className="d-flex align-items-center justify-content-center pt-2 text-warning fs-6"
                  >
                    TEMPO UTILIZADO: {formatTime(sharedSeconds)}
                  </MDBCol>
                )}
              </MDBRow>
            </MDBListGroupItem>
            {activeSelectedItem && activeSelectedItem.id === item.id && (
              <MDBRow
                className={
                  disableMinutes ? "disableMinutes timerConfig" : "timerConfig"
                }
              >
                {parameterMinutesToTimer ? (
                  <>
                    {parameterMinutesToTimer.map((itemParameter) => (
                      <MDBCol
                        onClick={() =>
                          currentMinutes === parseInt(itemParameter)
                            ? null
                            : handleTimerStartedClick(
                                item,
                                parseInt(itemParameter)
                              )
                        }
                        className={`${
                          currentMinutes === parseInt(itemParameter)
                            ? "timerConfig-active"
                            : ""
                        } d-flex align-items-center justify-content-center`}
                        md={2}
                      >
                        {parseInt(itemParameter)}
                      </MDBCol>
                    ))}
                  </>
                ) : (
                  <>
                    <MDBCol
                      onClick={() =>
                        currentMinutes === 1
                          ? null
                          : handleTimerStartedClick(item, 1)
                      }
                      className={`${
                        currentMinutes === 1 ? "timerConfig-active" : ""
                      } d-flex align-items-center justify-content-center`}
                      md={2}
                    >
                      1
                    </MDBCol>
                    <MDBCol
                      onClick={() =>
                        currentMinutes === 3
                          ? null
                          : handleTimerStartedClick(item, 3)
                      }
                      className={`${
                        currentMinutes === 3 ? "timerConfig-active" : ""
                      } d-flex align-items-center justify-content-center`}
                      md={2}
                    >
                      3
                    </MDBCol>
                    <MDBCol
                      onClick={() =>
                        currentMinutes === 5
                          ? null
                          : handleTimerStartedClick(item, 5)
                      }
                      className={`${
                        currentMinutes === 5 ? "timerConfig-active" : ""
                      } d-flex align-items-center justify-content-center`}
                      md={2}
                    >
                      5
                    </MDBCol>
                    <MDBCol
                      onClick={() =>
                        currentMinutes === 10
                          ? null
                          : handleTimerStartedClick(item, 10)
                      }
                      className={`${
                        currentMinutes === 10 ? "timerConfig-active" : ""
                      } d-flex align-items-center justify-content-center`}
                      md={2}
                    >
                      10
                    </MDBCol>
                    <MDBCol
                      onClick={() =>
                        currentMinutes === 15
                          ? null
                          : handleTimerStartedClick(item, 15)
                      }
                      className={`${
                        currentMinutes === 15 ? "timerConfig-active" : ""
                      } d-flex align-items-center justify-content-center`}
                      md={2}
                    >
                      15
                    </MDBCol>
                    <MDBCol
                      onClick={() =>
                        currentMinutes === 20
                          ? null
                          : handleTimerStartedClick(item, 20)
                      }
                      className={`${
                        currentMinutes === 20 ? "timerConfig-active" : ""
                      } d-flex align-items-center justify-content-center`}
                      md={2}
                    >
                      20
                    </MDBCol>
                  </>
                )}
              </MDBRow>
            )}
            {itemToTimerStarted && itemToTimerStarted.id === item.id && (
              <>
                <MDBRow className="timerStartedConfig border-solid">
                  <MDBCol
                    md={12}
                    style={{ color: "black" }}
                    className="timerStartedConfig-seconds d-flex align-items-center justify-content-center"
                  >
                    {formatTime(seconds)}
                  </MDBCol>
                  {isCountdown && (
                    <MDBCol
                      onClick={() => addMinuteTimer(1)}
                      md={4}
                      className="addMinuteTimer d-flex align-items-center justify-content-center"
                    >
                      +1m
                    </MDBCol>
                  )}
                  <MDBCol
                    onClick={pauseTimer}
                    md={isCountdown ? 4 : 6}
                    className="playTimer d-flex align-items-center justify-content-center"
                  >
                    {isRunning ? (
                      <MDBIcon fas icon="pause" />
                    ) : (
                      <MDBIcon fas icon="play" />
                    )}
                  </MDBCol>
                  <MDBCol
                    onClick={() => closeTimer(item)}
                    md={isCountdown ? 4 : 6}
                    className="closeTimer d-flex align-items-center justify-content-center"
                  >
                    <MDBIcon fas icon="times" />
                  </MDBCol>
                </MDBRow>
              </>
            )}
          </>
        ))}
      </MDBListGroup>
      <MDBListGroup light>
        {availableItems.map((item) => (
          <MDBListGroupItem
            key={item.id}
            onClick={() => handleItemClick(item)}
            noBorders
            className="px-3"
          >
            <img
              src={
                item.image
                  ? `${process.env.REACT_APP_API_URL}/${item.image}`
                  : "/user.png"
              }
              className="me-2 rounded-circle"
              style={{ width: "2.5rem" }}
              alt="Avatar"
            />
            {item.nickname ?? item.name}
          </MDBListGroupItem>
        ))}
      </MDBListGroup>
      <MDBListGroup light>
        <MDBListGroupItem
          noBorders
          className="px-3 d-flex justify-content-center"
        >
          {!newConvidado && (
            <MDBBtn
              onClick={() => {
                handleConvidado();
              }}
              className="mt-3"
              color="success"
              href="#confirmGuestAddition"
            >
              <MDBIcon fas icon="fas fa-plus " /> Adicionar convidado
            </MDBBtn>
          )}
          {newConvidado && (
            <MDBCol className="d-flex flex-column align-items-center">
              <div>
                <MDBInput
                  label="Nome do convidado"
                  name="name"
                  onChange={handleInputChange}
                  value={guestData.name}
                  required={true}
                  style={{ minWidth: "20rem" }}
                />
                <MDBInput
                  label="Cargo do convidado"
                  name="occupation"
                  onChange={handleInputChange}
                  value={guestData.occupation}
                  required={true}
                  className="mt-2"
                  style={{ minWidth: "20rem" }}
                />
              </div>
              <MDBCol classname="d-flex flex-column justify-content-between">
                <MDBBtn
                  id="confirmGuestAddition"
                  className="mx-1 mt-2"
                  onClick={() => {
                    addGuestToAvailable();
                  }}
                >
                  Confirmar
                </MDBBtn>
                <MDBBtn
                  className="mx-1 mt-2"
                  color="danger"
                  onClick={() => handleConvidado()}
                >
                  Cancelar
                </MDBBtn>
              </MDBCol>
            </MDBCol>
          )}
        </MDBListGroupItem>
      </MDBListGroup>
    </div>
  );
};

export default Sidebar;
