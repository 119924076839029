import "./FooterControlador.css";
import React, { useContext } from "react";
import { AuthContextController } from "../../context/controllerAuth";
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { getCityByUrl } from "../../common/util";

const FooterControlador = () => {
  const city = getCityByUrl();
  const { authenticated } = useContext(AuthContextController);

  return (
    <div>
      {authenticated &&
      window.location.pathname.includes("controlador") &&
      !window.location.pathname.includes("controlador/login") &&
      !window.location.pathname.includes("controlador/relatorio") ? (
        <>
          {/* <MDBFooter className="bg-secondary text-white">
            <div
              className="text-center p-2"
              style={{ backgroundColor: "#303134", height: "7vh" }}
            >
              Copyright © {new Date().getFullYear()}{" "}
              <a className="text-white fw-bold" style={{ cursor: "pointer" }}>
                Lima Empreedimentos e Serviços.
              </a>
            </div>
          </MDBFooter> */}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default FooterControlador;
