import React, { useState } from "react";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBProgress,
  MDBProgressBar,
  MDBTypography,
  MDBBadge,
} from "mdb-react-ui-kit";
import { convertStringDateForBrDate } from "../../common/util";
import { getMateriasBySessaoId } from "../../services/api";

const Accordion = (props) => {
  const [materiaModal, setMateriaModal] = useState(false);
  const [materias, setMaterias] = useState([]);

  const toggleMateriaModal = () => setMateriaModal(!materiaModal);

  async function loadMateriasInfo(id) {
    const response = await getMateriasBySessaoId(id);
    const responseWitgPercentage = calculePercentageVoteFromResponse(response);
    toggleMateriaModal();

    setMaterias(responseWitgPercentage);
  }

  function calculePercentageVoteFromResponse(response) {
    response &&
      response.forEach((e, index) => {
        if (e.Votos && e.Votos.length > 0) {
          const totalVotos = e.Votos.length;
          const totalAfavor = e.Votos.filter(
            (v) => v.Status === "A favor"
          ).length;
          const totalContra = e.Votos.filter(
            (v) => v.Status === "Contra"
          ).length;
          const totalSeAbsteve = e.Votos.filter(
            (v) => v.Status === "Se absteve"
          ).length;

          let porcentagemAfavor = (totalAfavor / totalVotos) * 100;
          let porcentagemContra = (totalContra / totalVotos) * 100;
          let porcentagemSeAbsteve = (totalSeAbsteve / totalVotos) * 100;

          response[index].TotalAfavor = totalAfavor;
          response[index].TotalContra = totalContra;
          response[index].TotalSeAbsteve = totalSeAbsteve;

          response[index].PorcentagemAfavor =
            porcentagemAfavor % 1 === 0
              ? porcentagemAfavor.toFixed(0)
              : porcentagemAfavor.toFixed(2);
          response[index].PorcentagemContra =
            porcentagemContra % 1 === 0
              ? porcentagemContra.toFixed(0)
              : porcentagemContra.toFixed(2);
          response[index].PorcentagemSeAbsteve =
            porcentagemSeAbsteve % 1 === 0
              ? porcentagemSeAbsteve.toFixed(0)
              : porcentagemSeAbsteve.toFixed(2);
        }
      });

    return response;
  }

  return (
    <>
      <MDBAccordion>
        {props.itens.map((item, index) => (
          <MDBAccordionItem
            key={index}
            collapseId={index + 1}
            headerTitle={
              <>
                <MDBIcon fas icon="caret-down" color="success" /> &nbsp;{" "}
                {item.Numero} - &nbsp;{convertStringDateForBrDate(item.Data)}
              </>
            }
          >
            <div>
              {item.Descricao}
              <br />
              <MDBBtn
                onClick={() => loadMateriasInfo(item.id)}
                className="m-1"
                color="info"
              >
                <MDBIcon fas icon="eye" />
                &nbsp; VER MATÉRIAS
              </MDBBtn>
            </div>
          </MDBAccordionItem>
        ))}
      </MDBAccordion>
      <MDBModal tabIndex="-1" show={materiaModal} setShow={setMateriaModal}>
        <MDBModalDialog
          size={materias.length == 0 ? "lg" : "fullscreen"}
          className="p-4 ps-0 "
        >
          <MDBModalContent className="rounded-8">
            <MDBModalHeader>
              <MDBModalTitle>MATÉRIAS</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleMateriaModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBAccordion>
                {materias.length == 0 && (
                  <div className="d-flex justify-content-center mt-3">
                    <MDBTypography
                      tag="h6"
                      className="mb-0 justify-content-center"
                    >
                      <MDBIcon fas icon="database" />{" "}
                      <span>NÃO HÁ MATÉRIAS CADASTRADAS</span>
                    </MDBTypography>
                  </div>
                )}
                {materias.map((item, index) => (
                  <MDBAccordionItem
                    key={index}
                    collapseId={index + 1}
                    headerTitle={
                      <>
                        <MDBIcon fas icon="caret-down" color="success" /> &nbsp;{" "}
                        {item.Numero} - &nbsp;
                        {convertStringDateForBrDate(item.Data)}
                      </>
                    }
                  >
                    <div>
                      {item.Resumo}
                      {item.Votos && item.Votos.length > 0 && (
                        <>
                          <MDBTypography variant="h5" className="mt-2">
                            Resultado da votação:
                          </MDBTypography>
                          <MDBProgress height="20">
                            <MDBProgressBar
                              width={item.PorcentagemAfavor}
                              valuemin={0}
                              valuemax={100}
                              bgColor="success"
                            >
                              {item.PorcentagemAfavor}%
                            </MDBProgressBar>
                            <MDBProgressBar
                              width={item.PorcentagemContra}
                              valuemin={0}
                              valuemax={100}
                              bgColor="warning"
                            >
                              {item.PorcentagemContra}%
                            </MDBProgressBar>
                            <MDBProgressBar
                              width={item.PorcentagemSeAbsteve}
                              valuemin={0}
                              valuemax={100}
                              bgColor="danger"
                            >
                              {item.PorcentagemSeAbsteve}%
                            </MDBProgressBar>
                          </MDBProgress>
                          <div className="mt-3 d-flex justify-content-center">
                            <MDBBadge pill color="success" light>
                              A favor ({item.TotalAfavor})
                            </MDBBadge>{" "}
                            <MDBBadge pill color="warning" light>
                              Se Absteve ({item.TotalSeAbsteve})
                            </MDBBadge>{" "}
                            <MDBBadge pill color="danger" light>
                              Contra ({item.TotalContra})
                            </MDBBadge>
                          </div>
                        </>
                      )}
                    </div>
                  </MDBAccordionItem>
                ))}
              </MDBAccordion>
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={toggleMateriaModal} color="info">
                Fechar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default Accordion;
