import React from "react";
import "./Pagination.css";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
  MDBIcon,
} from "mdb-react-ui-kit";

const Pagination = (props) => {
  const { pagina, onPaginaChange } = props;

  function handleClick(value) {
    onPaginaChange(value);
  }

  return (
    <nav aria-label="..." className="d-flex justify-content-center mt-2">
      <MDBPagination className="mb-0">
        <MDBPaginationItem>
          <MDBPaginationLink
            onClick={() => handleClick(0)}
            style={{ cursor: "pointer" }}
          >
            <MDBIcon fas icon="home" />
          </MDBPaginationLink>
        </MDBPaginationItem>
        <MDBPaginationItem disabled={pagina < 1 ? true : false}>
          <MDBPaginationLink
            onClick={() => handleClick(pagina - 1)}
            style={{ cursor: "pointer" }}
          >
            {pagina < 1 ? "0" : pagina}
          </MDBPaginationLink>
        </MDBPaginationItem>
        <MDBPaginationItem active aria-current="page">
          <MDBPaginationLink
            onClick={() => handleClick(pagina)}
            style={{ cursor: "pointer" }}
          >
            {pagina + 1}
            <span className="visually-hidden">(current)</span>
          </MDBPaginationLink>
        </MDBPaginationItem>
        <MDBPaginationItem
          disabled={props.totalPaginas == pagina + 1 ? true : false}
        >
          <MDBPaginationLink
            onClick={() => handleClick(pagina + 1)}
            style={{ cursor: "pointer" }}
          >
            {pagina + 2}
          </MDBPaginationLink>
        </MDBPaginationItem>
        <MDBPaginationItem>
          <MDBPaginationLink
            onClick={() => handleClick(props.totalPaginas - 1)}
            style={{ cursor: "pointer" }}
          >
            <MDBIcon fas icon="angle-double-right" /> {props.totalPaginas}
          </MDBPaginationLink>
        </MDBPaginationItem>
      </MDBPagination>
    </nav>
  );
};

export default Pagination;
