import axios from "axios";
import { notify } from "../common/toastConfig";
import { goToLoginUser, goToLoginControlador } from "../common/util";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/painel",
});

function addParamsToUrl(obj) {
  const urlParams = new URLSearchParams();

  for (const [key, value] of Object.entries(obj)) {
    urlParams.append(key, value);
  }

  return urlParams.toString();
}

export const createSession = async (email, password) => {
  return api
    .post("/login", { email, password })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const createSessionController = async (email, password) => {
  return api
    .post("/controlador/login", { email, password })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getParlamentars = async () => {
  return api
    .get("/parlamentaresAtivos")
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getSessoesControlador = async (params) => {
  const url = `/controlador/sessoes?` + addParamsToUrl(params);

  return api
    .get(url)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const createSessaoControlador = async (sessao) => {
  return api
    .post("/controlador/addSessao", sessao)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getInfoSessaoControlador = async (id) => {
  const url = `/controlador/getInfoSessao/${id}`;

  return api
    .get(url)
    .then((response) => {
      if ((response.data.status = "success")) return response;
      else notify(response.data.message, "danger");
      return;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const createMateriaControlador = async (materia) => {
  return api
    .post("/controlador/addMateria", materia, { timeout: 180000 })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const createRoteiroControlador = async (roteiro) => {
  return api
    .post("/controlador/addRoteiro", roteiro)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const editSessaoControlador = async (sessao) => {
  return api
    .post("/controlador/editSessao/" + sessao.id, sessao)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const removerSessaoControlador = async (id) => {
  return api
    .post("/controlador/removeSessao/" + id)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getParlamentarByUserId = async (id) => {
  const url = `/getParlamentarByUserId/${id}`;

  return api
    .get(url)
    .then((response) => {
      if ((response.data.status = "success")) return response;
      else goToLoginUser();
      return;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getSessaoByDataAndCamaraId = async (data) => {
  const url = `/getSessaoByDataAndCamaraId`;

  return api
    .post(url, data)
    .then((response) => {
      if ((response.data.status = "success")) return response;
      else notify(response.data.message, "danger");
      return;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const addVoteToMateria = async (data) => {
  const url = `/materia/addVote`;

  return api
    .post(url, data)
    .then((response) => {
      if ((response.data.status = "success")) return response;
      else notify(response.data.message, "danger");
      return;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getCamaraByCity = async (cidade) => {
  const url = `/camara?cidade=${cidade}`;

  return api
    .get(url)
    .then((response) => {
      if ((response.data.status = "success")) return response.data.camara;
      else notify(response.data.message, "danger");
      return;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getInfoUsersToLoginByCity = async () => {
  const url = `/getInfoUsersToLoginByCity?cidade=${
    window.location.pathname.split("/")[1]
  }`;

  return api
    .get(url)
    .then((response) => {
      if ((response.data.status = "success")) return response.data.users;
      else notify(response.data.message, "danger");
      return;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const checkPresenceOfParliamentarianInSession = async (
  sessaoId,
  parlamentarId
) => {
  const url = `/parlamentar/checkPresenceInSession?sessaoId=${sessaoId}&parlamentarId=${parlamentarId}`;

  return api
    .get(url)
    .then((response) => {
      return response.data.status && response.data.chamada ? true : false;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const sendPresenceConfirmation = async (data) => {
  const url = `/parlamentar/presenceConfirmation`;

  return api
    .post(url, data)
    .then((response) => {
      return response.data.status && response.data.chamada ? true : false;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const removerMateriaControlador = async (id) => {
  return api
    .post("/controlador/removeMateria/" + id)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const removerRoteiroControlador = async (id) => {
  return api
    .post("/controlador/removeRoteiro/" + id)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const editMateriaControlador = async (id, materia) => {
  return api
    .post("/controlador/editMateria/" + id, materia, { timeout: 180000 })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const editRoteiroControlador = async (id, roteiro) => {
  return api
    .post("/controlador/editRoteiro/" + id, roteiro)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getMateriasBySessaoId = async (id) => {
  return api
    .get("/sessao/getMateriasBySessaoId/" + id)
    .then((response) => {
      if (response.data.status === "success") return response.data.materias;
      else return;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getAllMateriasFromParlamentarId = async (id) => {
  return api
    .get("/sessao/getAllMateriasFromParlamentarId/" + id)
    .then((response) => {
      if (response.data.status === "success") return response.data.materias;
      else return;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getWebSocketHistoricByCamaraId = async (id) => {
  return api
    .get("/getWebSocketHistoricByCamaraId/" + id)
    .then((response) => {
      if (response.data.status === "success")
        return response.data.webSocketHistorics;
      else return [];
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const changePasswordControlador = async (data) => {
  return api
    .post("/controlador/changePasswordControlador", data)
    .then((response) => {
      if (response.data.status === "success")
        return notify(response.data.message, "success");
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getParlamentarsByCamaraId = async (id) => {
  return api
    .get("/getParlamentarsByCamaraId/" + id)
    .then((response) => {
      if (response.data.status === "success")
        return response.data.parlamentares;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getSessoesByCamaraId = async (params, id) => {
  const url = `/getSessoesByCamaraId/${id}?` + addParamsToUrl(params);

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success") return response.data;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getStatisticsByParlamentarId = async (id) => {
  const url = `/getStatistics/${id}?`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success") return response.data.statistics;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getComissaoByCamaraId = async (id) => {
  const url = `/comissao/getByCamaraId/${id}`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success") return response.data.comissoes;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getAllParameters = async (id) => {
  const url = `/parameter/getParametersByCamaraId/${id}`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success") return response.data.parameters;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const updateParameter = async (camaraId, parameter) => {
  return api
    .post("/parameter/update", { camaraId, parameter })
    .then((response) => {
      response.data.status === "success"
        ? notify(response.data.message, "success")
        : notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getTypesMateria = async () => {
  const url = `/controlador/approvalRuleMateria/getTypes`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success") return response.data.types;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getApprovalRuleMateriaByCamaraId = async (id) => {
  const url = `/controlador/approvalRuleMateria/getByCamaraId/${id}`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success")
        return response.data.approvalRuleMaterias;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const updateApprovalRuleMateria = async (camaraId, rules) => {
  return api
    .post("/controlador/approvalRuleMateria/updateApprovalRuleMateria", {
      camaraId,
      rules,
    })
    .then((response) => {
      response.data.status === "success"
        ? notify(response.data.message, "success")
        : notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginControlador();

      notify("Erro interno, tente novamente em instantes", "danger");
      return;
    });
};

export const getParameterByCamaraIdAndName = async (camaraId, name) => {
  const url = `/parameter/getByCamaraIdAndName?camaraId=${camaraId}&name=${name}`;

  return api
    .get(url)
    .then((response) => {
      return response.data.parameter;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getMateriaSituationById = async (id) => {
  const url = `/sessao/getMateriaSituation/${id}`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success") return response.data;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getBancadasByCamaraId = async (id) => {
  const url = `/parlamentar/getBancadasByCamaraId/${id}`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success")
        return response.data.listasDePartidos;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      // return notify("Erro interno, tente novamente em instantes oi", "danger");
      return [];
    });
};

export const getAutoFillSessao = async (tipo, camaraId) => {
  const url = `/controlador/getAutoFillSessao?tipo=${tipo}&camaraId=${camaraId}`;

  return api
    .get(url)
    .then((response) => {
      return response.data.status === "success"
        ? response.data.autoFill
        : response.data.mensage;
    })
    .catch((err) => {
      if (err.response.status === 401) goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getComissoesFromParlamentarId = async (id) => {
  const url = `/comissao/getByParlamentarId/${id}`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success") return response.data.comissoes;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getParlamentaresPresentes = async (sessaoId) => {
  const url = `/sessao/getParlamentaresPresentes/${sessaoId}`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success")
        return response.data.parlamentares;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getVotosByMateriaId = async (materiaId) => {
  const url = `/sessao/materia/${materiaId}/voto`;

  return api
    .get(url)
    .then((response) => {
      if (response.data.status === "success") return response.data.votos;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const editParlamentar = async (parlamentarId, formData) => {
  const url = `/parlamentar/edit/${parlamentarId}`;

  return api
    .post(url, formData)
    .then((response) => {
      if (response.data.status === "success") return response.data.parlamentar;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response && err.response.status === 401)
        return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const resetUserPassword = async (parlamentarId) => {
  const url = `/controlador/resetUserPassword/${parlamentarId}`;

  return api
    .post(url)
    .then((response) => {
      if (response.data.status === "success") return response.data;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response && err.response.status === 401)
        return goToLoginControlador();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const changePasswordUser = async (data) => {
  return api
    .post("/controlador/changePasswordUser", data)
    .then((response) => {
      if (response.data.status === "success")
        return notify(response.data.message, "success");
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};

export const getRoteiroByParlamentarIdAndSessaoId = async (
  parlamentarId,
  sessaoId
) => {
  return api
    .post("/getRoteiroByParlamentarIdAndSessaoId", { parlamentarId, sessaoId })
    .then((response) => {
      if (response.data.status === "success") return response.data.roteiros;
      else return notify(response.data.message, "danger");
    })
    .catch((err) => {
      if (err.response.status === 401) return goToLoginUser();

      return notify("Erro interno, tente novamente em instantes", "danger");
    });
};
