import "./VoteMateria.css";
import React, { useState, useEffect } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBadge,
  MDBCard,
  MDBCardBody,
  MDBContainer,
} from "mdb-react-ui-kit";
import {
  getParlamentaresPresentes,
  getWebSocketHistoricByCamaraId,
  getVotosByMateriaId,
  getMateriaSituationById,
} from "../../../services/api";
import { socketOn } from "../../../services/websockets";
import { useCamaraContext } from "../../../context/camaraContext";

const VoteMateria = (props) => {
  const [parlamentars, setParlamentars] = useState([]);
  const [isSecretVote, setIsSecretVote] = useState(props.materia.IsSecretVote);
  const [materiaSituation, setMateriaSituation] = useState(null);
  const materia = props.materia;

  const { camara } = useCamaraContext();

  async function fetchData() {
    const webSocketsHistoric = await getWebSocketHistoricByCamaraId(camara.id);

    webSocketsHistoric.forEach(async (webSocketHistoric) => {
      switch (webSocketHistoric.Event) {
        case "ShowSecretVotes":
          setIsSecretVote(webSocketHistoric.Data.ShowSecretVotes);
          break;
      }
    });

    const parlamentaresResponse = await getParlamentaresPresentes(
      materia.SessaoId
    );
    const votosResponse = await getVotosByMateriaId(materia.id);

    for (let i = 0; i < parlamentaresResponse.length; i++) {
      const voto = votosResponse.find(
        (voto) => voto.ParlamentarId === parlamentaresResponse[i].id
      );

      if (voto) {
        switch (voto.Status) {
          case "A favor":
            parlamentaresResponse[i].status = "success";
            break;
          case "Se absteve":
            parlamentaresResponse[i].status = "warning";
            break;
          case "Contra":
            parlamentaresResponse[i].status = "danger";
            break;
          default:
            parlamentaresResponse[i].status = "info";
        }
      }
    }

    setParlamentars(parlamentaresResponse);
  }

  useEffect(() => {
    if (camara) {
      fetchData();

      socketOn("ReceiveVote", async (data) => {
        data.Parlamentar.status = data.Value;

        switch (data.Value) {
          case "A favor":
            data.Parlamentar.status = "success";
            break;
          case "Se absteve":
            data.Parlamentar.status = "warning";
            break;
          case "Contra":
            data.Parlamentar.status = "danger";
            break;
          default:
            data.Parlamentar.status = "";
        }

        setParlamentars((parlamentares) => {
          const novosParlamentares = parlamentares.map((parlamentarPresente) =>
            parlamentarPresente.id === data.Parlamentar.id
              ? data.Parlamentar
              : parlamentarPresente
          );

          return novosParlamentares;
        });
      });

      socketOn("ShowSecretVotes", async (data) => {
        setIsSecretVote(data.ShowSecretVotes);
      });
    }
  }, [camara]);

  useEffect(() => {
    if (props.materia) {
      const intervalId = setInterval(async () => {
        const materiaSituationResponse = await getMateriaSituationById(
          props.materia.id
        );
        setMateriaSituation(materiaSituationResponse.situation);

        if (
          materiaSituationResponse.situation === "Aprovada" ||
          materiaSituationResponse.situation === "Reprovada"
        )
          clearInterval(intervalId);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [props.materia]);

  return (
    <>
      <MDBContainer className="d-flex flex-column topBar-Container">
        {materia && (
          <MDBRow className="info-topBar rounded-9">
            <MDBCol className="d-flex justify-content-start">
              <span className="fs-2 fw-bold d-flex align-items-center text-white">
                <MDBIcon icon="vote-yea" color="white" className="fs-3 m-2" />
                VOTAÇÃO DE MATÉRIA N°: {materia.Numero}
              </span>
            </MDBCol>
            <MDBCol className="d-flex justify-content-end">
              <span className="fs-2 fw-bold d-flex align-items-center text-white">
                {materia.Tipo.toUpperCase()}
              </span>
            </MDBCol>
          </MDBRow>
        )}

        <MDBRow className="VoteContainer mt-3">
          <MDBCol className="rounded-9 parlamentar-Container shadow justify-content-center">
            <MDBCol
              className="d-flex flex-wrap rounded-9 mt-2 w-100 ms-4"
              md={9}
            >
              {parlamentars.map((item, index) =>
                item.nickname ?? item.name ? (
                  <MDBCol
                    md={12}
                    key={item.id}
                    xl={2}
                    lg={3}
                    className="mb-3 mx-2 parlamentar-cards"
                  >
                    <MDBCard
                      background={
                        item.status
                          ? isSecretVote
                            ? "info"
                            : item.status
                          : null
                      }
                    >
                      <MDBCardBody>
                        <div className="d-flex align-items-center">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${item.image}`}
                            alt=""
                            style={{ width: "45px", height: "45px" }}
                            className="rounded-circle"
                          />
                          <div
                            className={`ms-3 text-overflow text-${item.status}`}
                          >
                            <p className="fw-bold mb-1 text-dark">
                              {item.nickname ?? item.name}
                            </p>
                            <p className="fw-bold mb-1 text-dark">
                              {item.partido ?? "Null"}
                            </p>
                            <p className="mb-0 text-body">{item.occupation}</p>
                          </div>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ) : (
                  ""
                )
              )}
            </MDBCol>
          </MDBCol>
          <MDBCol md={3}>
            {materia && !isSecretVote && (
              <MDBCol>
                <MDBCol md={12} className="VotesCardSize">
                  <MDBBadge
                    className="fs-4 d-flex justify-content-center flex-column shadow rounded-9 VotesCardSize"
                    color={"success"}
                    light
                  >
                    <p className="mt-3">
                      {
                        parlamentars.filter((item) => item.status === "success")
                          .length
                      }{" "}
                      VOTOS (
                      {(() => {
                        const filteredCount = parlamentars.filter(
                          (item) => item.status === "success"
                        ).length;
                        const totalCount = parlamentars.length;
                        const percentage = (filteredCount / totalCount) * 100;

                        if (percentage % 1 === 0) {
                          return percentage.toFixed(0);
                        } else {
                          return percentage.toFixed(2);
                        }
                      })().replace(".", ",")}
                      %)
                    </p>
                    <p className="fs-5 mt-1">A FAVOR</p>
                  </MDBBadge>
                </MDBCol>
                <MDBCol md={12}>
                  <MDBBadge
                    className="fs-4 d-flex justify-content-center flex-column mt-3 shadow rounded-9 VotesCardSize mt-3"
                    color={"warning"}
                    light
                  >
                    <p className="mt-3">
                      {
                        parlamentars.filter((item) => item.status === "warning")
                          .length
                      }{" "}
                      VOTOS (
                      {(() => {
                        const filteredCount = parlamentars.filter(
                          (item) => item.status === "warning"
                        ).length;
                        const totalCount = parlamentars.length;
                        const percentage = (filteredCount / totalCount) * 100;

                        if (percentage % 1 === 0) {
                          return percentage.toFixed(0);
                        } else {
                          return percentage.toFixed(2);
                        }
                      })().replace(".", ",")}
                      %)
                    </p>
                    <p className="fs-5 mt-1"> SE ABSTEVE</p>
                  </MDBBadge>
                </MDBCol>
                <MDBCol md={12}>
                  <MDBBadge
                    className="fs-4 d-flex justify-content-center flex-column mt-3 shadow rounded-9 VotesCardSize mt-3"
                    color={"danger"}
                    light
                  >
                    <p className="mt-3">
                      {
                        parlamentars.filter((item) => item.status === "danger")
                          .length
                      }{" "}
                      VOTOS (
                      {(() => {
                        const filteredCount = parlamentars.filter(
                          (item) => item.status === "danger"
                        ).length;
                        const totalCount = parlamentars.length;
                        const percentage = (filteredCount / totalCount) * 100;

                        if (percentage % 1 === 0) {
                          return percentage.toFixed(0);
                        } else {
                          return percentage.toFixed(2);
                        }
                      })().replace(".", ",")}
                      %)
                    </p>
                    <p className="fs-5 mt-1">CONTRA</p>
                  </MDBBadge>
                </MDBCol>
                <MDBCol md={12}>
                  <MDBBadge
                    className="fs-4 d-flex justify-content-center flex-column mt-3 shadow rounded-9 VotesCardSize mt-3"
                    color={"secondary"}
                    light
                  >
                    <p className="mt-3">
                      {parlamentars.filter((item) => !item.status).length} VOTOS
                      (
                      {(() => {
                        const filteredCount = parlamentars.filter(
                          (item) => !item.status
                        ).length;
                        const totalCount = parlamentars.length;
                        const percentage = (filteredCount / totalCount) * 100;

                        if (percentage % 1 === 0) {
                          return percentage.toFixed(0);
                        } else {
                          return percentage.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          });
                        }
                      })().replace(".", ",")}
                      %)
                    </p>
                    <p className="fs-5 mt-1">NÃO VOTOU</p>
                  </MDBBadge>
                </MDBCol>
                <MDBCol md={12} className="Situation-Card">
                  {materiaSituation == null && (
                    <MDBBadge
                      className="fs-4 d-flex justify-content-center flex-column mt-3 shadow rounded-9 Situation-Card"
                      color="primary"
                    >
                      <p className="mt-3">
                        <MDBIcon fas icon="info-circle" /> CARREGANDO...
                      </p>
                    </MDBBadge>
                  )}

                  {materiaSituation == "Reprovada" && (
                    <MDBBadge
                      className="fs-4 d-flex justify-content-center flex-column mt-3 shadow rounded-9 Situation-Card"
                      color="danger"
                    >
                      <p className="mt-3">
                        <MDBIcon fas icon="close" /> MATÉRIA REPROVADA
                      </p>
                    </MDBBadge>
                  )}

                  {materiaSituation == "Aprovada" && (
                    <MDBBadge
                      className="fs-4 d-flex justify-content-center flex-column mt-3 shadow rounded-9 Situation-Card"
                      color="success"
                    >
                      <p className="mt-3">
                        <MDBIcon fas icon="check" /> MATÉRIA APROVADA
                      </p>
                    </MDBBadge>
                  )}

                  {materiaSituation == "Em votação" && (
                    <MDBBadge
                      className="fs-4 d-flex justify-content-center flex-column mt-3 shadow rounded-9 Situation-Card"
                      color="primary"
                    >
                      <p className="mt-3">
                        <MDBIcon fas icon="vote-yea" /> VOTAÇÃO
                      </p>
                      <p className="mt-3">EM ANDAMENTO</p>
                    </MDBBadge>
                  )}

                  {materiaSituation == "Não votada" && (
                    <MDBBadge
                      className="fs-4 d-flex justify-content-center flex-column mt-3 shadow rounded-9 Situation-Card"
                      color="warning"
                    >
                      <p className="mt-3">
                        <MDBIcon fas icon="info-circle" /> MATÉRIA
                      </p>
                      <p className="mt-3">NÃO VOTADA</p>
                    </MDBBadge>
                  )}
                </MDBCol>
              </MDBCol>
            )}
            {materia && isSecretVote && (
              <MDBCol className="d-flex secretVote bg-warning h-100 rounded-9 align-items-center justify-content-center">
                <h1 className="d-flex flex-column justify-content-center text-center text-white display-1">
                  <MDBIcon
                    fas
                    icon="lock"
                    className="text-white display-1 mb-5"
                  />{" "}
                  <p className="display-3 fw-bold">VOTAÇÃO</p>{" "}
                  <p className="display-3 fw-bold">SECRETA</p>{" "}
                </h1>
              </MDBCol>
            )}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default VoteMateria;
