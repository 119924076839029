import { useEffect, useRef } from "react";
import "./Timer.css";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import { formatSecondsToTimer, loadParlamentarName } from "../../common/util";

const Timer = ({
  seconds,
  parlamentar,
  sharedSeconds,
  sharedParlamentar,
  audioOn,
}) => {
  const audioRef = useRef(null);

  useEffect(() => {
    try {
      if (parlamentar && seconds === 30 && audioOn) audioRef.current.play();

      if (parlamentar && seconds === 0 && audioOn) audioRef.current.play();
    } catch (err) {
      console.error(err);
    }
  }, [seconds]);

  if (!parlamentar) return <></>;

  return (
    <>
      {parlamentar && !sharedParlamentar ? (
        <MDBRow>
          <div className="cardBodyDiv mx-3" key={parlamentar.id}>
            <MDBRow className="d-flex speaking-parlamenter-container rounded-9">
              <MDBCol className="d-flex align-items-center">
                <img
                  className="rounded-7 shadow"
                  src={
                    parlamentar.image
                      ? `${process.env.REACT_APP_API_URL}/${parlamentar.image}`
                      : "/user.png"
                  }
                  alt=""
                  style={{ width: "35vw", height: "28vw" }}
                />
              </MDBCol>
              <MDBCol className="d-flex flex-column justify-content-center align-items-center">
                {seconds == 0 ? (
                  <MDBCol className="timerOver d-flex text-center align-items-center fs-2">
                    <p>TEMPO ESGOTADO!</p>
                  </MDBCol>
                ) : (
                  <MDBCol className="timer d-flex text-center align-items-center mt-3">
                    <p>
                      TEMPO RESTANTE
                      <br />
                      &nbsp;
                      {formatSecondsToTimer(seconds)}
                    </p>
                  </MDBCol>
                )}
              </MDBCol>
              <MDBCol
                className="d-flex align-items-center parlamentar-container primaryParlamentar-container"
                md={12}
              >
                <MDBCol className="text-white text-center fw-bold mt-3 fs-2">
                  <p className="texts-containers display-4 fw-bold">
                    {loadParlamentarName(parlamentar)}
                  </p>
                </MDBCol>
                <MDBCol className="text-white text-center fs-4 mt-1">
                  <p className="texts-containers">
                    {parlamentar.cargo ?? parlamentar.occupation}
                  </p>
                  <p className="texts-containers">
                    {parlamentar.partido ?? null}
                  </p>
                </MDBCol>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBRow>
      ) : (
        <MDBRow>
          <div className="cardBodyDiv mx-3" key={parlamentar.id}>
            <MDBRow className="d-flex speaking-parlamenter-container rounded-9">
              <MDBCol className="d-flex align-items-center">
                <img
                  className="rounded-7 shadow"
                  src={
                    sharedParlamentar.image
                      ? `${process.env.REACT_APP_API_URL}/${sharedParlamentar.image}`
                      : "/user.png"
                  }
                  alt=""
                  style={{ width: "35vw", height: "28vw" }}
                />
              </MDBCol>
              <MDBCol className="d-flex flex-column justify-content-center align-items-center">
                {sharedParlamentar && (
                  <MDBCol className="sharedTimerCard justify-content-center align-items-center rounded-9 w-100">
                    <MDBCol
                      className="d-flex justify-content-center align-items-center fw-bold text-warning text-center mt-3"
                      md={12}
                      style={{
                        backgroundColor: "#265b6c",
                        borderTopLeftRadius: "1rem",
                        borderTopRightRadius: "1rem",
                        height: "20%",
                      }}
                    >
                      <p className="mt-3 fs-3">CONCEDIDO</p>
                    </MDBCol>
                    <MDBCol className="d-flex align-items-center">
                      <MDBCol
                        className="cardBodyDiv flex-column"
                        md={7}
                        key={parlamentar.id}
                      >
                        <MDBCol className="ms-2 text-white text-center">
                          <p className="fw-bold mb-1 texts-containers">
                            {parlamentar.nickname ?? parlamentar.name}
                            <br />
                            {parlamentar.partido}
                          </p>
                          <p className="text-white text-center opacity-50 mb-0 texts-containers">
                            {parlamentar.cargo ?? parlamentar.occupation}
                          </p>
                          {seconds == 0 ? (
                            <p className="timerOver text-center fs-1">
                              TEMPO ESGOTADO!
                            </p>
                          ) : (
                            <p
                              className={`sharedTimer ${
                                seconds < 10 ? "text-danger" : "text-warning"
                              }`}
                            >
                              &nbsp;
                              {formatSecondsToTimer(seconds)}
                            </p>
                          )}
                        </MDBCol>
                      </MDBCol>
                      <MDBCol className="d-flex justify-content-center mb-3">
                        <img
                          className="rounded-5 shadow"
                          src={`${process.env.REACT_APP_API_URL}/${parlamentar.image}`}
                          alt=""
                          style={{ width: "9vw", height: "9vw" }}
                        />
                      </MDBCol>
                    </MDBCol>
                  </MDBCol>
                )}
                <MDBCol className="timer d-flex text-center align-items-center mt-3">
                  <p>
                    TEMPO UTILIZADO
                    <br />
                    &nbsp;
                    {formatSecondsToTimer(sharedSeconds)}
                  </p>
                </MDBCol>
              </MDBCol>
              <MDBCol
                className="d-flex align-items-center parlamentar-container primaryParlamentar-container"
                md={12}
              >
                <MDBCol className="text-white text-center fw-bold mt-3">
                  <p className="texts-containers display-4 fw-bold">
                    {loadParlamentarName(sharedParlamentar)}
                  </p>
                </MDBCol>
                <MDBCol className="text-white text-center fs-4 mt-1">
                  <p className="texts-containers">
                    {parlamentar.cargo ?? parlamentar.occupation}
                  </p>
                  <p className="texts-containers">
                    {sharedParlamentar.partido ?? null}
                  </p>
                </MDBCol>
              </MDBCol>
            </MDBRow>
          </div>
        </MDBRow>
      )}

      <audio ref={audioRef}>
        <source src="/BEEP.mp3" type="audio/mpeg" />
      </audio>
    </>
  );
};

export default Timer;
