import React, { useState, useContext, useEffect } from "react";

import "./ParlamentarControlador.css";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import {
  getInfoUsersToLoginByCity,
  editParlamentar,
  resetUserPassword,
} from "../../services/api";
import { notify } from "../../common/toastConfig";

const ParlamentarControlador = () => {
  const [usersInfos, setUsersInfos] = useState([]);
  const [editParliamentaryModal, setEditParliamentaryModal] = useState(false);
  const [parliamentaryToEdit, setParliamentaryToEdit] = useState(null);
  const [formValues, setFormValues] = useState({
    changeName: "",
    changeNickname: "",
    changeOccupation: "",
    changePartido: "",
    changeImage: null,
  });
  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const toggleResetPasswordModal = () => {
    setEditParliamentaryModal(!editParliamentaryModal);
    setResetPasswordModal(!resetPasswordModal);
  };

  const resetPassword = async () => {
    toggleResetPasswordModal();

    const resetUserPasswordResponse = await resetUserPassword(
      parliamentaryToEdit.Parlamentar.id
    );

    if (resetUserPasswordResponse) notify("Senha redefinida", "success");
  };

  async function fetchData() {
    try {
      const usersInfos = await getInfoUsersToLoginByCity();
      setUsersInfos(usersInfos);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const toggleShowEditParliamentaryModal = () => {
    setEditParliamentaryModal(!editParliamentaryModal);
  };

  const handleEditButtonClick = (item) => {
    setParliamentaryToEdit(item);
    setFormValues({
      name: item.Parlamentar.name || "",
      nickname: item.Parlamentar.nickname || "",
      occupation: item.Parlamentar.occupation || "",
      partido: item.Parlamentar.partido || "",
      image: item.Parlamentar.image || "",
    });
    toggleShowEditParliamentaryModal();
  };

  const editParlamantar = async () => {
    try {
      if (
        formValues.name === "" ||
        formValues.nickname === "" ||
        formValues.partido === ""
      ) {
        notify("Por favor, preencha todos os campos.", "warning");
        return;
      }

      const formData = new FormData();
      formData.append("changeName", formValues.name);
      formData.append("changeNickname", formValues.nickname);
      formData.append("changeOccupation", formValues.occupation);
      formData.append("changePartido", formValues.partido);
      formData.append("changeImage", formValues.changeImage);

      await editParlamentar(parliamentaryToEdit.Parlamentar.id, formData);

      notify("Edição concluída", "success");

      fetchData();

      setFormValues({
        ...formValues,
        changeImage: null,
      });

      toggleShowEditParliamentaryModal();
    } catch (error) {
      notify("Erro ao editar parlamentar", "danger");
    }
  };

  const handleformParlamentarChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <div id="controladorParlamentar">
      <MDBRow className="m-4">
        {usersInfos &&
          usersInfos.map((item, index) => (
            <MDBCol key={index} className="mb-2" sm={8} md={8} lg={4}>
              <MDBCard className="userItemControlador bg-gradient-secondary">
                <MDBCardBody className="border border-secondary rounded-2">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/${item.Parlamentar.image}`}
                        alt=""
                        style={{ width: "45px", height: "45px" }}
                        className="rounded-circle"
                      />
                      <div className="ms-3 text-overflow">
                        <p className="fw-bold mb-1">
                          {item.Parlamentar.nickname ?? item.Parlamentar.name}
                        </p>
                        <p className="text-muted mb-0">
                          {item.Parlamentar.partido ?? "Partido"}
                        </p>
                        <p className="text-muted mb-0">
                          {item.Parlamentar.occupation}
                        </p>
                      </div>
                    </div>
                    <div className="text-center btnEditar position-relative">
                      <MDBIcon
                        far
                        icon="edit"
                        className="m-1"
                        size="2x"
                        color="warning"
                        onClick={() => handleEditButtonClick(item)}
                      />
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))}
      </MDBRow>
      <MDBModal
        show={editParliamentaryModal}
        setShow={setEditParliamentaryModal}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>EDITAR PARLAMENTAR</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowEditParliamentaryModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {parliamentaryToEdit && (
                <div>
                  <form encType="multipart/form-data">
                    <MDBCol sm="12" md="12" className="mb-2">
                      <label>
                        <MDBIcon fas icon="user" /> Nome
                      </label>
                      <input
                        value={formValues.name}
                        name="name"
                        type="text"
                        className="form-control"
                        onChange={handleformParlamentarChange}
                      />
                    </MDBCol>
                    <MDBCol sm="12" md="12" className="mb-2">
                      <label>
                        <MDBIcon fas icon="signature" /> Apelido
                      </label>
                      <input
                        value={formValues.nickname}
                        name="nickname"
                        type="text"
                        className="form-control"
                        onChange={handleformParlamentarChange}
                      />
                    </MDBCol>
                    <MDBCol sm="12" md="12" className="mb-2">
                      <label>
                        <MDBIcon fas icon="image" /> Foto
                      </label>
                      <input
                        name="changeImage"
                        type="file"
                        className="form-control"
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            changeImage: e.target.files[0],
                          })
                        }
                      />
                    </MDBCol>
                    <MDBCol sm="12" md="12" className="mb-2">
                      <label>
                        <MDBIcon fas icon="briefcase" /> Cargo
                      </label>
                      <select
                        value={formValues.occupation}
                        onChange={handleformParlamentarChange}
                        name="occupation"
                        className="form-select"
                        aria-label="Selecione o Cargo"
                      >
                        <option value="VEREADOR">VEREADOR(A)</option>
                        <option value="PRESIDENTE">PRESIDENTE</option>
                        <option value="1° VICE-PRESIDENTE">
                          1° VICE-PRESIDENTE
                        </option>
                        <option value="2° VICE-PRESIDENTE">
                          2° VICE-PRESIDENTE
                        </option>
                        <option value="1° SECRETÁRIO">1° SECRETÁRIO</option>
                        <option value="2° SECRETÁRIO">2° SECRETÁRIO</option>
                        <option value="3° SECRETÁRIO">3° SECRETÁRIO</option>
                        <option value="4° SECRETÁRIO">4° SECRETÁRIO</option>
                        <option value="SUPLENTE">SUPLENTE</option>
                      </select>
                    </MDBCol>

                    <MDBCol sm="12" md="12" className="mb-2">
                      <label>
                        <MDBIcon fas icon="flag" /> Partido
                      </label>
                      <input
                        value={formValues.partido}
                        name="partido"
                        type="text"
                        className="form-control"
                        onChange={handleformParlamentarChange}
                      />
                    </MDBCol>
                  </form>
                  <MDBCol sm="12" md="12" className="my-4 mb-1">
                    <MDBBtn color="warning" onClick={toggleResetPasswordModal}>
                      <MDBIcon fas icon="key" /> Redefinir senha
                    </MDBBtn>
                  </MDBCol>
                </div>
              )}
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn onClick={editParlamantar} color="success">
                <MDBIcon fas icon="pen" /> Salvar Edição
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={resetPasswordModal}
        setShow={setResetPasswordModal}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Redefinir senha</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleResetPasswordModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Realmente deseja redefinir a senha deste parlamentar?
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleResetPasswordModal}>
                Cancelar
              </MDBBtn>
              <MDBBtn color="warning" onClick={resetPassword}>
                <MDBIcon fas icon="key" /> Redefinir senha
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default ParlamentarControlador;
