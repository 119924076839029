import "../../index.css";
import "./Relatorio.css";
import React, { useState, useEffect } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBBadge,
} from "mdb-react-ui-kit";
import {
  getInfoSessaoControlador,
  getMateriaSituationById,
} from "../../services/api";
import { useParams } from "react-router-dom";
import { loadParlamentarName } from "../../common/util";

const Relatorio = () => {
  const { id } = useParams();
  const [sessao, setSessao] = useState(null);
  const [chamada, setChamada] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [materiasResponse, setMateriasResponse] = useState([]);

  function closeThisPage() {
    window.close();
  }

  async function fetchData() {
    try {
      const infoSessaoresponse = await getInfoSessaoControlador(id);
      setSessao(infoSessaoresponse.data.sessao);
      setChamada(infoSessaoresponse.data.sessao.SessaoChamadas);
      setMateriasResponse(infoSessaoresponse.data.sessao.Materia);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (materiasResponse) {
      materiasResponse.forEach(async (materia, index) => {
        try {
          const materiaSituationResponse = await getMateriaSituationById(
            materia.id
          );
          materia.Situation = materiaSituationResponse.situation;
        } catch (err) {
          console.log(err);
        }
        setMaterias((prevMaterias) => [...prevMaterias, materia]);
      });
    }
  }, [materiasResponse]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {sessao ? (
        <div id="relatorio" className="px-5">
          <MDBRow className="mx-4 pt-4">
            <MDBCol md="12" className="mb-3">
              <h3 className="fw-bold text-center">
                RELATÓRIO SESSÃO {sessao.Numero}
              </h3>
            </MDBCol>
          </MDBRow>
          <MDBRow className="shadow infoSessao mx-3">
            <MDBCol sm="3" md="3">
              <label>
                <MDBIcon fas icon="spell-check" /> Número da sessão
              </label>
              <input
                disabled
                value={sessao.Numero}
                type="text"
                className="custom-input form-control"
              />
            </MDBCol>
            <MDBCol sm="3" md="3">
              <label>
                <MDBIcon fas icon="calendar-check" /> Data da sessão
              </label>
              <input
                disabled
                value={sessao.Data}
                type="date"
                className="custom-input form-control"
              />
            </MDBCol>
            <MDBCol sm="3" md="3" className="">
              <label>
                <MDBIcon fas icon="caret-down" /> Tipo da sessão
              </label>
              <input
                disabled
                value={sessao.Tipo}
                type="text"
                className="custom-input form-control"
              />
            </MDBCol>
            <MDBCol sm="3" md="3" className="">
              <label>
                <MDBIcon fas icon="caret-down" /> Situação da sessão
              </label>
              <input
                disabled
                value={sessao.Situacao}
                type="text"
                className="custom-input form-control"
              />
            </MDBCol>
            <MDBCol sm="12" md="12" className="mt-3">
              <label>
                <MDBIcon far icon="comment-alt" /> Descrição da sessão
              </label>
              <textarea
                value={sessao.Descricao}
                disabled
                className="custom-input form-control"
                rows="2"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol md="12" className="mb-3 my-5">
              <h4 className="fw-bold text-center">
                PARLAMENTARES PRESENTES NA SESSÃO
              </h4>
            </MDBCol>
            <MDBCol md="12" className="mb-2">
              <MDBRow className="mx-2 d-flex justify-content-center">
                {chamada.length == 0 && (
                  <MDBCol md={12} className="mb-3 chamadaIsNull">
                    <p className="fs-3 text-center text-danger">
                      Nenhum Parlamentar está presente nesta sessão
                    </p>
                  </MDBCol>
                )}
                {chamada.map((item) => (
                  <MDBCol key={item.id} xl={3} lg={6} className="mb-3">
                    <MDBCard
                      border={(() => {
                        switch (item.Situacao) {
                          case "Presente":
                            return "success";
                          case "Ausente":
                            return "warning";
                          case "Licença":
                            return "primary";
                        }
                      })()}
                      background="#dddddd"
                    >
                      <MDBCardBody>
                        <div className="d-flex align-items-center">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${item.Parlamentar.image}`}
                            alt=""
                            style={{ width: "45px", height: "45px" }}
                            className="rounded-circle"
                          />
                          <div className={`ms-3 text-overflow`}>
                            <p className="fw-bold mb-1 text-overflow">
                              {item.Parlamentar.nickname ??
                                item.Parlamentar.name}
                            </p>
                            <p className="fw-bold mb-1 text-overflow">
                              {item.Parlamentar.partido}
                            </p>
                            <p className="text-muted mb-0 text-overflow">
                              {item.Situacao && (
                                <MDBBadge
                                  pill
                                  color={(() => {
                                    switch (item.Situacao) {
                                      case "Presente":
                                        return "success";
                                      case "Ausente":
                                        return "warning";
                                      case "Licença":
                                        return "primary";
                                    }
                                  })()}
                                  light
                                >
                                  {item.Situacao}
                                </MDBBadge>
                              )}{" "}
                              {item.Parlamentar.occupation}
                            </p>
                          </div>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </MDBCol>
                ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol md="12" className="mb-3 mt-3">
              <h4 className="fw-bold text-center">ORDENS DO DIA</h4>
            </MDBCol>
            <MDBCol md="12" className="mb-2">
              <MDBRow className="mx-2">
                {materias == 0 && (
                  <MDBCol md={12} className="mb-3 materiasIsNull">
                    <p className="fs-3 text-center text-danger">
                      Não há ordem do dia nesta sessão
                    </p>
                  </MDBCol>
                )}
                {materias
                  .filter((materia) => materia.OrdemDia == true)
                  .map((item) => (
                    <MDBCol key={item.id} xl={12} lg={12} className="mb-3">
                      <MDBRow className="shadow infoMaterias mx-1">
                        <MDBCol md="12" className="mb-3">
                          <h3 className="fw-bold text-center">
                            MATÉRIA {item.Numero}
                          </h3>
                        </MDBCol>
                        <MDBCol sm="4" md="4">
                          <label>
                            <MDBIcon fas icon="spell-check" /> Número da matéria
                          </label>
                          <input
                            disabled
                            value={item.Numero}
                            type="text"
                            className="custom-input form-control"
                          />
                        </MDBCol>
                        <MDBCol sm="4" md="4">
                          <label>
                            <MDBIcon fas icon="calendar-check" /> Data da
                            matéria
                          </label>
                          <input
                            disabled
                            value={item.Data}
                            type="date"
                            className="custom-input form-control"
                          />
                        </MDBCol>
                        <MDBCol sm="4" md="4" className="">
                          <label>
                            <MDBIcon fas icon="caret-down" /> Tipo da matéria
                          </label>
                          <input
                            disabled
                            value={item.Tipo}
                            type="text"
                            className="custom-input form-control"
                          />
                        </MDBCol>
                        <MDBCol sm="12" md="12" className="mt-3">
                          <label>
                            <MDBIcon far icon="comment-alt" /> Resumo da matéria
                          </label>
                          <textarea
                            value={item.Resumo}
                            disabled
                            className="custom-input form-control"
                            rows="2"
                          />
                        </MDBCol>
                        <MDBCol md="12" className="mb-3">
                          <h4 className="text-center">AUTORES DA MATÉRIA</h4>
                        </MDBCol>
                        <MDBCol md="12" className="mb-3">
                          <MDBRow className="d-flex justify-content-center mb-3">
                            {item.ParlamentarMateria.map((item) => (
                              <MDBCol
                                key={item.id}
                                xl={3}
                                lg={6}
                                className="mb-3"
                              >
                                <MDBCard
                                  border={(() => {
                                    switch (item.Situacao) {
                                      case "Presente":
                                        return "success";
                                      case "Ausente":
                                        return "warning";
                                      case "Licença":
                                        return "primary";
                                    }
                                  })()}
                                  background="#dddddd"
                                >
                                  <MDBCardBody>
                                    {item.Name ? (
                                      <div className="d-flex align-items-center">
                                        <img
                                          src="/user.png"
                                          alt=""
                                          style={{
                                            width: "45px",
                                            height: "45px",
                                          }}
                                          className="rounded-circle"
                                        />
                                        <div className={`ms-3 text-overflow`}>
                                          <p className="fw-bold mb-1 text-overflow">
                                            {item.Name}
                                          </p>
                                          <p className="text-muted mb-0 text-overflow">
                                            {item.Situacao && (
                                              <MDBBadge
                                                pill
                                                color={(() => {
                                                  switch (item.Situacao) {
                                                    case "Presente":
                                                      return "success";
                                                    case "Ausente":
                                                      return "warning";
                                                    case "Licença":
                                                      return "primary";
                                                  }
                                                })()}
                                                light
                                              >
                                                {item.Situacao}
                                              </MDBBadge>
                                            )}{" "}
                                            Autor Externo
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={`${process.env.REACT_APP_API_URL}/${item.Parlamentar.image}`}
                                          alt=""
                                          style={{
                                            width: "45px",
                                            height: "45px",
                                          }}
                                          className="rounded-circle"
                                        />
                                        <div className={`ms-3 text-overflow`}>
                                          <p className="fw-bold mb-1 text-overflow">
                                            {item.Parlamentar.nickname ??
                                              item.Parlamentar.name}
                                          </p>
                                          <p className="fw-bold mb-1 text-overflow">
                                            {item.Parlamentar.partido}
                                          </p>
                                          <p className="text-muted mb-0 text-overflow">
                                            {item.Situacao && (
                                              <MDBBadge
                                                pill
                                                color={(() => {
                                                  switch (item.Situacao) {
                                                    case "Presente":
                                                      return "success";
                                                    case "Ausente":
                                                      return "warning";
                                                    case "Licença":
                                                      return "primary";
                                                  }
                                                })()}
                                                light
                                              >
                                                {item.Situacao}
                                              </MDBBadge>
                                            )}{" "}
                                            {item.Parlamentar.occupation}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            ))}
                          </MDBRow>
                        </MDBCol>
                        <MDBCol md="12" className="mb-3">
                          <h4 className="text-center">
                            {item.Votos.length == 0
                              ? "ESTA MATÉRIA AINDA NÃO FOI VOTADA"
                              : "RESULTADO DA VOTAÇÃO"}
                          </h4>
                        </MDBCol>
                        {sessao.Situacao == "Fechada" && (
                          <MDBCol
                            md="12"
                            className="mb-3 d-flex justify-content-center fs-3"
                          >
                            {item && item.Situation == null && (
                              <MDBBadge color={"primary"} light>
                                CARREGANDO...
                              </MDBBadge>
                            )}
                            {item && item.Situation === "Reprovada" && (
                              <MDBBadge color={"danger"} light>
                                REPROVADA
                              </MDBBadge>
                            )}
                            {item && item.Situation === "Aprovada" && (
                              <MDBBadge color={"success"} light>
                                APROVADA
                              </MDBBadge>
                            )}
                            {item && item.Situation === "Em votação" && (
                              <MDBBadge color={"warning"} light>
                                EM VOTAÇÃO
                              </MDBBadge>
                            )}
                            {item && item.Situation === "Não votada" && (
                              <MDBBadge color={"primary"} light>
                                NÃO VOTADA
                              </MDBBadge>
                            )}
                          </MDBCol>
                        )}
                        {item.Votos.length === 0 ? (
                          ""
                        ) : (
                          <>
                            <MDBCol md="4" className="mb-3">
                              <MDBBadge
                                className="fs-4 d-flex justify-content-center"
                                pill
                                color={"success"}
                                light
                              >
                                {(() => {
                                  const favorCount = item.Votos.filter(
                                    (voto) => voto.Status === "A favor"
                                  ).length;
                                  const totalCount = item.Votos.length;
                                  const percentage =
                                    (favorCount / totalCount) * 100;

                                  if (percentage % 1 === 0) {
                                    return percentage.toFixed(0);
                                  } else {
                                    return percentage.toFixed(2);
                                  }
                                })().replace(".", ",")}
                                % A favor
                              </MDBBadge>
                            </MDBCol>
                            <MDBCol md="4" className="mb-3">
                              <MDBBadge
                                className="fs-4 d-flex justify-content-center"
                                pill
                                color={"warning"}
                                light
                              >
                                {(() => {
                                  const abstainCount = item.Votos.filter(
                                    (voto) => voto.Status === "Se absteve"
                                  ).length;
                                  const totalCount = item.Votos.length;
                                  const percentage =
                                    (abstainCount / totalCount) * 100;

                                  if (percentage % 1 === 0) {
                                    return percentage.toFixed(0);
                                  } else {
                                    return percentage.toFixed(2);
                                  }
                                })().replace(".", ",")}
                                % Se absteve
                              </MDBBadge>
                            </MDBCol>
                            <MDBCol md="4" className="mb-3">
                              <MDBBadge
                                className="fs-4 d-flex justify-content-center"
                                pill
                                color={"danger"}
                                light
                              >
                                {(() => {
                                  const againstCount = item.Votos.filter(
                                    (voto) => voto.Status === "Contra"
                                  ).length;
                                  const totalCount = item.Votos.length;
                                  const percentage =
                                    (againstCount / totalCount) * 100;

                                  if (percentage % 1 === 0) {
                                    return percentage.toFixed(0);
                                  } else {
                                    return percentage.toFixed(2);
                                  }
                                })().replace(".", ",")}
                                % Contra
                              </MDBBadge>
                            </MDBCol>
                          </>
                        )}
                        <MDBCol md="12" className="mb-3">
                          <MDBRow className="d-flex justify-content-center">
                            {item.Votos.map((voto) => {
                              const parlamentarVoto = chamada.find(
                                (e) => e.ParlamentarId == voto.ParlamentarId
                              );

                              return (
                                <MDBCol
                                  xl={3}
                                  lg={6}
                                  className="d-flex mb-3 justify-content-center align-items-center"
                                  key={voto.id}
                                >
                                  <div className="d-inline-flex position-relative">
                                    <MDBBadge
                                      className={(() => {
                                        switch (voto.Status) {
                                          case "A favor":
                                            return "bg-success position-absolute top-50 start-1 translate-middle p-1 border border-light rounded-circle ms-3";
                                          case "Se absteve":
                                            return "bg-warning position-absolute top-50 start-1 translate-middle p-1 border border-light rounded-circle ms-3";
                                          case "Contra":
                                            return "bg-danger position-absolute top-50 start-1 translate-middle p-1 border border-light rounded-circle ms-3";
                                        }
                                      })()}
                                      style={{
                                        width: "1.4rem",
                                        zIndex: "9999",
                                      }}
                                    >
                                      <MDBIcon
                                        fas
                                        icon={(() => {
                                          switch (voto.Status) {
                                            case "A favor":
                                              return "check";
                                            case "Se absteve":
                                              return "circle";
                                            case "Contra":
                                              return "times";
                                          }
                                        })()}
                                      />
                                    </MDBBadge>
                                    {parlamentarVoto && (
                                      <MDBCol>
                                        <MDBCard className="VotedParlamentarCard">
                                          <MDBCardBody className="ms-2">
                                            <div className="d-flex align-items-center">
                                              <img
                                                src={`${process.env.REACT_APP_API_URL}/${parlamentarVoto.Parlamentar.image}`}
                                                style={{
                                                  width: "45px",
                                                  height: "45px",
                                                }}
                                                className="rounded-circle "
                                              />
                                              <div
                                                className={`ms-3 text-overflow`}
                                              >
                                                <p className="fw-bold mb-1 text-overflow">
                                                  {
                                                    loadParlamentarName(
                                                      parlamentarVoto.Parlamentar
                                                    ).split(" ")[0]
                                                  }
                                                </p>
                                                <p className="text-muted mb-0 text-overflow">
                                                  {
                                                    parlamentarVoto.Parlamentar
                                                      .occupation
                                                  }
                                                </p>
                                              </div>
                                            </div>
                                          </MDBCardBody>
                                        </MDBCard>
                                      </MDBCol>
                                    )}
                                  </div>
                                </MDBCol>
                              );
                            })}
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol md="12" className="mb-3 mt-3">
              <h4 className="fw-bold text-center">PAUTAS DA SESSÃO</h4>
            </MDBCol>
            <MDBCol md="12" className="mb-2">
              <MDBRow className="mx-2">
                {materias == 0 && (
                  <MDBCol md={12} className="mb-3 materiasIsNull">
                    <p className="fs-3 text-center text-danger">
                      Não há pautas nesta sessão
                    </p>
                  </MDBCol>
                )}
                {materias
                  .filter((materia) => !materia.OrdemDia)
                  .map((item) => (
                    <MDBCol key={item.id} xl={12} lg={12} className="mb-3">
                      <MDBRow className="shadow infoMaterias mx-1">
                        <MDBCol md="12" className="mb-3">
                          <h3 className="fw-bold text-center">
                            MATÉRIA {item.Numero}
                          </h3>
                        </MDBCol>
                        <MDBCol sm="4" md="4">
                          <label>
                            <MDBIcon fas icon="spell-check" /> Número da matéria
                          </label>
                          <input
                            disabled
                            value={item.Numero}
                            type="text"
                            className="custom-input form-control"
                          />
                        </MDBCol>
                        <MDBCol sm="4" md="4">
                          <label>
                            <MDBIcon fas icon="calendar-check" /> Data da
                            matéria
                          </label>
                          <input
                            disabled
                            value={item.Data}
                            type="date"
                            className="custom-input form-control"
                          />
                        </MDBCol>
                        <MDBCol sm="4" md="4" className="">
                          <label>
                            <MDBIcon fas icon="caret-down" /> Tipo da matéria
                          </label>
                          <input
                            disabled
                            value={item.Tipo}
                            type="text"
                            className="custom-input form-control"
                          />
                        </MDBCol>
                        <MDBCol sm="12" md="12" className="mt-3">
                          <label>
                            <MDBIcon far icon="comment-alt" /> Resumo da matéria
                          </label>
                          <textarea
                            value={item.Resumo}
                            disabled
                            className="custom-input form-control"
                            rows="2"
                          />
                        </MDBCol>
                        <MDBCol md="12" className="mb-3">
                          <h4 className="text-center">AUTORES DA MATÉRIA</h4>
                        </MDBCol>
                        <MDBCol md="12" className="mb-3">
                          <MDBRow className="d-flex justify-content-center mb-3">
                            {item.ParlamentarMateria.map((item) => (
                              <MDBCol
                                key={item.id}
                                xl={3}
                                lg={6}
                                className="mb-3"
                              >
                                <MDBCard
                                  border={(() => {
                                    switch (item.Situacao) {
                                      case "Presente":
                                        return "success";
                                      case "Ausente":
                                        return "warning";
                                      case "Licença":
                                        return "primary";
                                    }
                                  })()}
                                  background="#dddddd"
                                >
                                  <MDBCardBody>
                                    {item.Name ? (
                                      <div className="d-flex align-items-center">
                                        <img
                                          src="/user.png"
                                          alt=""
                                          style={{
                                            width: "45px",
                                            height: "45px",
                                          }}
                                          className="rounded-circle"
                                        />
                                        <div className={`ms-3 text-overflow`}>
                                          <p className="fw-bold mb-1 text-overflow">
                                            {item.Name}
                                          </p>
                                          <p className="text-muted mb-0 text-overflow">
                                            {item.Situacao && (
                                              <MDBBadge
                                                pill
                                                color={(() => {
                                                  switch (item.Situacao) {
                                                    case "Presente":
                                                      return "success";
                                                    case "Ausente":
                                                      return "warning";
                                                    case "Licença":
                                                      return "primary";
                                                  }
                                                })()}
                                                light
                                              >
                                                {item.Situacao}
                                              </MDBBadge>
                                            )}{" "}
                                            Autor Externo
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex align-items-center">
                                        <img
                                          src={`${process.env.REACT_APP_API_URL}/${item.Parlamentar.image}`}
                                          alt=""
                                          style={{
                                            width: "45px",
                                            height: "45px",
                                          }}
                                          className="rounded-circle"
                                        />
                                        <div className={`ms-3 text-overflow`}>
                                          <p className="fw-bold mb-1 text-overflow">
                                            {item.Parlamentar.nickname ??
                                              item.Parlamentar.name}
                                          </p>
                                          <p className="fw-bold mb-1 text-overflow">
                                            {item.Parlamentar.partido}
                                          </p>
                                          <p className="text-muted mb-0 text-overflow">
                                            {item.Situacao && (
                                              <MDBBadge
                                                pill
                                                color={(() => {
                                                  switch (item.Situacao) {
                                                    case "Presente":
                                                      return "success";
                                                    case "Ausente":
                                                      return "warning";
                                                    case "Licença":
                                                      return "primary";
                                                  }
                                                })()}
                                                light
                                              >
                                                {item.Situacao}
                                              </MDBBadge>
                                            )}{" "}
                                            {item.Parlamentar.occupation}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </MDBCardBody>
                                </MDBCard>
                              </MDBCol>
                            ))}
                          </MDBRow>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  ))}
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </div>
      ) : (
        <div
          id="relatorio"
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100vw", height: "100vh" }}
        >
          <MDBRow>
            <MDBCol
              md="12"
              className="text-danger fs-1 fw-bold d-flex justify-content-center"
            >
              RELATÓRIO NÃO ENCONTRADO
            </MDBCol>
            <MDBCol md="12" className="d-flex justify-content-center">
              <MDBBtn
                onClick={() => closeThisPage()}
                className="mx-2 px-5"
                color="danger"
                size="lg"
              >
                FECHAR
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
      )}
    </>
  );
};

export default Relatorio;
