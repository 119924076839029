import "./TimerPainel.css";
import React, { useState, useEffect } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBBadge,
  MDBContainer,
} from "mdb-react-ui-kit";
import Timer from "../../Timer/Timer";
import { socketOn } from "../../../services/websockets";
import { useCamaraContext } from "../../../context/camaraContext";
import Slider from "react-slick";
import { loadParlamentarName } from "../../../common/util";

const TimerPainel = (props) => {
  const [seconds, setSeconds] = useState(0);
  const [parlamentar, setParlamentar] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [sharedParlamentar, setSharedParlamentar] = useState(null);
  const [sharedSeconds, setSharedSeconds] = useState(0);

  const { camara } = useCamaraContext();

  useEffect(() => {
    if (camara) {
      socketOn("Timer", (data) => {
        setSeconds(data.seconds);
        setParlamentar(data.parlamentar);
        setSharedSeconds(data.sharedSeconds);
        setSharedParlamentar(data.sharedParlamentar);
      });

      socketOn("StartTimer", (data) => {
        setIsRunning(true);
      });

      socketOn("CloseTimer", (data) => {
        setIsRunning(false);
        setSeconds(0);
        setParlamentar(null);
        setSharedSeconds(0);
        setSharedParlamentar(null);
      });
    }
  }, [camara]);

  const settingsCarousel = {
    accessibility: false,
    arrows: false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    pauseOnHover: false,
  };

  if (props.isParlamentar && props.timerQueue.length == 0 && !parlamentar) {
    return <></>;
  } else {
    return (
      <MDBContainer
        className={`d-flex align-items-center justify-content-center ${
          props.isParlamentar ? "" : "mt-5"
        }`}
      >
        <MDBCol
          className={`d-flex rounded-9 mx-4 align-items-center justify-content-center speak-container-empty ${
            !parlamentar ? "speaking-container-empty" : ""
          }`}
        >
          <MDBRow className="justify-content-start rounded-9 align-items-center">
            {!parlamentar && (
              <MDBIcon
                fas
                icon="microphone"
                size="10x"
                color="white"
                className="d-flex justify-content-center mx-3"
              />
            )}
            <Timer
              seconds={seconds}
              isRunning={isRunning}
              parlamentar={parlamentar}
              sharedSeconds={sharedSeconds}
              sharedParlamentar={sharedParlamentar}
              audioOn={props.soundOn ?? false}
            />
          </MDBRow>
        </MDBCol>

        <MDBCol className="d-flex rounded-9 text-white fw-bold align-items-center flex-column speak-queue-container">
          <MDBRow className="w-100 justify-content-center align-items-center speak-order-box">
            <h5 className="text-center fw-bold fs-2 text-white mt-3">
              ORDEM DE FALA
            </h5>
          </MDBRow>

          {props.timerQueue.length == 1 && parlamentar ? (
            <MDBCol className="d-flex text-center opacity-50 align-items-center">
              <h5 className="fw-bold fs-1 text-white text-center">
                ORDEM VAZIA
              </h5>
            </MDBCol>
          ) : (
            <MDBCol
              className={
                props.timerQueue.length >= 4 ? "carousel-container" : ""
              }
            >
              {props.timerQueue.length >= 4 ? (
                <Slider {...settingsCarousel}>
                  {props.timerQueue &&
                    props.timerQueue.map((item, index) =>
                      parlamentar &&
                      loadParlamentarName(item) ===
                        loadParlamentarName(parlamentar) ? null : (
                        <MDBCol xl={4} lg={6} className="mb-1 mt-2" key={index}>
                          <MDBCard>
                            <MDBCardBody>
                              <div className="d-flex align-items-center">
                                <MDBBadge
                                  className="ms-2 me-3 fs-6"
                                  color="primary"
                                >
                                  {parlamentar ? index : index + 1}°
                                </MDBBadge>
                                <img
                                  src={
                                    item.image
                                      ? `${process.env.REACT_APP_API_URL}/${item.image}`
                                      : "/user.png"
                                  }
                                  alt=""
                                  style={{ width: "60px", height: "60px" }}
                                  className="rounded-circle"
                                />
                                <div className="ms-3 text-overflow">
                                  <p className="fw-bold text-muted mb-1">
                                    {item.nickname ?? item.name}
                                  </p>
                                  <p className="fw-bold text-muted mb-1">
                                    {item.partido}
                                  </p>
                                  <p className="text-muted mb-0">
                                    {item.occupation}
                                  </p>
                                </div>
                              </div>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      )
                    )}
                </Slider>
              ) : (
                <>
                  {props.timerQueue &&
                    props.timerQueue.map((item, index) =>
                      parlamentar &&
                      loadParlamentarName(item) ===
                        loadParlamentarName(parlamentar) ? null : (
                        <MDBCard className="m-3" key={index}>
                          <MDBCardBody>
                            <div className="d-flex align-items-center">
                              <MDBBadge
                                className="ms-2 me-3 fs-6"
                                color="primary"
                              >
                                {parlamentar ? index : index + 1}°
                              </MDBBadge>
                              <img
                                src={
                                  item.image
                                    ? `${process.env.REACT_APP_API_URL}/${item.image}`
                                    : "/user.png"
                                }
                                alt=""
                                style={{ width: "60px", height: "60px" }}
                                className="rounded-circle"
                              />
                              <div className="ms-3 text-overflow">
                                <p className="fw-bold text-muted mb-1">
                                  {item.nickname ?? item.name}
                                </p>
                                <p className="fw-bold text-muted mb-1">
                                  {item.partido}
                                </p>
                                <p className="text-muted mb-0">
                                  {item.occupation}
                                </p>
                              </div>
                            </div>
                          </MDBCardBody>
                        </MDBCard>
                      )
                    )}
                </>
              )}
            </MDBCol>
          )}
        </MDBCol>
      </MDBContainer>
    );
  }
};

export default TimerPainel;
