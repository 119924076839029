import React, { useState, useEffect } from "react";
import { MDBCol, MDBRow, MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import { notify } from "../../common/toastConfig";
import {
  updateApprovalRuleMateria,
  getApprovalRuleMateriaByCamaraId,
  getTypesMateria,
} from "../../services/api";
import { useCamaraContext } from "../../context/camaraContext";

const MateriaRules = (props) => {
  const { camara } = useCamaraContext();
  const [rules, setRules] = useState([{ Type: "", Percentage: "" }]);
  const [availableOptions, setAvailableOptions] = useState([]);

  async function fetchData() {
    const typesMateriaResponse = await getTypesMateria(camara.id);
    setAvailableOptions(typesMateriaResponse);

    const currentRules = await getApprovalRuleMateriaByCamaraId(camara.id);

    if (currentRules.length > 0) setRules(currentRules);
  }

  useEffect(() => {
    if (camara) {
      fetchData();
    }
  }, [camara]);

  const handleSelectChange = (index, selectedOption) => {
    rules.forEach((rule) => {
      if (rule.Type === selectedOption) {
        notify(
          "Já existe uma regra cadastrada para esse tipo de matéria",
          "warning"
        );

        newRules[index].Type = null;

        return;
      }
    });

    const newRules = [...rules];
    newRules[index].Type = selectedOption;

    setRules(newRules);
  };

  const handlePercentageChange = (index, value) => {
    if (
      /^(100(\.\d{1,2})?|(\d{1,2}(\.\d{1,2})?|\d{1,2}(,\d{1,2})?))$/.test(
        value.replace(",", ".")
      ) ||
      value === ""
    ) {
      const newRules = [...rules];
      newRules[index].Percentage = value;
      setRules(newRules);
    }
  };

  function removeRule(rule) {
    if (rules.length === 1) {
      setRules([{ Type: "", Percentage: "" }]);
    } else {
      const updatedRules = rules.filter((item) => item !== rule);
      setRules(updatedRules);
    }
  }

  async function saveRules() {
    let validated = true;
    
    if (rules.length === 1) {
      if (!rules[0].Type && !rules[0].Percentage) {
        await updateApprovalRuleMateria(camara.id, []);
        return;
      } else if (!rules[0].Type || !rules[0].Percentage) validated = false;
    } else {
      rules.forEach((rule) => {
        if (!rule.Type || !rule.Percentage) validated = false;
      });
    }

    if (validated) await updateApprovalRuleMateria(camara.id, rules);
    else return notify("Preencha todas as informações", "warning");
  }

  const addNewRule = () => {
    for (const rule of rules) {
      if (!rule.Type) {
        notify("O campo tipo é obrigatório", "warning");
        return;
      }

      if (!rule.Percentage) {
        notify("O campo porcentagem é obrigatório", "warning");
        return;
      }
    }

    setRules([...rules, { Type: "", Percentage: "" }]);
  };

  return (
    <div>
      <MDBRow className="my-2 mx-1">
        <div className="note note-warning mt-0">
          <strong>Atenção:</strong> Por padrão a porcentagem para aprovação de
          matérias é para quantidade de votos a favor maior que 50%
        </div>
      </MDBRow>
      <MDBRow className="my-2">
        <MDBCol size={6}>
          <label>
            <MDBIcon fas icon="caret-down" /> TIPO DA MATÉRIA
          </label>
        </MDBCol>
        <MDBCol size={6}>
          <label>
            <MDBIcon fas icon="Percentage" /> PORCENTAGEM PARA APROVAÇÃO
          </label>
        </MDBCol>
      </MDBRow>
      {rules.map((rule, index) => (
        <MDBRow key={index} className="mb-2">
          <MDBCol size={6}>
            <select
              value={rule.Type}
              onChange={(e) => handleSelectChange(index, e.target.value)}
              className="form-select"
            >
              <option value="">Selecione uma opção</option>
              {availableOptions.map((option, optionIndex) => (
                <option key={optionIndex} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </MDBCol>
          <MDBCol size={5}>
            <input
              type="number"
              value={rule.Percentage}
              onChange={(e) => handlePercentageChange(index, e.target.value)}
              placeholder="Porcentagem para aprovação"
              className="form-control"
            />
          </MDBCol>
          <MDBCol size={1} className="d-flex align-items-center">
            <MDBIcon
              fas
              icon="times-circle"
              color="danger"
              size="1"
              title="Remover regra"
              onClick={() => removeRule(rule)}
              style={{ cursor: "pointer" }}
            />
          </MDBCol>
        </MDBRow>
      ))}
      <MDBRow>
        <MDBCol size={12} className="d-flex justify-content-end mb-5">
          <MDBBtn
            onClick={addNewRule}
            color="primary"
            disabled={availableOptions.length === rules.length}
          >
            <MDBIcon fas icon="plus-circle" /> Nova regra
          </MDBBtn>
          <MDBBtn
            className="ms-2"
            onClick={saveRules}
            color="success"
            disabled={availableOptions.length === rules.length}
          >
            <MDBIcon fas icon="save" /> Salvar regras
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </div>
  );
};

export default MateriaRules;
