import React, { useState } from "react";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBCol,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import { getMateriasBySessaoId } from "../../services/api";
import CardParlamentar from "../CardParlamentar/CardParlamentar";

const Accordion = (props) => {
  return (
    <>
      <MDBAccordion>
        {props.itens.map((item, index) => (
          <MDBAccordionItem
            key={index}
            collapseId={index + 1}
            headerTitle={
              <>
                <MDBIcon fas icon="caret-down" color="success" /> &nbsp;{" "}
                {item[0].partido}
              </>
            }
          >
            <div>
              <MDBRow>
                {item.map((parlamentar, index) => (
                  <MDBCol size={4} className="mb-1" key={index}>
                    <CardParlamentar parlamentar={parlamentar} />
                  </MDBCol>
                ))}
              </MDBRow>
            </div>
          </MDBAccordionItem>
        ))}
      </MDBAccordion>
    </>
  );
};

export default Accordion;
