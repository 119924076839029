import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
  withCredentials: true,
});

export const socketOn = (event, functionToExecute) => {
  socket.on(event, (data) => {
    return functionToExecute(data);
  });
};

export const socketEmit = (event, data) => {
  socket.emit(event, data);
};
