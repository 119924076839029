import "../../index.css";
import "./Parlamentar.css";
import React, { useState, useEffect } from "react";
import {
  MDBCol,
  MDBRow,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBadge,
} from "mdb-react-ui-kit";
import Timer from "../../components/Timer/Timer";
import {
  getParlamentarByUserId,
  getSessaoByDataAndCamaraId,
  addVoteToMateria,
  checkPresenceOfParliamentarianInSession,
  sendPresenceConfirmation,
  getWebSocketHistoricByCamaraId,
} from "../../services/api";
import { notify } from "../../common/toastConfig";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import Spinner from "../../components/Spinner/Spinner";
import PdfView from "../../components/PdfView/PdfView";
import ParlamentarSidebar from "../../components/ParlamentarSidebar/ParlamentarSidebar";
import { socketEmit, socketOn } from "../../services/websockets";
import { useNavigate } from "react-router-dom";
import { useCamaraContext } from "../../context/camaraContext";
import {
  getCityByUrl,
  reloadScreen,
  useFullScreenToggle,
} from "../../common/util";
import TimerPainel from "../../components/Painel/TimerPainel/TimerPainel";
import SessaoInfo from "../../components/Painel/SessaoInfo/SessaoInfo";
import ReadMateria from "../../components/Painel/ReadMateria/ReadMateria";

const dataAtual = new Date();
const dataFormatada = format(dataAtual, "EEEE, dd 'de' MMMM 'de' yyyy", {
  locale: ptBR,
}).toUpperCase();

const Parlamentar = () => {
  const [loading, setLoading] = useState(true);
  const [sessao, setSessao] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [addSeconds, setAddSeconds] = useState(0);
  const [parlamentar, setParlamentar] = useState({});
  const [isRunning, setIsRunning] = useState(false);
  const [isCloseTimer, setIsCloseTimer] = useState(false);
  const [timerQueue, setTimerQueue] = useState([]);
  const [materiaReading, setMateriaReading] = useState(null);
  const [votingInProgress, setVotingInProgress] = useState(false);
  const [materiaToVoting, setMateriaToVoting] = useState(null);
  const [voted, setVoted] = useState(null);
  const [needsPresence, setNeedsPresence] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [parlamentarLogged, setParlamentarLogged] = useState(null);
  const [parlamentarSharedTimer, setParlamentarSharedTimer] = useState(null);
  const [currentSecondsSharedTimer, setCurrentSecondsSharedTimer] = useState(0);
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const navigate = useNavigate();
  const { camara } = useCamaraContext();
  const { isFullScreen, toggleFullScreen } = useFullScreenToggle();

  const toggleShowPdfModal = () => setPdfModal(!pdfModal);

  async function presenceConfirmation() {
    const parlamentarLoggedId = JSON.parse(
      localStorage.getItem("parlamentarLogged")
    ).id;

    const presenceConfirmation = await sendPresenceConfirmation({
      parlamentarId: parlamentarLoggedId,
      sessaoId: sessao.id,
    });

    if (presenceConfirmation) {
      setNeedsPresence(!presenceConfirmation);
      notify("Presença confirmada", "success");
    }
  }

  async function handleVoted(value) {
    if (voted && voted === value) return;

    if (value === "Nao Participar") {
      notify(voted ? "Voto atualizado" : "Voto realizado", "success");
      setVoted(value);
    } else {
      const parlamentarLoggedId = JSON.parse(
        localStorage.getItem("parlamentarLogged")
      ).id;

      socketEmit("SendVote", {
        CamaraId: camara ? camara.id : null,
        Content: {
          Value: value,
          MateriaId: materiaToVoting.id,
          ParlamentarId: parlamentarLoggedId,
        },
      });

      await addVoteToMateria({
        status: value,
        parlamentarId: parlamentarLoggedId,
        materiaId: materiaToVoting.id,
      });

      notify(voted ? "Voto atualizado" : "Voto realizado", "success");

      setVoted(value);
    }
  }

  async function fetchData() {
    setLoading(true);

    try {
      const user = JSON.parse(localStorage.getItem("user"));

      const parlamentarResponse = await getParlamentarByUserId(user.id);
      const parlamentar = parlamentarResponse.data.parlamentar;
      localStorage.setItem("parlamentarLogged", JSON.stringify(parlamentar));
      setParlamentarLogged(parlamentar);

      const moment = require("moment-timezone");
      const currentStringDate = moment()
        .tz("America/Sao_Paulo")
        .format("YYYY-MM-DD");
      const sessaoResponse = await getSessaoByDataAndCamaraId({
        data: currentStringDate,
        camaraId: parlamentar.CamaraId,
      });
      if (sessaoResponse.data.sessao) {
        setSessao(sessaoResponse.data.sessao);
        setNeedsPresence(
          !(await checkPresenceOfParliamentarianInSession(
            sessaoResponse.data.sessao.id,
            parlamentar.id
          ))
        );
      }

      const webSocketsHistoric = await getWebSocketHistoricByCamaraId(
        parlamentar.CamaraId
      );

      webSocketsHistoric.forEach((webSocketHistoric) => {
        switch (webSocketHistoric.Event) {
          case "AddOneMinuteToTimer":
            setAddSeconds(webSocketHistoric.Data.currentSeconds);
            break;
          case "PauseTimer":
            setIsRunning(!webSocketHistoric.Data);
            break;
          case "CloseTimer":
            setParlamentar({});
            setIsCloseTimer(webSocketHistoric.Data);
            setSeconds(0);
            break;
          case "TimerQueue":
            setTimerQueue(webSocketHistoric.Data);
            break;
          case "ReadyMatter":
            setMateriaReading(webSocketHistoric.Data);
            break;
          case "EndReadyMatter":
            setMateriaReading(null);
            break;
          case "StartVoting":
            setMateriaReading(null);

            const parlamentarLoggedId = JSON.parse(
              localStorage.getItem("parlamentarLogged")
            ).id;

            setVotingInProgress(
              webSocketHistoric.Data.chamada.some(
                (obj) => obj.ParlamentarId === parlamentarLoggedId
              )
            );

            setMateriaToVoting(webSocketHistoric.Data.materia);
            break;
          case "EndVoting":
            setVotingInProgress(false);
            setVoted(null);
            setMateriaToVoting(null);
            localStorage.removeItem("materiaToVoting");
            break;
          case "StartTimer":
            let currentSeconds;
            try {
              currentSeconds =
                webSocketHistoric.Data.seconds -
                Math.floor(
                  (new Date().getTime() -
                    new Date(webSocketHistoric.Data.initDate).getTime()) /
                    1000
                );

              if (currentSeconds < 0) currentSeconds = 0;
            } catch (ex) {
              currentSeconds = 0;
            }
            setSeconds(
              parseInt(currentSeconds == seconds ? seconds + 1 : currentSeconds)
            );
            setParlamentar(webSocketHistoric.Data.parlamentar);
            setIsRunning(true);
            break;
          case "StartSharedTimer":
            setParlamentarSharedTimer(webSocketHistoric.Data.parlamentar);
            let currentSecondsSharedTimerTemp;
            try {
              currentSecondsSharedTimerTemp = Math.floor(
                (new Date().getTime() -
                  new Date(webSocketHistoric.Data.initDate).getTime()) /
                  1000
              );

              if (currentSecondsSharedTimerTemp < 0)
                currentSecondsSharedTimerTemp = 0;
            } catch (ex) {
              currentSecondsSharedTimerTemp = 0;
            }
            setCurrentSecondsSharedTimer(
              parseInt(currentSecondsSharedTimerTemp)
            );
            break;
        }
      });

      checkUser();
    } catch (error) {
      notify("Erro interno, tente novamente em instantes", "danger");
    }

    setLoading(false);
  }

  useEffect(() => {
    if (camara) {
      socketEmit("JoinCamara", { CamaraId: camara.id });

      fetchData();

      socketOn("AddOneMinuteToTimer", (data) => {
        setAddSeconds(data.currentSeconds);
      });

      socketOn("PauseTimer", (data) => {
        setIsRunning(!data);
      });

      socketOn("CloseTimer", (data) => {
        setParlamentar({});
        setIsCloseTimer(data);
        setSeconds(0);
      });

      socketOn("TimerQueue", (data) => {
        setTimerQueue(data);
      });

      socketOn("ReadyMatter", (data) => {
        setMateriaReading(data);
      });

      socketOn("EndReadyMatter", (data) => {
        setMateriaReading(null);
      });

      socketOn("StartVoting", async (data) => {
        setMateriaReading(null);

        const parlamentarLoggedId = JSON.parse(
          localStorage.getItem("parlamentarLogged")
        ).id;

        setVotingInProgress(
          data.chamada.some((obj) => obj.ParlamentarId === parlamentarLoggedId)
        );

        setMateriaToVoting(data.materia);
      });

      socketOn("EndVoting", (data) => {
        setVotingInProgress(false); //validar isso pela camaraId da Url
        setVoted(null);
        setMateriaToVoting(null);
        localStorage.removeItem("materiaToVoting");
      });

      socketOn("StartSharedTimer", (data) => {
        setParlamentarSharedTimer(data.parlamentar);
      });

      socketOn("EndSharedTimer", (data) => {
        setParlamentarSharedTimer(null);
      });
    }
  }, [camara]);

  useEffect(() => {
    socketOn("StartTimer", (data) => {
      setSeconds(
        parseInt(data.seconds === seconds ? seconds + 1 : data.seconds)
      );
      setParlamentar(data.parlamentar);
      setIsRunning(true);
    });
  }, [seconds]);

  useEffect(() => {
    setCurrentSecondsSharedTimer(0);
  }, [parlamentarSharedTimer]);

  useEffect(() => {
    if (sessao === null) {
      const interval = setInterval(() => {
        fetchData();
      }, 15000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [sessao]);

  function checkUser() {
    if (camara) {
      let idCamaraFromParlamentar = JSON.parse(
        localStorage.getItem("parlamentarLogged")
      ).CamaraId;

      if (idCamaraFromParlamentar !== camara.id) {
        navigate(`/${getCityByUrl()}/login`);
      }
    }
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div id="parlamentar">
        <Spinner show={loading} />
        {camara && (
          <>
            {sessao && (
              <MDBModal
                show={needsPresence}
                className="modalPresence"
                nonInvasive={true}
                tabIndex="-1"
              >
                <MDBModalDialog centered size="sm">
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>
                        <MDBIcon fas icon="receipt" /> PRESENÇA
                      </MDBModalTitle>
                    </MDBModalHeader>
                    <MDBModalBody>
                      Deseja confirmar a presença na sessão "{sessao.Numero}"?
                    </MDBModalBody>

                    <MDBModalFooter className="justify-content-center">
                      <MDBBtn onClick={presenceConfirmation} color="success">
                        <MDBIcon fas icon="check-circle" /> Confirmar
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
            )}
            {parlamentarLogged && (
              <MDBRow className="d-flex m-3 mb-0 mt-0 d-flex justify-content-center w-100 ms-0">
                <MDBCol className="d-flex user-select-none justify-content-between align-items-center  imagem-camara-container">
                  <span className="fw-bold text-white opacity-50 fs-2">
                    {time}
                  </span>
                  <img
                    className="image-camara me-5 w-0 h-100"
                    src={camara && camara.LogoPath}
                  />
                  <ParlamentarSidebar
                    parlamentar={parlamentarLogged}
                    sessao={sessao}
                  />
                </MDBCol>
              </MDBRow>
            )}

            <MDBRow className=" justify-content-center align-items-center w-100 mt-3">
              {!sessao &&
                !materiaReading &&
                !votingInProgress &&
                !voted &&
                !timerQueue.length &&
                Object.keys(parlamentar).length === 0 && (
                  <>
                    <MDBCol className="d-flex justify-content-center Centralize-Camaralogo">
                      <img
                        className="img-fluid center"
                        src={camara && camara.LogoPath}
                        alt="Logo"
                      />
                    </MDBCol>
                  </>
                )}

              {sessao &&
                !materiaReading &&
                !votingInProgress &&
                !voted &&
                !timerQueue.length &&
                Object.keys(parlamentar).length === 0 && (
                  <MDBCol className="d-flex justify-content-center align-items-center mt-5">
                    <SessaoInfo isParlamentar={true} sessao={sessao} />
                  </MDBCol>
                )}

              {!materiaReading && !votingInProgress && (
                <TimerPainel
                  isParlamentar={true}
                  timerQueue={timerQueue}
                  soundOn={false}
                />
              )}

              {materiaReading && !votingInProgress && (
                <ReadMateria
                  IsParlamentar={true}
                  materia={materiaReading}
                  sessao={sessao}
                />
              )}

              {votingInProgress && !materiaReading && (
                <div
                  className="d-flex justify-content-center align-items-center ms-2"
                  style={{ minHeight: "80vh" }}
                >
                  <MDBCol className="ms-5">
                    <MDBRow className="d-flex align-items-start text-center">
                      <MDBCol md="8" className="mb-3 mb-md-0 mt-5">
                        <MDBCol className="d-flex justify-content-between align-items-center materiaInfoVote rounded-9">
                          <p className="d-flex text-white fw-bold fs-3 mt-3 ms-3">
                            Nº{materiaToVoting.Numero}
                            {materiaToVoting.Path && (
                              <>
                                <MDBCol className="d-flex ms-2">
                                  <MDBBtn
                                    onClick={toggleShowPdfModal}
                                    color="primary"
                                  >
                                    <MDBIcon fas icon="file-pdf" /> Ler
                                    documento
                                  </MDBBtn>
                                </MDBCol>
                                <MDBModal
                                  tabIndex="-1"
                                  show={pdfModal}
                                  setShow={setPdfModal}
                                >
                                  <MDBModalDialog size="fullscreen">
                                    <MDBModalContent>
                                      <MDBModalHeader>
                                        <MDBBtn
                                          type="button"
                                          className="btn-close"
                                          color="none"
                                          onClick={toggleShowPdfModal}
                                        ></MDBBtn>
                                      </MDBModalHeader>
                                      <MDBModalBody>
                                        <PdfView
                                          url={`${process.env.REACT_APP_API_URL}/${materiaToVoting.Path}`}
                                        />
                                      </MDBModalBody>
                                      <MDBModalFooter>
                                        <MDBBtn
                                          type="button"
                                          color="primary"
                                          onClick={toggleShowPdfModal}
                                        >
                                          Fechar
                                        </MDBBtn>
                                      </MDBModalFooter>
                                    </MDBModalContent>
                                  </MDBModalDialog>
                                </MDBModal>
                              </>
                            )}
                          </p>
                          <p className="text-white fw-bold fs-3 mt-3 me-3">
                            {materiaToVoting.Tipo}
                          </p>
                        </MDBCol>
                        <MDBCol className="rounded-8 text-center text-white fw-bold mt-2 VoteInfoBox align-items-center">
                          <MDBCol className="d-flex justify-content-center align-items-center materiaInfoVote rounded-7 rounded-bottom">
                            <MDBIcon
                              icon="info-circle"
                              color="white"
                              className="fs-3 m-2"
                            />
                            <p className="text-white fw-bold fs-3 mt-3">
                              SOBRE A MATÉRIA
                            </p>
                          </MDBCol>

                          <MDBCol className="ResumoTextBox">
                            <p>{materiaToVoting.Resumo}</p>
                          </MDBCol>
                        </MDBCol>
                      </MDBCol>

                      <MDBCol
                        md="4"
                        className="d-flex flex-column justify-content-center mt-5 voteCardsColumn"
                      >
                        <h1 className="votacaoWarning text-center mt-2">
                          SELECIONE UMA OPÇÃO
                        </h1>

                        <MDBCol className="mt-4">
                          <div
                            onClick={() => handleVoted("A favor")}
                            className={`${
                              voted && voted != "A favor" ? "disabled" : ""
                            } votacaoItem bg-success d-flex justify-content-center align-items-center`}
                          >
                            <div className="fw-bold">
                              A FAVOR
                              <br />
                              <MDBIcon
                                className="mt-2"
                                fas
                                icon="check"
                                size="4x"
                              />
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol className="mt-2">
                          <div
                            onClick={() => handleVoted("Se absteve")}
                            className={`${
                              voted && voted != "Se absteve" ? "disabled" : ""
                            } votacaoItem bg-warning d-flex justify-content-center align-items-center`}
                          >
                            <div className="fw-bold">
                              SE ABSTER
                              <br />
                              <MDBIcon
                                className="mt-2"
                                far
                                icon="circle"
                                size="4x"
                              />
                            </div>
                          </div>
                        </MDBCol>
                        <MDBCol className="mt-2">
                          <div
                            onClick={() => handleVoted("Contra")}
                            className={`${
                              voted && voted != "Contra" ? "disabled" : ""
                            } votacaoItem bg-danger d-flex justify-content-center align-items-center`}
                          >
                            <div className="fw-bold">
                              CONTRA
                              <br />
                              <MDBIcon
                                className="mt-2"
                                fas
                                icon="times"
                                size="4x"
                              />
                            </div>
                          </div>
                        </MDBCol>
                        {parlamentarLogged?.occupation === "PRESIDENTE" && (
                          <MDBCol className="mt-2">
                            <div
                              onClick={() => handleVoted("Nao Participar")}
                              className={`${
                                voted && voted != "Nao Participar"
                                  ? "disabled"
                                  : ""
                              } votacaoItem bg-secondary d-flex justify-content-center align-items-center`}
                            >
                              <div className="fw-bold">
                                Não Participar
                                <br />
                                <MDBIcon
                                  className="mt-2"
                                  fas
                                  icon="diamond"
                                  size="4x"
                                />
                              </div>
                            </div>
                          </MDBCol>
                        )}
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </div>
              )}
            </MDBRow>
          </>
        )}

        <>
          <MDBCol className="p-2">
            <span>
              <MDBIcon
                onClick={toggleFullScreen}
                icon={
                  isFullScreen ? "compress-arrows-alt" : "expand-arrows-alt"
                }
                size="2x"
                className="fullscreenIcon me-3"
                tabIndex="1"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    toggleFullScreen();
                  }
                }}
              />
            </span>
            <span>
              <MDBIcon
                onClick={reloadScreen}
                icon="redo-alt"
                size="2x"
                className="reloadscreenIcon me-3"
                tabIndex="2"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    reloadScreen();
                  }
                }}
              />
            </span>
          </MDBCol>
        </>
      </div>
    </>
  );
};

export default Parlamentar;
