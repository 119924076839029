import "./Configuracoes.css";
import React, { useState, useEffect } from "react";
import { useCamaraContext } from "../../context/camaraContext";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import Parameters from "../../components/Parameters/Parameters";
import MateriasRules from "../../components/MateriasRules/MateriasRules";

const Configuracoes = () => {
  const { camara } = useCamaraContext();
  const [loading, setLoading] = useState(true);

  async function fetchData() {}

  useEffect(() => {
    if (camara) fetchData();
  }, [camara]);

  return (
    <div>
      {camara && (
        <div className="content mx-3">
          <MDBRow className="d-flex justify-content-center d-flex aling-itens-center mt-5">
            <h2 className="text-center">PARÂMETROS</h2>
          </MDBRow>
          <Parameters camara={camara} />
          <MDBRow className="d-flex justify-content-center d-flex aling-itens-center mt-5">
            <h2 className="text-center">REGRAS PARA APROVAÇÃO DE MATÉRIAS</h2>
          </MDBRow>
          <MateriasRules camara={camara} />
        </div>
      )}
    </div>
  );
};

export default Configuracoes;
