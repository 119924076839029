import "./NotFound.css";
import React from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { returnToHomePage, getCityByUrl } from "../../common/util";

const NotFound = () => {
  return (
    <>
      {window.location.pathname !== `/${getCityByUrl()}/login` &&
        window.location.pathname !== `/${getCityByUrl()}` &&
        window.location.pathname !== `/${getCityByUrl()}/` &&
        window.location.pathname !== `/${getCityByUrl()}/painel` &&
        window.location.pathname !== `/${getCityByUrl()}/vereador` &&
        window.location.pathname.toLowerCase() !==
          `/${getCityByUrl()}/relatoriotemporeal` && (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <MDBRow className="w-100">
              <MDBCol md="12" className="text-center">
                <MDBIcon icon="vote-yea" color="primary" /> VOTAÇÃO WEB
              </MDBCol>
              <MDBCol md="12" className="text-center">
                <h1 className="display-1 fw-bold">404</h1>
              </MDBCol>
              <MDBCol md="12" className="text-center">
                <h1 className="display-6">Página não encontrada!</h1>
              </MDBCol>
              <MDBCol md="12" className="text-center">
                <h1 className="display-5">
                  A página que está procurando talvez não exista.
                </h1>
              </MDBCol>
              <MDBCol md="12" className="text-center">
                <button
                  type="button"
                  class="btn btn-primary btn-lg"
                  onClick={() => returnToHomePage()}
                >
                  Início
                </button>
              </MDBCol>
            </MDBRow>
          </div>
        )}
    </>
  );
};

export default NotFound;
