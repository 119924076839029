import React, { useState, useEffect } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBadge,
  MDBProgress,
  MDBProgressBar,
} from "mdb-react-ui-kit";
import { socketEmit, socketOn } from "../../services/websockets";
import { notify } from "../../common/toastConfig";
import {
  getMateriaSituationById,
  getParlamentaresPresentes,
  getVotosByMateriaId,
  getWebSocketHistoricByCamaraId,
} from "../../services/api";
import CardParlamentar from "../CardParlamentar/CardParlamentar";

const VotingControlModal = (props) => {
  const materia = props.materiaToVote;
  const camara = props.camara;

  //#region states
  const [showModal, setshowModal] = useState(false);
  const [votingInProgress, setVotingInProgress] = useState(false);
  const [isSecretVote, setIsSecretVote] = useState(false);
  const [showSecretVotes, setShowSecretVotes] = useState(false);
  const [parlamentares, setparlamentares] = useState([]);
  const [materiaSituation, setMateriaSituation] = useState(null);
  //#endregion

  async function fetchData() {
    if (materia) {
      setIsSecretVote(materia.IsSecretVote);
      const parlamentaresResponse = await getParlamentaresPresentes(
        materia.SessaoId
      );
      const votosResponse = await getVotosByMateriaId(materia.id);

      for (let i = 0; i < parlamentaresResponse.length; i++) {
        const voto = votosResponse.find(
          (voto) => voto.ParlamentarId === parlamentaresResponse[i].id
        );

        if (voto) {
          switch (voto.Status) {
            case "A favor":
              parlamentaresResponse[i].status = "success";
              break;
            case "Se absteve":
              parlamentaresResponse[i].status = "warning";
              break;
            case "Contra":
              parlamentaresResponse[i].status = "danger";
              break;
            default:
              parlamentaresResponse[i].status = "info";
          }
        }
      }

      setparlamentares(parlamentaresResponse);

      const webSocketsHistoric = await getWebSocketHistoricByCamaraId(
        props.camara.id
      );

      webSocketsHistoric.forEach(async (webSocketHistoric) => {
        switch (webSocketHistoric.Event) {
          case "StartVoting":
            setVotingInProgress(true);
            break;
          case "ShowSecretVotes":
            setShowSecretVotes(!webSocketHistoric.Data.ShowSecretVotes);
            break;
        }
      });
    }
  }

  //#region useEffects
  useEffect(() => {
    fetchData();
  }, [props.materiaToVote]);

  useEffect(() => {
    socketOn("ReceiveVote", async (data) => {
      data.Parlamentar.status = data.Value;

      switch (data.Value) {
        case "A favor":
          data.Parlamentar.status = "success";
          break;
        case "Se absteve":
          data.Parlamentar.status = "warning";
          break;
        case "Contra":
          data.Parlamentar.status = "danger";
          break;
        default:
          data.Parlamentar.status = "info";
      }

      setparlamentares((parlamentares) => {
        const novosParlamentares = parlamentares.map((parlamentarPresente) =>
          parlamentarPresente.id === data.Parlamentar.id
            ? data.Parlamentar
            : parlamentarPresente
        );

        return novosParlamentares;
      });
    });
  }, []);
  //#endregion

  //#region handleBtnFunctions
  async function handleBtnEndVoting() {
    setIsSecretVote(false);
    setShowSecretVotes(false);
    setVotingInProgress(false);
    socketEmit("EndVoting", { CamaraId: camara.id, Content: { materia } });
    notify("Votação encerrada", "success");
    props.functionToExecute(props.parameterToFunction);
  }

  async function handleBtnInitVoting(isSecretVote) {
    if (isSecretVote) setIsSecretVote(true);
    else setIsSecretVote(false);

    setVotingInProgress(true);

    let materiaToSend = { ...materia };
    materiaToSend.IsSecretVote = isSecretVote;

    socketEmit("StartVoting", { CamaraId: camara.id, Content: materiaToSend });
  }

  async function handleBtnShowSecretVotes() {
    setShowSecretVotes(!showSecretVotes);

    socketEmit("ShowSecretVotes", {
      CamaraId: camara.id,
      Content: { ShowSecretVotes: showSecretVotes },
    });

    notify(
      showSecretVotes
        ? "Os votos foram revelados no painel"
        : "Os votos foram ocultados no painel",
      "success"
    );
  }
  //#endregion

  useEffect(() => {
    if (props.materiaToVote) {
      const intervalId = setInterval(async () => {
        const materiaSituationResponse = await getMateriaSituationById(
          props.materiaToVote.id
        );
        setMateriaSituation(materiaSituationResponse.situation);

        if (
          materiaSituationResponse.situation === "Aprovada" ||
          materiaSituationResponse.situation === "Reprovada"
        )
          clearInterval(intervalId);
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [props.materiaToVote]);

  //#region internalComponents
  const PublicVoteBodyModal = (parlamentares) => {
    return (
      <>
        <MDBRow>
          {parlamentares.map((parlamentar, index) => (
            <MDBCol size={4} className="mb-1" key={index}>
              <CardParlamentar
                parlamentar={parlamentar}
                subtitle={parlamentar.Occupation ?? ""}
                background={parlamentar.status}
              />
            </MDBCol>
          ))}
        </MDBRow>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size={4} className="d-flex justify-content-center mt-4">
            <MDBBadge color="danger" light>
              Contra
            </MDBBadge>
            <MDBBadge className="mx-2" color="warning" light>
              Se asbteve
            </MDBBadge>
            <MDBBadge color="success" light>
              A favor
            </MDBBadge>
          </MDBCol>
        </MDBRow>
      </>
    );
  };

  const SecretVoteBodyModal = (parlamentares, showSecretVotes) => {
    return (
      <>
        <MDBRow>
          {parlamentares.map((parlamentar, index) => (
            <MDBCol size={4} className="mb-1" key={index}>
              <CardParlamentar
                parlamentar={parlamentar}
                subtitle={parlamentar.Occupation ?? ""}
                background={
                  parlamentar.status
                    ? showSecretVotes
                      ? parlamentar.status
                      : "info"
                    : null
                }
              />
            </MDBCol>
          ))}
        </MDBRow>
        <MDBRow className="d-flex justify-content-center">
          <MDBCol size={4} className="d-flex justify-content-center mt-4">
            {showSecretVotes ? (
              <>
                <MDBBadge color="danger" light>
                  Contra
                </MDBBadge>
                <MDBBadge className="mx-2" color="warning" light>
                  Se asbteve
                </MDBBadge>
                <MDBBadge color="success" light>
                  A favor
                </MDBBadge>
              </>
            ) : (
              <MDBBadge color="info" light>
                Voto realizado
              </MDBBadge>
            )}
          </MDBCol>
        </MDBRow>
      </>
    );
  };
  //#endregion

  if (!props.materiaToVote) {
    return <></>;
  } else {
    return (
      <div className="VotingControlModal">
        <MDBModal
          show={props.showModal}
          setShow={setshowModal}
          tabIndex="-1"
          staticBackdrop
        >
          <MDBModalDialog size={"xl"}>
            <MDBModalContent>
              <MDBModalHeader>
                <MDBModalTitle>
                  {votingInProgress
                    ? `VOTAÇÃO EM ANDAMENTO (${
                        parlamentares.filter((x) => x.status !== undefined)
                          .length
                      } de ${parlamentares.length} votos)`
                    : "INICIANDO VOTAÇÃO"}
                </MDBModalTitle>
              </MDBModalHeader>
              {votingInProgress && (
                <MDBProgress height="8">
                  <MDBProgressBar
                    striped
                    animated
                    width={
                      (parlamentares.filter((x) => x.status !== undefined)
                        .length *
                        100) /
                      parlamentares.length
                    }
                    valuemin={0}
                    valuemax={parlamentares.length}
                  />
                </MDBProgress>
              )}
              <MDBModalBody>
                {votingInProgress ? (
                  isSecretVote ? (
                    SecretVoteBodyModal(parlamentares, showSecretVotes)
                  ) : (
                    PublicVoteBodyModal(parlamentares)
                  )
                ) : (
                  <MDBRow className="d-flex justify-content-center">
                    <MDBCol size={6} className="d-flex justify-content-center">
                      <MDBBtn
                        onClick={() => handleBtnInitVoting(false)}
                        className="mt-4 mx-1"
                        color={"success"}
                      >
                        <MDBIcon fas icon={"users"} /> INICIAR VOTAÇÃO
                      </MDBBtn>
                      <MDBBtn
                        onClick={() => handleBtnInitVoting(true)}
                        className="mt-4 mx-1"
                        color={"warning"}
                      >
                        <MDBIcon fas icon={"lock"} /> INICIAR VOTAÇÃO SECRETA
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                )}
              </MDBModalBody>

              {((votingInProgress && !isSecretVote) || showSecretVotes) && (
                <MDBBadge color="null">
                  <MDBCol md={12}>
                    {materiaSituation == null && (
                      <MDBBadge
                        className="w-25 justify-content-center shadow rounded-9"
                        color="primary"
                      >
                        <p className="mt-3 fs-6">
                          <MDBIcon fas icon="info-circle" /> CARREGANDO...
                        </p>
                      </MDBBadge>
                    )}
                    {materiaSituation == "Reprovada" && (
                      <MDBBadge
                        className="w-25 justify-content-center shadow rounded-9"
                        color="danger"
                      >
                        <p className="mt-3 fs-6">
                          <MDBIcon fas icon="close" /> MATÉRIA REPROVADA
                        </p>
                      </MDBBadge>
                    )}

                    {materiaSituation == "Aprovada" && (
                      <MDBBadge
                        className="w-25 justify-content-center shadow rounded-9"
                        color="success"
                      >
                        <p className="mt-3 fs-6">
                          <MDBIcon fas icon="check" /> MATÉRIA APROVADA
                        </p>
                      </MDBBadge>
                    )}

                    {materiaSituation == "Em votação" && (
                      <MDBBadge
                        className="w-25 justify-content-center shadow rounded-9"
                        color="primary"
                      >
                        <p className="mt-3 fs-6">
                          <MDBIcon fas icon="vote-yea" /> VOTAÇÃO EM ANDAMENTO
                        </p>
                      </MDBBadge>
                    )}

                    {materiaSituation == "Não votada" && (
                      <MDBBadge
                        className="w-25 justify-content-center shadow rounded-9"
                        color="warning"
                      >
                        <p className="mt-3 fs-6">
                          <MDBIcon fas icon="info-circle" /> MATÉRIA NÃO VOTADA
                        </p>
                      </MDBBadge>
                    )}
                  </MDBCol>
                </MDBBadge>
              )}

              <MDBModalFooter>
                {isSecretVote && (
                  <MDBBtn
                    onClick={() => handleBtnShowSecretVotes()}
                    color={showSecretVotes ? "warning" : "success"}
                  >
                    <MDBIcon
                      fas
                      icon={showSecretVotes ? "lock" : "lock-open"}
                    />
                    {showSecretVotes
                      ? " Ocultar resultado da votação"
                      : " Revelar resultado da votação"}
                  </MDBBtn>
                )}
                <MDBBtn onClick={handleBtnEndVoting} color="danger">
                  <MDBIcon fas icon="close" /> Encerrar votação
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>
    );
  }
};

export default VotingControlModal;
