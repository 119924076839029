import "./Header.css";
import React, { useState } from "react";
import { MDBRipple, MDBRow, MDBCol } from "mdb-react-ui-kit";

const Header = (props) => {
  return (
    <header>
      <MDBRow>
        <MDBCol className="d-flex justify-content-center">
          <MDBRipple rippleTag="a">
            <img
              src={props.camara.LogoPath}
              className="img-fluid rounded image-camara"
              alt="example"
              id="imgHeader"
            />
          </MDBRipple>
        </MDBCol>
      </MDBRow>
    </header>
  );
};

export default Header;
