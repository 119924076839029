import "./SessaoInfo.css";
import React from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { useCamaraContext } from "../../../context/camaraContext";
import { convertStringDateForBrDate } from "../../../common/util";

const SessaoInfo = (props) => {
  const sessao = props.sessao;
  const { camara } = useCamaraContext();

  return sessao ? (
    <div
      id="sessao-info"
      className="user-select-none d-flex align-items-center justify-content-around"
    >
      <MDBRow
        className={`d-flex align-items-center justify-content-around ${
          props.isParlamentar ? "isParlamentar" : ""
        }`}
      >
        <MDBCol
          md="6"
          className="rounded-9 text-center text-white d-flex flex-column justify-content-center align-items-center fw-bold mx-2 text-container"
        >
          <MDBRow>
            <h1 className="fw-bold display-2">SESSÃO</h1>
          </MDBRow>
          <MDBRow>
            <h1 className="fw-bold display-2">{"Nº: " + sessao.Numero}</h1>
          </MDBRow>
          <MDBRow>
            <h5 className="pt-5 fw-bold fs-1">{sessao.Tipo.toUpperCase()}</h5>
          </MDBRow>
          <MDBRow>
            <h5 className="pt-5 fs-2 fw-bold">
              {convertStringDateForBrDate(sessao.Data)}
            </h5>
          </MDBRow>
          {sessao.Comissao && (
            <MDBRow>
              <h5>{sessao.Comissao.Name}</h5>
            </MDBRow>
          )}
        </MDBCol>
        <MDBCol
          md="6"
          className="rounded-9 text-white bg-white text-container mx-2"
        >
          <MDBRow>
            <MDBCol
              md="12"
              className="desc-session d-flex justify-content-center align-items-center"
            >
              <MDBIcon icon="landmark" color="white" className="fs-3 m-2" />
              <span className="text-white fw-bold fs-3 ">SOBRE A SESSÃO</span>
            </MDBCol>
            <MDBCol md="12">
              <p className="about-session">{sessao.Descricao}</p>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </div>
  ) : (
    <div id="sessao-info" className="user-select-none">
      <MDBRow className="d-flex justify-content-center align-items-center vh-100">
        <img className="img-fluid opacity-15" src={camara && camara.LogoPath} />
      </MDBRow>
    </div>
  );
};

export default SessaoInfo;
