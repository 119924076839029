import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/auth";

import "./Login.css";
import {
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { getInfoUsersToLoginByCity } from "../../services/api";
import WakeLockApi from "../../components/WakeLockApi/WakeLockApi";
import Spinner from "../../components/Spinner/Spinner";

const Login = () => {
  const { login, loading } = useContext(AuthContext);

  const [loadingLogin, setLoadingLogin] = useState(true);
  const [usersInfos, setUsersInfos] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loginModal, setLoginModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function toggleShowLoginModal(user) {
    setEmail(user.Email);
    setSelectedUser(user ?? null);
    setLoginModal(!loginModal);
  }

  const handlerSubmit = (e) => {
    setLoadingLogin(loading);
    e.preventDefault();
    login(email, password);
    setLoadingLogin(loading);
  };

  async function fetchData() {
    setLoadingLogin(true);
    try {
      const usersInfos = await getInfoUsersToLoginByCity();
      setUsersInfos(usersInfos);
    } catch (error) {
      console.error(error);
    }
    setLoadingLogin(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div id="login">
      <WakeLockApi />
      <Spinner show={loading} />
      <MDBRow className="d-flex justify-content-center align-items-center h-100 px-4 pt-4">
        {usersInfos &&
          usersInfos.map((item, index) => (
            <MDBCol sm={4} key={index} className="mb-2">
              <MDBCard
                onClick={() => toggleShowLoginModal(item)}
                className="userItem bg-ligth"
              >
                <MDBCardBody>
                  <div className="d-flex align-items-center">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${item.Parlamentar.image}`}
                      alt=""
                      style={{ width: "45px", height: "45px" }}
                      className="rounded-circle"
                    />
                    <div className="ms-3 text-overflow">
                      <p className="fw-bold mb-1">
                        {item.Parlamentar.nickname ?? item.Parlamentar.name}
                      </p>
                      <p className="text-muted mb-0">
                        {item.Parlamentar.partido ?? "Partido"}
                      </p>
                      <p className="text-muted mb-0">
                        {item.Parlamentar.occupation}
                      </p>
                    </div>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          ))}
      </MDBRow>
      <MDBModal show={loginModal} setShow={setLoginModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                {loginModal
                  ? selectedUser.Parlamentar.nickname ??
                    selectedUser.Parlamentar.name
                  : null}
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowLoginModal}
              ></MDBBtn>
            </MDBModalHeader>
            <form>
              <MDBModalBody>
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  labelClass="text-dark"
                  label="Senha"
                  id="password"
                  type="password"
                  size="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn onClick={handlerSubmit} color="success">
                  <MDBIcon fas icon="sign-in-alt" /> Entrar
                </MDBBtn>
              </MDBModalFooter>
            </form>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </div>
  );
};

export default Login;
