import "./NavbarControlador.css";
import React, { useState, useContext } from "react";
import { AuthContextController } from "../../context/controllerAuth";
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
} from "mdb-react-ui-kit";
import { getCityByUrl, useLocalStorageState } from "../../common/util";

// components
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarLeft from "../../components/SidebarLeft/SidebarLeft";

const NavbarControlador = () => {
  const { authenticated } = useContext(AuthContextController);
  const [showSidebarLeft, setShowSidebarLeft] = useState(false);
  const [showSideBarRight, setShowSideBarRight] = useLocalStorageState(
    "showSideBarRight",
    false
  );

  const toggleSidebar = () => {
    setShowSideBarRight(!showSideBarRight);
    setShowSidebarLeft(false);
  };

  const closeSidebarRightAndElement = () => {
    setShowSideBarRight(false);
  };

  const toggleSidebarLeft = () => {
    setShowSidebarLeft(!showSidebarLeft);
    setShowSideBarRight(false);
  };

  const closeSideBarLeftAndElement = () => {
    setShowSidebarLeft(false);
  };

  let city = getCityByUrl();
  let paths = window.location.pathname.split("/");
  let path = ``;

  const homeControlador = `/${city}/controlador`;

  const handleBackButton = () => {
    if (window.location.pathname !== homeControlador)
      return window.history.back();
  };

  const disabledBtnBack = window.location.pathname !== homeControlador;

  return (
    <>
      {authenticated &&
      window.location.pathname.includes("controlador") &&
      !window.location.pathname.includes("controlador/login") &&
      !window.location.pathname.includes("controlador/relatorio") ? (
        <>
          <SidebarLeft show={showSidebarLeft} />
          <nav className="navbarControlador">
            <ul className="navbarUl">
              <li
                className={
                  showSidebarLeft
                    ? "openSidebarLeft navbarLi logo"
                    : "navbarLi logo"
                }
              >
                <a href="#" className="navbarA">
                  <MDBIcon
                    fas
                    icon={showSidebarLeft ? "angle-double-left" : "bars"}
                    onClick={toggleSidebarLeft}
                    style={{ cursor: "pointer" }}
                    size="2x"
                  />
                </a>
              </li>
              <li className="navbarLi navBarLinks">
                <a
                  href={`/${getCityByUrl()}/painel`}
                  target="_blank"
                  className="navbarA navBarLinksItem"
                >
                  <MDBIcon className="ms-1" fas icon="tv" size="2x" />
                </a>
                <a href="#" className="navbarA">
                  <MDBIcon
                    onClick={toggleSidebar}
                    className="ms-1"
                    fas
                    icon={
                      showSideBarRight
                        ? "microphone-alt-slash"
                        : "microphone-alt"
                    }
                    size="2x"
                  />
                  <Sidebar show={showSideBarRight} />
                </a>
              </li>
            </ul>
          </nav>
          <MDBNavbar expand="lg" className="BreadcrumbControlador">
            <MDBContainer fluid>
              <MDBBreadcrumb>
                <MDBBreadcrumbItem className="me-3">
                  <MDBIcon
                    fas
                    icon="arrow-left"
                    onClick={handleBackButton}
                    className={`HoverBackButton ${
                      disabledBtnBack ? "" : "disabled"
                    }`}
                  />
                </MDBBreadcrumbItem>
                {paths.slice(1).map((item, index) => {
                  path = path + "/" + item;

                  return (
                    <MDBBreadcrumbItem key={index}>
                      {item === city ? (
                        <a href={`/${city}/controlador`}>Início</a>
                      ) : (
                        <a href={path}>{item}</a>
                      )}
                    </MDBBreadcrumbItem>
                  );
                })}
              </MDBBreadcrumb>
            </MDBContainer>
          </MDBNavbar>
          <div
            className={`fechaMenu ${!showSideBarRight ? "isClose" : ""}`}
            onClick={closeSidebarRightAndElement}
          ></div>
          <div
            className={`fechaMenuLeft ${!showSidebarLeft ? "isCloseLeft" : ""}`}
            onClick={closeSideBarLeftAndElement}
          ></div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default NavbarControlador;
