import React, { useState, useContext } from "react";
import { AuthContextController } from "../../context/controllerAuth";

import "../../index.css";
import "./ControladorLogin.css";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
} from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner/Spinner";

const ControladorLogin = () => {
  const { login, loading } = useContext(AuthContextController);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handlerSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <MDBContainer fluid className="controladorLogin">
      <Spinner show={loading} />
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCol col="12">
          <MDBCard
            className="bg-light text-dark my-5 mx-auto"
            style={{ borderRadius: "1rem", maxWidth: "450px" }}
          >
            <form>
              <MDBCardBody className="p-5 d-flex flex-column align-items-center mx-auto w-100">
                <h2 className="fw-bold mb-2 text-uppercase">VOTAÇÃO WEB</h2>
                <h4 className="fw-bold mb-2 text-uppercase">CONTROLADOR</h4>
                <p className="text-white-50 mb-5">
                  Preencha os campos para acessar o sistema
                </p>
                <MDBInput
                  wrapperClass="mb-4 mx-5 w-100"
                  labelClass="text-dark"
                  label="Email"
                  id="email"
                  type="email"
                  size="lg"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <MDBInput
                  wrapperClass="mb-4 mx-5 w-100"
                  labelClass="text-dark"
                  label="Senha"
                  id="password"
                  type="password"
                  size="lg"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                {/* <div className="small mb-3 pb-lg-2">
                <a className="text-dark-50" href="#!">
                  Esqueceu a senha?
                </a>
              </div> */}

                <MDBBtn
                  outline
                  className="mx-2 px-5"
                  color="white"
                  size="lg"
                  onClick={handlerSubmit}
                >
                  Entrar
                </MDBBtn>
              </MDBCardBody>
            </form>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ControladorLogin;
