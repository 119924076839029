import "./Camara.css";
import React, { useState, useContext, useEffect } from "react";
import {
  getParlamentarsByCamaraId,
  getSessoesByCamaraId,
  getAllMateriasFromParlamentarId,
  getStatisticsByParlamentarId,
  getBancadasByCamaraId,
  getComissaoByCamaraId,
  getComissoesFromParlamentarId,
} from "../../services/api";
import {
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBBadge,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBAccordion,
  MDBAccordionItem,
  MDBProgress,
  MDBProgressBar,
} from "mdb-react-ui-kit";
import { useCamaraContext } from "../../context/camaraContext";
import Spinner from "../../components/Spinner/Spinner";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AccordionSessoes from "../../components/AccordionSessoes/AccordionSessoes";
import AccordionBancadas from "../../components/AccordionBancadas/AccordionBancadas";
import AccordionComissoes from "../../components/AccordionComissoes/AccordionComissoes";
import TinyBarChart from "../../components/Charts/TinyBarChart/TinyBarChart";
import PieChart from "../../components/Charts/PieChart/PieChart";
import { convertStringDateForBrDate } from "../../common/util";
import Pagination from "../../components/Pagination/Pagination";

const Camara = () => {
  const { camara } = useCamaraContext();
  const [loading, setLoading] = useState(true);
  const [parlamentars, setParlamentars] = useState([]);
  const [sessoes, setSessoes] = useState([]);
  const [isSM, setIsSM] = useState(false);
  const [parlamentarModal, setParlamentarModal] = useState(false);
  const [parlamentarInfo, setParlamentarInfo] = useState(null);
  const [materiasParlamentar, setMateriasParlamentar] = useState([]);
  const [statisticsParlamentar, setStatisticsParlamentar] = useState(null);
  const [bancadas, setBancadas] = useState([]);
  const [totalPaginas, setTotalPaginas] = useState();
  const [filterPagina, setfilterPagina] = useState({
    pagina: 0,
  });
  const [comissoes, setComissoes] = useState([]);
  const [parlamentarComissoes, setParlamentarComissoes] = useState([]);

  useEffect(() => {
    fetchData();
  }, [camara]);

  useEffect(() => {
    fetchData();
  }, [filterPagina.pagina]);

  async function fetchData() {
    if (camara) {
      const parlamentarsReponse = await getParlamentarsByCamaraId(camara.id);
      const sessoesResponse = await getSessoesByCamaraId(
        filterPagina,
        camara.id
      );
      const bancadasResponse = await getBancadasByCamaraId(camara.id);
      const comissoesResponse = await getComissaoByCamaraId(camara.id);

      setTotalPaginas(sessoesResponse.totalPaginas);

      setParlamentars(parlamentarsReponse);
      setSessoes(sessoesResponse.sessoes);
      setBancadas(bancadasResponse);
      setComissoes(comissoesResponse);
      setLoading(false);
    }
  }

  function setSizeScreenStates() {
    const mediaQuery = window.matchMedia("(max-width: 576px)");

    function handleMediaChange(event) {
      setIsSM(event.matches);
    }

    mediaQuery.addListener(handleMediaChange);

    handleMediaChange(mediaQuery);
    return () => {
      mediaQuery.removeListener(handleMediaChange);
    };
  }

  async function handlePaginaChange(value) {
    setfilterPagina({ pagina: value });
  }

  useEffect(() => {
    setSizeScreenStates();
  }, []);

  const toggleParlamentarModal = () => setParlamentarModal(!parlamentarModal);

  async function loadParlamentarInfo(parlamentar) {
    setParlamentarInfo(parlamentar);

    const response = await getAllMateriasFromParlamentarId(parlamentar.id);
    const responseMapped = calculePercentageVoteFromResponse(response);
    const resParlamentarComissoes = await getComissoesFromParlamentarId(
      parlamentar.id
    );

    setParlamentarComissoes(resParlamentarComissoes);
    responseMapped.forEach((e, index) => {
      if (e.Votos) {
        const parlamentarVoto = e.Votos.find(
          (voto) => voto.ParlamentarId === parlamentar.id
        );

        responseMapped[index].ParlamentarVoto = parlamentarVoto;
      }
    });
    setMateriasParlamentar(responseMapped);

    toggleParlamentarModal();

    const statisticsParlamentarResponse = await getStatisticsByParlamentarId(
      parlamentar.id
    );

    setStatisticsParlamentar(statisticsParlamentarResponse);
  }

  function calculePercentageVoteFromResponse(response) {
    response &&
      response.forEach((e, index) => {
        if (e.Votos && e.Votos.length > 0) {
          const totalVotos = e.Votos.length;
          const totalAfavor = e.Votos.filter(
            (v) => v.Status === "A favor"
          ).length;
          const totalContra = e.Votos.filter(
            (v) => v.Status === "Contra"
          ).length;
          const totalSeAbsteve = e.Votos.filter(
            (v) => v.Status === "Se absteve"
          ).length;

          let porcentagemAfavor = (totalAfavor / totalVotos) * 100;
          let porcentagemContra = (totalContra / totalVotos) * 100;
          let porcentagemSeAbsteve = (totalSeAbsteve / totalVotos) * 100;

          response[index].TotalAfavor = totalAfavor;
          response[index].TotalContra = totalContra;
          response[index].TotalSeAbsteve = totalSeAbsteve;

          response[index].PorcentagemAfavor =
            porcentagemAfavor % 1 === 0
              ? porcentagemAfavor.toFixed(0)
              : porcentagemAfavor.toFixed(2);
          response[index].PorcentagemContra =
            porcentagemContra % 1 === 0
              ? porcentagemContra.toFixed(0)
              : porcentagemContra.toFixed(2);
          response[index].PorcentagemSeAbsteve =
            porcentagemSeAbsteve % 1 === 0
              ? porcentagemSeAbsteve.toFixed(0)
              : porcentagemSeAbsteve.toFixed(2);
        }
      });

    return response;
  }

  return (
    <div>
      <div className="content m-3 mx-3 p-2 shadow rounded-7">
        <Spinner show={loading} />
        {camara && (
          <>
            <Header camara={camara} />
            <MDBTypography
              tag="div"
              className="display-6 pb-3 mb-3 border-bottom text-center"
            >
              PARLAMENTARES
            </MDBTypography>
            <MDBRow className="d-flex justify-content-center">
              <MDBCol
                md={12}
                className="mb-2 mx-2 d-flex justify-content-center"
              >
                <MDBBadge
                  className="mx-2 d-block"
                  style={{ maxWidth: "100%", overflowWrap: "break-word" }}
                  color="info"
                  light
                >
                  <MDBIcon fas icon="info-circle" /> Clique nas imagens para
                  exibir informações do parlamentar
                </MDBBadge>
              </MDBCol>
            </MDBRow>
            <MDBRow
              className={`p-1 d-flex ${
                !isSM ? "justify-content-center" : "justify-content-start"
              }`}
            >
              {parlamentars.map((item, index) => (
                <MDBCol
                  key={index}
                  size={3}
                  sm={2}
                  lg={1}
                  className="p-2 d-flex justify-content-center"
                >
                  <img
                    onClick={() => loadParlamentarInfo(item)}
                    src={
                      `${process.env.REACT_APP_API_URL}/${item.image}` ??
                      `/user.png`
                    }
                    alt=""
                    style={{ width: "3rem", height: "3rem" }}
                    className="rounded-6 parlamentar-image"
                    title={item.nickname ?? item.name}
                  />
                </MDBCol>
              ))}
            </MDBRow>
            <MDBModal
              tabIndex="-1"
              show={parlamentarModal}
              setShow={setParlamentarModal}
            >
              <MDBModalDialog size={isSM ? "fullscreen" : "lg"} className="p-3">
                <MDBModalContent className="rounded-8">
                  <MDBModalHeader>
                    <MDBModalTitle>
                      {parlamentarInfo &&
                        (parlamentarInfo.nickname ?? parlamentarInfo.name)}

                      {parlamentarInfo && " - " + parlamentarInfo.partido}
                    </MDBModalTitle>
                    <MDBBtn
                      className="btn-close"
                      color="none"
                      onClick={toggleParlamentarModal}
                    ></MDBBtn>
                  </MDBModalHeader>
                  <MDBModalBody>
                    {parlamentarInfo && (
                      <>
                        <MDBRow>
                          <MDBCol
                            size={12}
                            className="d-flex justify-content-center"
                          >
                            <img
                              src={
                                `${process.env.REACT_APP_API_URL}/${parlamentarInfo.image}` ??
                                `/user.png`
                              }
                              alt=""
                              style={{ width: "18", height: "18rem" }}
                              className="rounded-6 shadow"
                              title={
                                parlamentarInfo.nickname ?? parlamentarInfo.name
                              }
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol size={12}>
                            <MDBTypography
                              tag="div"
                              className="mt-4 display-6 pb-3 mb-3 border-bottom text-center"
                            >
                              MATÉRIAS PROPOSTAS
                            </MDBTypography>
                            <span className="d-flex justify-content-center">
                              {materiasParlamentar.length === 0 && (
                                <>
                                  <span>
                                    <MDBIcon
                                      fas
                                      icon="exclamation-circle"
                                      color="warning"
                                    />{" "}
                                    Nenhuma matéria foi proposta
                                  </span>
                                </>
                              )}
                            </span>
                            <MDBAccordion>
                              {materiasParlamentar.map((item, index) => (
                                <MDBAccordionItem
                                  key={index}
                                  collapseId={index + 1}
                                  headerTitle={
                                    <>
                                      <MDBIcon
                                        fas
                                        icon="caret-down"
                                        color="success"
                                      />{" "}
                                      &nbsp; {item.Numero} - &nbsp;
                                      {convertStringDateForBrDate(item.Data)}
                                    </>
                                  }
                                >
                                  <div>
                                    {item.Resumo}
                                    {item.Votos && item.Votos.length > 0 && (
                                      <>
                                        <MDBTypography
                                          variant="h5"
                                          className="mt-2"
                                        >
                                          Resultado da votação:
                                        </MDBTypography>
                                        <MDBProgress height="20">
                                          <MDBProgressBar
                                            width={item.PorcentagemAfavor}
                                            valuemin={0}
                                            valuemax={100}
                                            bgColor="success"
                                          >
                                            {item.PorcentagemAfavor}%
                                          </MDBProgressBar>
                                          <MDBProgressBar
                                            width={item.PorcentagemContra}
                                            valuemin={0}
                                            valuemax={100}
                                            bgColor="warning"
                                          >
                                            {item.PorcentagemSeAbsteve}%
                                          </MDBProgressBar>
                                          <MDBProgressBar
                                            width={item.PorcentagemSeAbsteve}
                                            valuemin={0}
                                            valuemax={100}
                                            bgColor="danger"
                                          >
                                            {item.PorcentagemContra}%
                                          </MDBProgressBar>
                                        </MDBProgress>
                                        <div className="mt-3 d-flex justify-content-center">
                                          <MDBBadge pill color="success" light>
                                            A favor ({item.TotalAfavor})
                                          </MDBBadge>{" "}
                                          <MDBBadge pill color="warning" light>
                                            Se Absteve ({item.TotalSeAbsteve})
                                          </MDBBadge>{" "}
                                          <MDBBadge pill color="danger" light>
                                            Contra ({item.TotalContra})
                                          </MDBBadge>
                                        </div>
                                        {item.ParlamentarVoto && (
                                          <div className="mt-3 d-flex justify-content-start">
                                            {parlamentarInfo.nickname ??
                                              parlamentarInfo.name}
                                            {" votou:  "}
                                            {item.ParlamentarVoto.Status ===
                                              "A favor" && (
                                              <MDBBadge
                                                pill
                                                color="success"
                                                light
                                                className="mt-1 ms-2"
                                              >
                                                A favor
                                              </MDBBadge>
                                            )}
                                            {item.ParlamentarVoto.Status ===
                                              "Se absteve" && (
                                              <MDBBadge
                                                pill
                                                color="warning"
                                                light
                                                className="mt-1 ms-2"
                                              >
                                                Se Absteve
                                              </MDBBadge>
                                            )}
                                            {item.ParlamentarVoto.Status ===
                                              "Contra" && (
                                              <MDBBadge
                                                pill
                                                color="danger"
                                                light
                                                className="mt-1 ms-2"
                                              >
                                                Contra
                                              </MDBBadge>
                                            )}
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </MDBAccordionItem>
                              ))}
                            </MDBAccordion>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol size={12}>
                            <MDBTypography
                              tag="div"
                              className="mt-4 display-6 pb-3 mb-3 border-bottom text-center"
                            >
                              COMISSÕES DO PARLAMENTAR
                            </MDBTypography>
                            <span className="d-flex justify-content-center">
                              <>
                                <span className="d-flex justify-content-center">
                                  {parlamentarComissoes.length === 0 && (
                                    <>
                                      <span>
                                        <MDBIcon
                                          fas
                                          icon="exclamation-circle"
                                          color="warning"
                                        />{" "}
                                        Este parlamentar não participa de
                                        nenhuma comissão
                                      </span>
                                    </>
                                  )}
                                </span>
                                <span>
                                  {parlamentarComissoes.length > 0 && (
                                    <AccordionComissoes
                                      itens={parlamentarComissoes}
                                    />
                                  )}
                                </span>
                              </>
                            </span>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-3 me-3">
                          {statisticsParlamentar && (
                            <>
                              <MDBTypography
                                tag="div"
                                className="mt-4 fs-5 pb-3 mb-3 border-bottom text-center"
                              >
                                QUANTIDADE DE VOTOS EM MATÉRIAS
                              </MDBTypography>
                              <MDBCol
                                size={12}
                                lg={12}
                                className="d-flex justify-content-center"
                              >
                                <TinyBarChart
                                  countVotes={
                                    statisticsParlamentar.CountVotesParlamentar
                                  }
                                />
                              </MDBCol>

                              <MDBTypography
                                tag="div"
                                className="mt-4 fs-5 pb-3 mb-3 border-bottom text-center"
                              >
                                PRESENÇAS POR SESSÃO
                              </MDBTypography>
                              <MDBCol
                                size={12}
                                lg={12}
                                className="d-flex justify-content-center"
                              >
                                <PieChart
                                  data={[
                                    {
                                      name: "Presente",
                                      value:
                                        statisticsParlamentar.CountPresence,
                                      color: "#28a744",
                                    },
                                    {
                                      name: "Ausente",
                                      value:
                                        statisticsParlamentar.CountSessao -
                                        statisticsParlamentar.CountPresence,
                                      color: "#dc3546",
                                    },
                                  ]}
                                />
                              </MDBCol>
                              <MDBTypography
                                tag="div"
                                className="mt-4 fs-5 pb-3 mb-3 border-bottom text-center"
                              >
                                QUANTIDADE DE MATÉRIAS PROPOSTAS
                              </MDBTypography>
                              <MDBCol
                                size={12}
                                lg={12}
                                className="d-flex justify-content-center"
                              >
                                <PieChart
                                  data={[
                                    {
                                      name: "Propostas",
                                      value:
                                        statisticsParlamentar.CountMateriasByParlamentar,
                                      color: "#28a744",
                                    },
                                    {
                                      name: "Total",
                                      value:
                                        statisticsParlamentar.CountMaterias -
                                        statisticsParlamentar.CountMateriasByParlamentar,
                                      color: "#e4a11c",
                                    },
                                  ]}
                                />
                              </MDBCol>
                            </>
                          )}
                        </MDBRow>
                      </>
                    )}
                  </MDBModalBody>
                  <MDBModalFooter>
                    <MDBBtn onClick={toggleParlamentarModal} color="info">
                      Fechar
                    </MDBBtn>
                  </MDBModalFooter>
                </MDBModalContent>
              </MDBModalDialog>
            </MDBModal>
            <MDBTypography
              tag="div"
              className="mt-4 display-6 pb-3 mb-3 border-bottom text-center"
            >
              BANCADAS
            </MDBTypography>
            <span className="d-flex justify-content-center">
              {bancadas && bancadas.length === 0 && (
                <>
                  <span>
                    <MDBIcon fas icon="exclamation-circle" color="warning" />{" "}
                    Nenhuma bancada foi encontrada
                  </span>
                </>
              )}
            </span>
            <MDBRow className="p-1 d-flex">
              <AccordionBancadas itens={bancadas} />
            </MDBRow>
            <MDBTypography
              tag="div"
              className="mt-4 display-6 pb-3 mb-3 border-bottom text-center"
            >
              SESSÕES
            </MDBTypography>
            <MDBRow className="p-1 d-flex">
              <AccordionSessoes itens={sessoes} />
            </MDBRow>
            <MDBRow style={{ marginBottom: "8vh" }}>
              <Pagination
                totalPaginas={totalPaginas}
                pagina={filterPagina.pagina}
                onPaginaChange={handlePaginaChange}
              />
            </MDBRow>

            <div>
              <MDBTypography
                tag="div"
                className="mt-4 display-6 pb-3 mb-3 border-bottom text-center"
              >
                COMISSÕES
              </MDBTypography>
              <span className="d-flex justify-content-center">
                {comissoes.length === 0 && (
                  <>
                    <span>
                      <MDBIcon fas icon="exclamation-circle" color="warning" />{" "}
                      Não há comissões cadastradas
                    </span>
                  </>
                )}
              </span>

              {comissoes.length != 0 && (
                <MDBRow className="p-1 d-flex">
                  <AccordionComissoes itens={comissoes} />
                </MDBRow>
              )}
            </div>
          </>
        )}
      </div>
      {camara && <Footer camara={camara} />}
    </div>
  );
};

export default Camara;
