import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Pdf from "@mikecousins/react-pdf";
import { MDBBtn, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function PdfView(props) {
  const { url } = props;
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [page, setPage] = useState(1);
  const [scale, setScale] = useState(1.3);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <Pdf file={url} page={page} scale={scale} className="">
        {({ pdfDocument, pdfPage, canvas }) => (
          <>
            {!pdfDocument && <span>Carregando pdf...</span>}
            {canvas}
            {Boolean(pdfDocument && pdfDocument.numPages) && (
              <MDBRow className="d-flex justify-content-center">
                <MDBCol size={4}>
                  <MDBBtn
                    disabled={page === 1}
                    color="success"
                    onClick={() => setPage(page - 1)}
                  >
                    Anterior
                  </MDBBtn>
                </MDBCol>
                <MDBCol size={4} className="d-flex justify-content-center fs-3">
                  {page}
                </MDBCol>
                <MDBCol size={4} className="d-flex justify-content-end">
                  <MDBBtn
                    disabled={page === pdfDocument.numPages}
                    color="success"
                    onClick={() => setPage(page + 1)}
                  >
                    Próxima
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            )}
          </>
        )}
      </Pdf>
    </>
  );
}
