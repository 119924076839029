import "./Card.css";
import React, { useState, useEffect, useRef } from "react";
import { MDBIcon, MDBBtn, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <Link to={props.url ?? ""} className="cardNoBoostrapLink">
      <div
        className="cardNoBoostrap"
        style={{ borderLeft: "3px solid" + props.color }}
      >
        <h2 className="titleNoBoostrap">{props.title}</h2>
        <p className="descriptionNoBoostrap">{props.description}</p>
        <ul className="notesNoBoostrap">
          {props.notes.map(
            (item) =>
              item && (
                <li
                  key={item.value}
                  className="noteNoBoostrap"
                  style={{ backgroundColor: item.color ?? props.color }}
                >
                  <MDBIcon fas icon={item.icon} /> {item.value}
                </li>
              )
          )}
        </ul>

        {props.buttons &&
          props.buttons.map(
            (item) =>
              item && (
                <MDBBtn
                  onClick={() =>
                    item.functionToExecute(item.parameterToFunction)
                  }
                  color={item.color}
                  className="mb-2 me-1"
                >
                  <MDBIcon fas icon={item.icon} /> {item.value}
                </MDBBtn>
              )
          )}
      </div>
    </Link>
  );
};

export default Card;
