import React from "react";
import {
  MDBAccordion,
  MDBAccordionItem,
  MDBIcon,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import CardParlamentar from "../CardParlamentar/CardParlamentar";

const Accordion = (props) => {
  return (
    <>
      <MDBAccordion>
        {props.itens.map((item, index) => (
          <MDBAccordionItem
            key={index}
            collapseId={index + 1}
            headerTitle={
              <>
                <MDBIcon fas icon="caret-down" color="success" /> &nbsp;{" "}
                {item.Name} ({item.Type})
              </>
            }
          >
            <MDBRow className="text-center fs-4 mb-3">
              <div>{item.Description}</div>
            </MDBRow>
            <MDBRow className="text-start fs-5 mb-1">
              <div>INTEGRANTES:</div>
            </MDBRow>
            <MDBRow>
              {item.ParlamentarComissaos.map((parlamentarComissao, index) => (
                <MDBCol size={4} className="mb-1" key={index}>
                  <CardParlamentar
                    parlamentar={parlamentarComissao.Parlamentar} subtitle={parlamentarComissao.Occupation ?? ""}
                  />
                </MDBCol>
              ))}
            </MDBRow>
          </MDBAccordionItem>
        ))}
      </MDBAccordion>
    </>
  );
};

export default Accordion;
