import React, { useContext } from "react";
import { getCityByUrl } from "./common/util";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

//#region components
import NavbarControlador from "./components/NavbarControlador/NavbarControlador";
import FooterControlador from "./components/FooterControlador/FooterControlador";
//#endregion

//#region pages
import Parlamentar from "./pages/Parlamentar/Parlamentar";
import Login from "./pages/Login/Login";
import ControladorLogin from "./pages/ControladorLogin/ControladorLogin";
import Painel from "./pages/Painel/Painel";
import Sessoes from "./pages/Sessoes/Sessoes";
import Sessao from "./pages/Sessao/Sessao";
import Relatorio from "./pages/Relatorio/Relatorio";
import Account from "./pages/Account/Account";
import Camara from "./pages/Camara/Camara";
import Configuracoes from "./pages/Configuracoes/Configuracoes";
import RelatorioTempoReal from "./pages/RelatorioTempoReal/RelatorioTempoReal";
import ParlamentarControlador from "./pages/ParlamentarControlador/ParlamentarControlador"
import NotFound from "./pages/NotFound/NotFound";
//#endregion

//#region context
import { AuthProvider, AuthContext } from "./context/auth";
import {
  AuthProviderController,
  AuthContextController,
} from "./context/controllerAuth";
import { CamaraProvider } from "./context/camaraContext";
//#endregion

function AppRoutes() {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);

    if (loading) return <div className="loading">Carregando...</div>;

    if (!authenticated) return <Navigate to={`/${getCityByUrl()}/login`} />;

    return children;
  };

  const PrivateController = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContextController);

    if (loading) return <div className="loading">Carregando...</div>;

    if (!authenticated)
      return <Navigate to={`/${getCityByUrl()}/controlador/login`} />;

    return children;
  };

  return (
    <Router>
      <CamaraProvider>
        <AuthProvider>
          <Routes>
            <Route
              path="/:id/vereador"
              element={
                <Private>
                  <Parlamentar />
                </Private>
              }
            />
            <Route path=":id/login" element={<Login />} />
            <Route path=":id/painel" element={<Painel />} />
            <Route path=":id/relatorioTempoReal" element={<RelatorioTempoReal />} />
            <Route path=":id" element={<Camara />} />
          </Routes>
        </AuthProvider>
        <AuthProviderController>
          <NavbarControlador />
          <Routes>
            <Route
              path=":id/controlador/login"
              element={<ControladorLogin />}
            />
            <Route
              path=":id/controlador"
              element={
                <PrivateController>
                  <Sessoes />
                </PrivateController>
              }
            />
            <Route
              path=":id/controlador/conta"
              element={
                <PrivateController>
                  <Account />
                </PrivateController>
              }
            />
            <Route
              path=":id/controlador/sessoes/:id"
              element={
                <PrivateController>
                  <Sessao />
                </PrivateController>
              }
            />
            <Route
              path=":id/controlador/sessoes"
              element={
                <PrivateController>
                  <Sessoes />
                </PrivateController>
              }
            />
            <Route
              path=":id/controlador/relatorio/:id"
              element={
                <PrivateController>
                  <Relatorio />
                </PrivateController>
              }
            />
            <Route
              path=":id/controlador/configuracoes"
              element={
                <PrivateController>
                  <Configuracoes />
                </PrivateController>
              }
            />
            <Route
              path=":id/controlador/camara"
              element={
                <PrivateController>
                  <Camara/>
                </PrivateController>
              }
            />
            <Route
              path=":id/controlador/ParlamentarControlador"
              element={
                <PrivateController>
                  <ParlamentarControlador/>
                </PrivateController>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <FooterControlador />
        </AuthProviderController>
      </CamaraProvider>
    </Router>
  );
}

export default AppRoutes;
