import "./Painel.css";
import {
  getSessaoByDataAndCamaraId,
  getWebSocketHistoricByCamaraId,
} from "../../services/api";
import React, { useState, useEffect } from "react";
import { MDBCol, MDBRow, MDBIcon } from "mdb-react-ui-kit";
import Spinner from "../../components/Spinner/Spinner";
import { getCurrentStringDate, reloadScreen } from "../../common/util";
import { socketEmit, socketOn } from "../../services/websockets";
import { useCamaraContext } from "../../context/camaraContext";
import SessaoInfo from "../../components/Painel/SessaoInfo/SessaoInfo";
import ReadMateria from "../../components/Painel/ReadMateria/ReadMateria";
import VoteMateria from "../../components/Painel/VoteMateria/VoteMateria";
import TimerPainel from "../../components/Painel/TimerPainel/TimerPainel";

const Painel = () => {
  const [loading, setLoading] = useState(true);
  const [sessao, setSessao] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(null);
  const [soundOn, setSoundOn] = useState(false);
  const [time, setTime] = useState(new Date().toLocaleTimeString());
  const [page, setPage] = useState(null);
  const [returnDataWebSocket, setReturnDataWebSocket] = useState(null);
  const [timerQueue, setTimerQueue] = useState([]);

  const { camara } = useCamaraContext();

  async function fetchData() {
    setLoading(true);

    const sessaoResponse = await getSessaoByDataAndCamaraId({
      data: getCurrentStringDate(),
      camaraId: camara.id,
    });

    if (sessaoResponse) setSessao(sessaoResponse.data.sessao);

    const webSocketsHistoric = await getWebSocketHistoricByCamaraId(camara.id);

    webSocketsHistoric.forEach(async (webSocketHistoric) => {
      switch (webSocketHistoric.Event) {
        case "StartTimer":
          setReturnDataWebSocket(webSocketHistoric.Data);
          setPage("TimerPainel");
          break;
        case "TimerQueue":
          if (webSocketHistoric.Data && webSocketHistoric.Data.length > 0) {
            setTimerQueue(webSocketHistoric.Data);
            setPage("TimerPainel");
          }
          break;
        case "ReadyMatter":
          setReturnDataWebSocket(webSocketHistoric.Data);
          setPage("ReadMateria");
          break;
        case "StartVoting":
          setReturnDataWebSocket(webSocketHistoric.Data);
          setPage("VoteMateria");
          break;
        case "EndVoting":
          setReturnDataWebSocket(webSocketHistoric.Data);
          setPage(null);
          break;
      }
    });

    setLoading(false);
  }

  useEffect(() => {
    if (camara) {
      socketEmit("JoinCamara", { CamaraId: camara.id });
      fetchData();

      socketOn("StartTimer", (data) => {
        setReturnDataWebSocket(data);
        setPage("TimerPainel");
      });

      socketOn("CloseTimer", (data) => {
        setReturnDataWebSocket(null);

        if (timerQueue.length === 0) setPage(null);
      });

      socketOn("TimerQueue", (data) => {
        if (data.length === 0) {
          setPage(null);
        } else {
          setPage("TimerPainel");
          setTimerQueue(data);
        }
      });

      socketOn("ReadyMatter", (data) => {
        setReturnDataWebSocket(data);
        setPage("ReadMateria");
      });

      socketOn("EndReadyMatter", (data) => {
        setReturnDataWebSocket(null);
        setPage(null);
      });

      socketOn("StartVoting", async (data) => {
        setReturnDataWebSocket(data);
        setPage("VoteMateria");
      });

      socketOn("EndVoting", (data) => {
        setReturnDataWebSocket(null);
        setPage(null);
      });
    }
  }, [camara]);

  useEffect(() => {
    if (camara && sessao === null) {
      const interval = setInterval(() => {
        fetchData();
      }, 15000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [sessao]);

  useEffect(() => {
    const fullScreenCheck = async () => {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      )
        setIsFullScreen(!!isFullScreen);

      return isFullScreen;
    };

    fullScreenCheck();
    document.addEventListener("DOMContentLoaded", fullScreenCheck);

    document.addEventListener("fullscreenchange", fullScreenCheck);
    document.addEventListener("mozfullscreenchange", fullScreenCheck);
    document.addEventListener("webkitfullscreenchange", fullScreenCheck);
    document.addEventListener("msfullscreenchange", fullScreenCheck);

    return () => {
      document.removeEventListener("DOMContentLoaded", fullScreenCheck);
      document.removeEventListener("fullscreenchange", fullScreenCheck);
      document.removeEventListener("mozfullscreenchange", fullScreenCheck);
      document.removeEventListener("webkitfullscreenchange", fullScreenCheck);
      document.removeEventListener("msfullscreenchange", fullScreenCheck);
    };
  }, [isFullScreen]);

  const toggleFullScreen = () => {
    const elem = document.documentElement;
    if (!document.fullscreenElement) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  const toggleSound = async () => {
    const audioContext = new AudioContext();
    const oscillator = audioContext.createOscillator();
    oscillator.connect(audioContext.destination);
    oscillator.start();
    oscillator.stop(audioContext.currentTime);

    setSoundOn(!soundOn);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div id="painel" className="px-5 m-0 py-0">
      <Spinner show={loading} />
      {camara && (
        <>
          <MDBRow className="navBar fixed-top p-2 d-flex align-items-center user-select-none">
            <MDBCol
              md="12"
              className="d-flex justify-content-between d-flex align-items-center"
            >
              <span className="fw-bold text-white opacity-50 fs-2">{time}</span>
              <div className="justify-content-center">
                <MDBIcon icon="vote-yea" color="white" className="fs-4 m-2" />
                <a className="text-white fs-3 fw-bold">VOTAÇÃO WEB</a>
              </div>
              <img
                className="image-camara"
                src={camara && camara.LogoPath}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol className="fixed-bottom p-2">
              <span>
                <MDBIcon
                  onClick={toggleFullScreen}
                  icon={
                    isFullScreen ? "compress-arrows-alt" : "expand-arrows-alt"
                  }
                  size="2x"
                  className="fullscreenIcon me-3"
                  tabIndex="1"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      toggleFullScreen();
                    }
                  }}
                />
              </span>
              <span>
                <MDBIcon
                  onClick={reloadScreen}
                  icon="redo-alt"
                  size="2x"
                  className="reloadscreenIcon me-3"
                  tabIndex="2"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      reloadScreen();
                    }
                  }}
                />
              </span>
              <span>
                <MDBIcon
                  onClick={toggleSound}
                  icon={soundOn ? "volume-up" : "volume-mute"}
                  size="2x"
                  className={`Audioicon ${
                    !soundOn ? "me-3 sound-off" : "me-3"
                  }`}
                  tabIndex="3"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      toggleSound();
                    }
                  }}
                />
              </span>
            </MDBCol>
          </MDBRow>
          <MDBRow className="d-flex justify-content-center">
            {(() => {
              switch (page) {
                case "ReadMateria":
                  return (
                    <ReadMateria
                      materia={returnDataWebSocket}
                      sessao={sessao}
                    />
                  );
                case "VoteMateria":
                  return <VoteMateria materia={returnDataWebSocket.materia} />;
                case "TimerPainel":
                  return (
                    <TimerPainel timerQueue={timerQueue} soundOn={soundOn} />
                  );
                default:
                  return <SessaoInfo sessao={sessao} />;
              }
            })()}
          </MDBRow>
        </>
      )}
    </div>
  );
};

export default Painel;
