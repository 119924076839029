import { MDBCard, MDBCardBody, MDBTooltip } from "mdb-react-ui-kit";

const CardParlamentar = (props) => {
  const parlamentar = props.parlamentar;
  return (
    <>
      {parlamentar && (
        <MDBCard
          background={props.background ?? "#dddddd"}
          style={{ backgroundColor: props.background }}
        >
          <MDBCardBody>
            <div className="d-flex align-items-center">
              <img
                src={
                  parlamentar
                    ? `${process.env.REACT_APP_API_URL}/${parlamentar.image}`
                    : "/user.png"
                }
                alt=""
                style={{ width: "45px", height: "45px" }}
                className="rounded-circle"
              />
              <div className={`ms-3 text-overflow`}>
                <p className="fw-bold mb-1 text-overflow">
                  <MDBTooltip
                    title={parlamentar.nickname ?? parlamentar.name}
                    tag="a"
                  >
                    {parlamentar.nickname ?? parlamentar.name}
                  </MDBTooltip>
                </p>
                <p className="text-muted mb-0 text-overflow">
                  {props.subtitle ?? parlamentar.occupation}
                </p>
              </div>
            </div>
          </MDBCardBody>
        </MDBCard>
      )}
    </>
  );
};

export default CardParlamentar;
