import "../../index.css";
import "./Sessao.css";
import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBBadge,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBCheckbox,
  MDBRadio,
} from "mdb-react-ui-kit";
import {
  getInfoSessaoControlador,
  getParlamentars,
  createMateriaControlador,
  createRoteiroControlador,
  editSessaoControlador,
  removerSessaoControlador,
  removerMateriaControlador,
  removerRoteiroControlador,
  editMateriaControlador,
  editRoteiroControlador,
  getWebSocketHistoricByCamaraId,
  getComissaoByCamaraId,
  getMateriaSituationById,
  getParameterByCamaraIdAndName,
} from "../../services/api";
import { notify } from "../../common/toastConfig";
import Card from "../../components/Card/Card";
import { MultiSelect } from "react-multi-select-component";
import {
  getCityByUrl,
  getCamaraIdFromLocalStorageControlador,
  useLocalStorageState,
  getCurrentStringDate,
  checkParameterBooleanIsEnabled,
  getYear,
  formatNumber,
  loadParlamentarName,
} from "../../common/util";
import Spinner from "../../components/Spinner/Spinner";
import { socketEmit } from "../../services/websockets";
import { useCamaraContext } from "../../context/camaraContext";
import VotingControlModal from "../../components/VotingControlModal/VotingControlModal";
import { ParameterName } from "../../Enums/ParameterEnums";

const Sessao = () => {
  const { camara } = useCamaraContext();
  const [loading, setLoading] = useState(true);
  const CamaraId = getCamaraIdFromLocalStorageControlador();
  const { id } = useParams();
  const [sessao, setSessao] = useState({});
  const [sessaoChange, setSessaoChange] = useState({});
  const [sessaoIsChanged, setSessaoIsChanged] = useState(false);
  const [removeSessaoModal, setRemoveSessaoModal] = useState(false);
  const [removeMateriaModal, setRemoveMateriaModal] = useState(false);
  const [materiaToRemove, setMateriaToRemove] = useState(null);
  const [editMateriaModal, setEditMateriaModal] = useState(false);
  const [materiaToEdit, setMateriaToEdit] = useState(null);
  const [materias, setMaterias] = useState([]);
  const [materiaReading, setMateriaReading] = useLocalStorageState(
    "materiaReading",
    null
  );
  const [parlamentares, setParlamentares] = useState([]);
  const [selectedParlamentares, setSelectedParlamentares] = useState([]);
  const [
    selectedParlamentaresToEditMateria,
    setSelectedParlamentaresToEditMateria,
  ] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [materiaToVote, setMateriaToVote] = useState(null);
  const [formValues, setformValues] = useState({
    sessaoId: id,
    parlamentaresId: [],
    numero: "",
    tipo: "",
    resumo: "",
    data: "",
    ordemDia: false,
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const inputFileRef = useRef(null);
  const [selectedFileUpdate, setSelectedFileUpdate] = useState(null);
  const [sessionScheduledToday, setSessionScheduledToday] = useState(false);
  const inputUpdateFileRef = useRef(null);
  const [comissoes, setcomissoes] = useState([]);
  const [blockClosedSessionEdit, setBlockClosedSessionEdit] = useState(false);
  const [currentTab, setCurrentTab] = useState("materias");
  const [showFormRoteiro, setShowFormRoteiro] = useState(false);
  const [formValuesRoteiro, setformValuesRoteiro] = useState({
    sessaoId: id,
    parlamentarId: null,
    numero: "",
    resumo: "",
  });
  const [roteiros, setRoteiros] = useState([]);
  const [editRoteiroModal, setEditRoteiroModal] = useState(false);
  const [roteiroToEdit, setRoteiroToEdit] = useState(null);
  const [roteiroToRemove, setRoteiroToRemove] = useState(null);
  const [removeRoteiroModal, setRemoveRoteiroModal] = useState(false);
  const [addMatterRequestInProgress, setAddMatterRequestInProgress] =
    useState(false);
  const [isPautaSessao, setIsPautaSessao] = useState(false);

  useEffect(() => {
    if (!addMatterRequestInProgress) return;

    const timerId = setTimeout(() => {
      notify("Matéria cadastrada com sucesso", "success");
      fetchData();
      setLoading(false);
    }, 2 * 60 * 1000);

    return () => clearTimeout(timerId);
  }, [addMatterRequestInProgress]);

  const handleCurrentTab = (value) => {
    if (value === currentTab) {
      return;
    }

    setCurrentTab(value);
  };

  const handleNewField = (value) => ({
    label: value,
    value: value.toUpperCase(),
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["application/pdf"];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      inputFileRef.current.value = null;
      notify("Somente arquivos PDF são permitidos", "danger");
    }
  };

  const handleFileUpdateChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["application/pdf"];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedFileUpdate(file);
    } else {
      setSelectedFileUpdate(null);
      inputUpdateFileRef.current.value = null;
      notify("Somente arquivos PDF são permitidos", "danger");
    }
  };

  const toggleShowRemoveSessaoModal = () =>
    setRemoveSessaoModal(!removeSessaoModal);

  const toggleShowRemoveMateriaModal = () =>
    setRemoveMateriaModal(!removeMateriaModal);

  const toggleShowEditMateriaModal = () =>
    setEditMateriaModal(!editMateriaModal);

  const toggleShowEditRoteiroModal = () =>
    setEditRoteiroModal(!editRoteiroModal);

  const toggleShowRemoveRoteiroModal = () =>
    setRemoveRoteiroModal(!removeRoteiroModal);

  function handleSessaoChange(event) {
    const { name, value } = event.target;
    setSessaoChange({ ...sessaoChange, [name]: value });

    setSessaoIsChanged(true);
  }

  async function handleEditSessaoClick() {
    setLoading(true);

    if (materiaToVote !== null) {
      setLoading(false);
      notify(
        "Erro ao editar sessão, votação de matéria em andamento",
        "warning"
      );
      return;
    }

    if (materiaReading !== null) {
      setLoading(false);
      notify(
        "Erro ao editar sessão, leitura de matéria em andamento",
        "warning"
      );
      return;
    }

    const response = await editSessaoControlador(sessaoChange);

    if (response.data.status === "success") {
      notify(response.data.message, "success");
      setSessaoIsChanged(false);
      fetchData();
    } else notify(response.data.message, "danger");

    setLoading(false);
  }

  function handleBtnCriarMateria() {
    setShowForm(!showForm);
    loadAutoFill();
  }

  function handleBtnCriarRoteiro() {
    setShowFormRoteiro(!showFormRoteiro);
  }

  function handleBtnExcluirMateria(item) {
    if (materiaReading)
      return notify(
        "Não é possível excluir a matéria, pois uma leitura de matéria está em andamento.",
        "danger"
      );

    setMateriaToRemove(item);
    toggleShowRemoveMateriaModal();
  }

  function handleBtnExcluirRoteiro(item) {
    setRoteiroToRemove(item);
    toggleShowRemoveRoteiroModal();
  }

  function handleBtnEditarMateria(item) {
    if (materiaReading)
      return notify(
        "Não é possível editar a matéria, pois há uma leitura de matéria em andamento",
        "danger"
      );

    setMateriaToEdit(item);
    toggleShowEditMateriaModal();

    setSelectedParlamentaresToEditMateria(
      item.ParlamentarMateria.map((obj) => {
        if (obj.Name) {
          return {
            label: obj.Name,
            value: obj.Name,
          };
        } else {
          return {
            label: obj.Parlamentar.nickname ?? obj.Parlamentar.name,
            value: obj.ParlamentarId,
          };
        }
      })
    );
  }

  function handleBtnEditarRoteiro(item) {
    setRoteiroToEdit(item);
    toggleShowEditRoteiroModal();
  }

  function handleBtnAbrirPdf(item) {
    if (item.Path)
      window.open(`${process.env.REACT_APP_API_URL}/${item.Path}`, "_blank");
    else notify("Esta matéria não possui PDF cadastrado", "warning");
  }

  function initMateriaToVote(materia) {
    if (!sessionScheduledToday)
      return notify(
        "Erro ao iniciar votação, sessão não agendada para hoje.",
        "danger"
      );

    if (materiaReading)
      return notify(
        "Não é possível iniciar a votação, pois uma leitura de matéria está em andamento",
        "danger"
      );

    setMateriaToVote(materia);
  }

  function endMateriaToVote(materia) {
    setMateriaToVote(null);
  }

  function handleBtnLeituraMateria(materia) {
    if (!sessionScheduledToday)
      return notify(
        "Erro ao iniciar leitura, sessão não agendada para hoje.",
        "danger"
      );

    if (materiaReading && materia != materiaReading)
      socketEmit("EndReadyMatter", { CamaraId, Content: materiaReading });

    socketEmit("ReadyMatter", { CamaraId, Content: materia });
    notify(`A leitura da matéria "${materia.Numero}" foi iniciada`, "success");
    setMateriaReading(materia);
  }

  function handleBtnEncerrarLeituraMateria(materia) {
    socketEmit("EndReadyMatter", { CamaraId, Content: materia });
    notify(
      `A leitura da matéria "${materia.Numero}" foi finalizada`,
      "success"
    );
    setMateriaReading(null);
  }

  const handleformCriarMateriaChange = (event) => {
    const { name, value } = event.target;
    setformValues({ ...formValues, [name]: value });
  };

  const handleOrdemDiaCriarMateria = (value) => {
    setformValues({ ...formValues, ["ordemDia"]: value });
  };

  const handleOrdemDiaEditMateria = (value) => {
    setMateriaToEdit({ ...materiaToEdit, ["OrdemDia"]: value });
  };

  const handleformCriarRoteiroChange = (event) => {
    const { name, value } = event.target;
    setformValuesRoteiro({ ...formValuesRoteiro, [name]: value });
  };

  const handleformEditMateriaChange = (event) => {
    const { name, value } = event.target;
    setMateriaToEdit({ ...materiaToEdit, [name]: value });
  };

  const handleformEditRoteiroChange = (event) => {
    const { name, value } = event.target;
    setRoteiroToEdit({ ...roteiroToEdit, [name]: value });
  };

  const handleMultilpeSelectChange = () => {
    setformValues({
      ...formValues,
      parlamentaresId: selectedParlamentares.map((obj) => obj.value),
    });
  };

  function handleBtnClearNovaMateria() {
    setformValues({
      sessaoId: id,
      parlamentaresId: [],
      numero: "",
      tipo: "",
      resumo: "",
      data: "",
    });
    setSelectedParlamentares([]);
    setSelectedFile(null);
    inputFileRef.current.value = null;
  }

  function handleBtnClearNovoRoteiro() {
    setformValues({
      sessaoId: id,
      parlamentarId: null,
      numero: "",
      resumo: "",
    });
    setSelectedFile(null);
    inputFileRef.current.value = null;
  }

  const handleMultilpeSelectEditMateriaChange = () => {
    setMateriaToEdit({
      ...materiaToEdit,
      parlamentaresId: selectedParlamentaresToEditMateria.map(
        (obj) => obj.value
      ),
    });
  };

  async function handleBtnSalvarMateria() {
    setLoading(true);

    const formData = new FormData();
    formData.append("pdfFile", selectedFile);

    Object.entries(formValues).forEach(([key, value]) => {
      formData.append(key, value);
    });

    setAddMatterRequestInProgress(true);
    const response = await createMateriaControlador(formData);

    if (response.data.status === "success") {
      notify(response.data.message, "success");
      handleBtnClearNovaMateria();
      setShowForm(!showForm);
      fetchData();
      setAddMatterRequestInProgress(false);
    } else {
      notify(response.data.message, "danger");
      setAddMatterRequestInProgress(false);
    }

    setLoading(false);
  }

  async function handleBtnSalvarRoteiro() {
    setLoading(true);

    const formData = new FormData();
    formData.append("pdfFile", selectedFile);

    Object.entries(formValuesRoteiro).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await createRoteiroControlador(formData);

    if (!response) {
      setLoading(false);
    } else if (response.data.status === "success") {
      notify(response.data.message, "success");
      handleBtnClearNovoRoteiro();
      setShowFormRoteiro(!showFormRoteiro);
      fetchData();
    } else notify(response.data.message, "danger");

    setLoading(false);
  }

  async function removeSessao() {
    setLoading(true);

    const response = await removerSessaoControlador(sessao.id);

    if (response.data.status === "success") {
      notify(response.data.message, "success");

      setTimeout(() => {
        window.location.href = `/${getCityByUrl()}/controlador/sessoes`;
      }, 1000);
    } else notify(response.data.message, "danger");

    setLoading(false);
  }

  async function removeMateria() {
    setLoading(true);

    const response = await removerMateriaControlador(materiaToRemove.id);

    setMateriaToRemove(null);
    setRemoveMateriaModal(false);
    fetchData();

    if (response.status === "success") {
      notify(response.message, "success");
    } else notify(response.message, "danger");

    setLoading(false);
  }

  async function removeRoteiro() {
    setLoading(true);

    const response = await removerRoteiroControlador(roteiroToRemove.id);

    setRoteiroToRemove(null);
    setRemoveRoteiroModal(false);
    fetchData();

    if (response.status === "success") {
      notify(response.message, "success");
    } else notify(response.message, "danger");

    setLoading(false);
  }

  async function editMateria() {
    setLoading(true);

    if (selectedParlamentaresToEditMateria.length === 0) {
      setLoading(false);
      notify("Selecione pelo menos um autor.", "danger");
      return;
    }

    const formData = new FormData();
    formData.append("pdfFile", selectedFileUpdate);

    Object.entries(materiaToEdit).forEach(([key, value]) => {
      formData.append(key, value);
    });

    setAddMatterRequestInProgress(true);
    const response = await editMateriaControlador(materiaToEdit.id, formData);

    setMateriaToEdit(null);
    setEditMateriaModal(false);
    fetchData();

    if (response.status === "success") {
      notify(response.message, "success");
      setAddMatterRequestInProgress(false);
    } else {
      notify(response.message, "danger");
      setAddMatterRequestInProgress(false);
    }
  }

  async function editRoteiro() {
    setLoading(true);

    const formData = new FormData();
    formData.append("pdfFile", selectedFileUpdate);

    Object.entries(roteiroToEdit).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const response = await editRoteiroControlador(roteiroToEdit.id, formData);

    setRoteiroToEdit(null);
    setEditRoteiroModal(false);
    fetchData();

    if (response.status === "success") {
      notify(response.message, "success");
    } else notify(response.message, "danger");
  }

  function goToNewPage(url) {
    window.open(url, "_blank");
  }

  async function fetchData() {
    if (camara) {
      setLoading(true);
      try {
        const response = await getInfoSessaoControlador(id);
        setSessao(response.data.sessao);
        setSessaoChange(response.data.sessao);

        const sessaoIsClosed = response.data.sessao.Situacao == "Fechada";

        let materiasResponse = response.data.sessao.Materia ?? [];
        if (sessaoIsClosed) {
          if (response.data.sessao.Materia) {
            materiasResponse.forEach(async (materia, index) => {
              const approved = await getMateriaSituationById(materia.id);

              materiasResponse[index].Approved = approved;
            });
          }
        }

        setMaterias(materiasResponse);
        setRoteiros(response.data.sessao.Roteiros ?? []);

        if (
          response.data.sessao &&
          response.data.sessao.Data == getCurrentStringDate()
        )
          setSessionScheduledToday(true);
        else setSessionScheduledToday(false);

        const response_parlamentares = await getParlamentars();
        setParlamentares(response_parlamentares.data.parlamentares);

        const webSocketsHistoric = await getWebSocketHistoricByCamaraId(
          CamaraId
        );

        const comissoesResponse = await getComissaoByCamaraId(camara.id);
        setcomissoes(comissoesResponse);

        webSocketsHistoric.forEach((webSocketHistoric) => {
          switch (webSocketHistoric.Event) {
            case "ReadyMatter":
              setMateriaReading(webSocketHistoric.Data);
              break;
            case "StartVoting":
              setMateriaToVote(webSocketHistoric.Data.materia);
              break;
          }
        });

        const blockClosedSessionEditResponse =
          await getParameterByCamaraIdAndName(
            camara.id,
            ParameterName.BlockClosedSessionEdit
          );

        const isBlockClosedSessionEdit = checkParameterBooleanIsEnabled(
          blockClosedSessionEditResponse
        );

        setBlockClosedSessionEdit(isBlockClosedSessionEdit && sessaoIsClosed);
      } catch (error) {
        notify("Erro interno, tente novamente mais tarde.", "danger");
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    socketEmit("JoinCamara", { CamaraId });
    fetchData();
  }, [camara]);

  useEffect(() => {
    handleMultilpeSelectChange();
  }, [selectedParlamentares]);

  useEffect(() => {
    handleMultilpeSelectEditMateriaChange();
  }, [selectedParlamentaresToEditMateria]);

  async function loadAutoFill() {
    const SuggestMatterNameResponse = await getParameterByCamaraIdAndName(
      camara.id,
      ParameterName.SuggestMatterName
    );

    const isSuggestMatterName = checkParameterBooleanIsEnabled(
      SuggestMatterNameResponse
    );

    if (isSuggestMatterName) {
      const autoFill = `${formatNumber(materias.length + 1, 3)}/${getYear()}`;
      setformValues({ ...formValues, numero: autoFill });
    }
  }

  return (
    <div
      id="sessao"
      className={blockClosedSessionEdit ? "blockClosedSessionEdit" : ""}
    >
      <Spinner show={loading} />
      <MDBRow id="sessaoForm">
        <MDBCol md="12" className="mt-3">
          <h3 className="text-center">Sessão - {sessao.Numero}</h3>
        </MDBCol>
        <MDBCol sm="6" md="6">
          <label>
            <MDBIcon fas icon="spell-check" /> N° da Sessão
          </label>
          <input
            value={sessaoChange.Numero}
            onChange={handleSessaoChange}
            name="Numero"
            type="text"
            className="form-control"
            placeholder="00/00"
          />
        </MDBCol>
        <MDBCol sm="6" md="6">
          <label>
            <MDBIcon fas icon="calendar-check" /> Data da Sessão
          </label>
          <input
            value={sessaoChange.Data}
            onChange={handleSessaoChange}
            name="Data"
            type="date"
            className="form-control"
            placeholder="00/00"
          />
        </MDBCol>
        <MDBCol sm="4" md="4" className="mt-3">
          <label>
            <MDBIcon fas icon="caret-down" /> Tipo da Sessão
          </label>
          <select
            value={sessaoChange.Tipo}
            onChange={handleSessaoChange}
            name="Tipo"
            className="form-select"
            defaultValue={""}
          >
            <option value="">Selecione</option>
            <option value="Extra-Ordinária">Extra-Ordinária</option>
            <option value="Audiência pública">Audiência pública</option>
            <option value="Solene">Solene</option>
            <option value="Abertura de período legislativo">
              Abertura de período legislativo
            </option>
            <option value="Encerramento de período legislativo">
              Encerramento de período legislativo
            </option>
            <option value="Intinerante">Intinerante</option>
            <option value="Sessão administrativa">Sessão administrativa</option>
            <option value="Sessão especial">Sessão especial</option>
            <option value="Ordinária">Ordinária</option>
          </select>
        </MDBCol>
        <MDBCol sm="4" md="4" className="mt-3">
          <label>
            <MDBIcon fas icon="caret-down" /> Situação da Sessão
          </label>
          <select
            value={sessaoChange.Situacao}
            onChange={handleSessaoChange}
            name="Situacao"
            className="form-select"
            defaultValue={""}
          >
            <option value="">Selecione</option>
            <option value="Aberta">Aberta</option>
            <option value="Fechada">Fechada</option>
          </select>
        </MDBCol>
        <MDBCol sm="4" md="4" className="mt-3">
          <label>
            <MDBIcon icon="users" /> Comissão (opcional)
          </label>
          <select
            value={sessaoChange.ComissaoId}
            onChange={handleSessaoChange}
            name="ComissaoId"
            className="form-select"
            aria-label="Default select example"
          >
            <option value="" selected>
              Selecione
            </option>
            {comissoes.map((item, index) => (
              <option key={index} value={item.id}>
                {item.Name}
              </option>
            ))}
          </select>
        </MDBCol>
        <MDBCol sm="12" md="12" className="mt-3">
          <label>
            <MDBIcon far icon="comment-alt" /> Descrição
          </label>
          <textarea
            value={sessaoChange.Descricao}
            onChange={handleSessaoChange}
            name="Descricao"
            className="form-control"
            rows="2"
          />
        </MDBCol>
        {sessaoIsChanged && (
          <MDBCol sm="12" md="12">
            <MDBBtn
              onClick={handleEditSessaoClick}
              className="mt-4"
              color={"success"}
            >
              <MDBIcon fas icon={"save"} /> Salvar Edição
            </MDBBtn>
          </MDBCol>
        )}
      </MDBRow>

      <MDBTabs fill className="mt-3">
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleCurrentTab("materias")}
            active={currentTab === "materias"}
          >
            Matérias
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleCurrentTab("roteiros")}
            active={currentTab === "roteiros"}
          >
            Roteiros
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={currentTab === "materias"}>
          <>
            <MDBRow>
              <MDBCol md="8" className="mt-3">
                <h5 className="title pt-4">
                  <MDBIcon fas icon="poll" /> Matérias
                </h5>
              </MDBCol>
              <MDBCol md="4" className="mt-3 mb-2 d-flex justify-content-end">
                {!showForm && (
                  <>
                    <MDBBtn
                      onClick={() =>
                        goToNewPage(
                          `/${getCityByUrl()}/controlador/relatorio/${
                            sessao.id
                          }`
                        )
                      }
                      className="mt-4 me-2 btnRelatorio"
                      color={"warning"}
                    >
                      <MDBIcon fas icon={"eye"} /> Exibir relatório
                    </MDBBtn>
                    <MDBBtn
                      onClick={() =>
                        goToNewPage(`/${getCityByUrl()}/relatorioTempoReal`)
                      }
                      className="mt-4 me-2 btnRelatorio"
                      color={"primary"}
                    >
                      <MDBIcon fas icon={"eye"} /> Painel em tempo real
                    </MDBBtn>
                  </>
                )}
                <MDBBtn
                  onClick={handleBtnCriarMateria}
                  className="mt-4"
                  color={showForm ? "danger" : "success"}
                >
                  <MDBIcon fas icon={showForm ? "close" : "plus"} />
                  {showForm ? " Fechar" : " Criar Matéria"}
                </MDBBtn>
              </MDBCol>

              {showForm && (
                <div className="criarMaterias">
                  <MDBRow>
                    <MDBCol sm="6" md="4">
                      <label>
                        <MDBIcon fas icon="spell-check" /> N° da Matéria
                      </label>
                      <input
                        value={formValues.numero}
                        onChange={handleformCriarMateriaChange}
                        name="numero"
                        type="text"
                        className="form-control"
                        placeholder="00/00"
                      />
                    </MDBCol>
                    <MDBCol sm="6" md="4">
                      <label>
                        <MDBIcon fas icon="caret-down" /> Tipo
                      </label>
                      <select
                        value={formValues.tipo}
                        onChange={handleformCriarMateriaChange}
                        name="tipo"
                        className="form-select"
                        aria-label="Default select example"
                        defaultValue={""}
                      >
                        <option value="">Selecione</option>
                        <option value="1">ATA</option>
                        <option value="2">Convocações</option>
                        <option value="3">Edital de convocação</option>
                        <option value="4">Emendas</option>
                        <option value="5">Indicação</option>
                        <option value="6">Moções</option>
                        <option value="7">Ofícios Expedidos</option>
                        <option value="8">Ofícios Recebidos</option>
                        <option value="9">
                          Parecer Prévio - Contas de Governo
                        </option>
                        <option value="10">Pedido de Providências</option>
                        <option value="11">
                          Projeto de Decreto Legislativo
                        </option>
                        <option value="12">Projeto de Indicativo</option>
                        <option value="13">Projeto de Lei - Executivo</option>
                        <option value="14">Projeto de Lei - Legislativo</option>
                        <option value="15">Projeto de resolução</option>
                        <option value="16">Proposta de Emendas</option>
                        <option value="17">Requerimentos</option>
                        <option value="18">Resolução</option>
                      </select>
                    </MDBCol>
                    <MDBCol sm="6" md="4">
                      <label>
                        <MDBIcon far icon="calendar-check" /> Data
                      </label>
                      <input
                        value={formValues.data}
                        onChange={handleformCriarMateriaChange}
                        name="data"
                        type="date"
                        className="form-control"
                        placeholder="00/00"
                      />
                    </MDBCol>
                    <MDBCol sm="6" md="4" className="mt-2">
                      <label>
                        <MDBIcon fas icon="caret-down" /> Autores
                      </label>
                      <MultiSelect
                        options={parlamentares.map((item) => {
                          return {
                            label: item.nickname ?? item.name,
                            value: item.id,
                          };
                        })}
                        value={selectedParlamentares}
                        onChange={setSelectedParlamentares}
                        labelledBy={"Select"}
                        isCreatable={true}
                        overrideStrings={{
                          search: "Pesquisar",
                          selectAll: "Selecionar todos",
                          selectSomeItems: "Selecione...",
                          create: "Adicionar autor externo",
                        }}
                        hasSelectAll={false}
                        onCreateOption={handleNewField}
                      />
                    </MDBCol>
                    <MDBCol sm="6" md="4" className="mt-2">
                      <label>
                        <MDBIcon fas icon="file" /> Documento PDF (Opcional)
                      </label>
                      <input
                        onChange={handleFileChange}
                        name="file"
                        type="file"
                        id="pdfFile"
                        ref={inputFileRef}
                        className="form-control"
                        placeholder="00/00"
                      />
                    </MDBCol>
                    <MDBCol sm="6" md="4" className="mt-4 d-flex">
                      <MDBRadio
                        name="ordemDia"
                        onClick={() => handleOrdemDiaCriarMateria(true)}
                        label="Ordem do dia"
                      />

                      <MDBRadio
                        className="ms-1"
                        name="ordemDia"
                        onClick={() => handleOrdemDiaCriarMateria(false)}
                        label="pautas da sessão"
                        defaultChecked
                      />
                    </MDBCol>

                    <MDBCol sm="12" md="12" className="mt-2">
                      <label>
                        <MDBIcon far icon="comment-alt" /> Resumo
                      </label>
                      <textarea
                        value={formValues.resumo}
                        onChange={handleformCriarMateriaChange}
                        name="resumo"
                        className="form-control"
                        rows="2"
                      />
                    </MDBCol>
                    <MDBCol
                      sm="6"
                      md="12"
                      className="d-flex justify-content-end"
                    >
                      <MDBBtn
                        onClick={handleBtnClearNovaMateria}
                        className="me-1 mt-4"
                        color="warning"
                      >
                        <MDBIcon fas icon="times" /> Limpar Campos
                      </MDBBtn>
                      <MDBBtn
                        onClick={handleBtnSalvarMateria}
                        className="me-1 mt-4"
                        color="success"
                      >
                        <MDBIcon fas icon="save" /> CRIAR
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              )}

              {materias.length === 0 ? (
                <MDBCol
                  md={12}
                  className="mb-2 m-4 mb-0 fs-3 d-flex justify-content-center"
                >
                  <MDBBadge className="mx-2 d-block" color="info" light>
                    <MDBIcon fas icon="info-circle" /> Ainda não foram
                    cadastradas matérias nesta sessão
                  </MDBBadge>
                </MDBCol>
              ) : (
                <>
                  {materias.filter((materia) => materia.OrdemDia).length >
                    0 && (
                    <h4 className="text-center fw-bold mt-5">
                      <MDBIcon fas icon="poll" /> ORDENS DO DIA
                    </h4>
                  )}
                  {materias
                    .filter((materia) => materia.OrdemDia)
                    .map((item, index) => (
                      <MDBCol md="12" className="mt-3">
                        <Card
                          key={item.id}
                          color={"#14a44d"}
                          title={item.Numero}
                          description={item.Resumo}
                          notes={[
                            sessao.Situacao === "Fechada" &&
                              (item.Approved
                                ? {
                                    value: "Aprovada",
                                    color: "#10813d",
                                  }
                                : {
                                    value: "Reprovada",
                                    color: "#dc4c64",
                                  }),
                            {
                              icon: "calendar-check",
                              value: new Date(item.Data).toLocaleDateString(
                                "pt-BR",
                                {
                                  timeZone: "UTC",
                                }
                              ),
                            },
                            {
                              icon: "",
                              value: item.Tipo,
                            },
                          ]}
                          buttons={[
                            {
                              color:
                                sessao.Situacao === "Fechada"
                                  ? "primary disabled"
                                  : materiaReading &&
                                    materiaReading.id === item.id
                                  ? "danger"
                                  : "primary",
                              icon:
                                materiaReading && materiaReading.id === item.id
                                  ? "eye-slash"
                                  : "eye",
                              functionToExecute:
                                sessao.Situacao !== "Fechada"
                                  ? materiaReading &&
                                    materiaReading.id === item.id
                                    ? handleBtnEncerrarLeituraMateria
                                    : handleBtnLeituraMateria
                                  : undefined,
                              parameterToFunction: item,
                              value:
                                materiaReading && materiaReading.id === item.id
                                  ? "Finalizar leitura"
                                  : "Iniciar leitura",
                            },
                            {
                              color:
                                sessao.Situacao === "Fechada"
                                  ? "info disabled"
                                  : materiaToVote &&
                                    materiaToVote.id === item.id
                                  ? "danger"
                                  : "info",
                              icon:
                                materiaToVote && materiaToVote.id === item.id
                                  ? "times"
                                  : "vote-yea",
                              functionToExecute:
                                sessao.Situacao !== "Fechada"
                                  ? materiaToVote &&
                                    materiaToVote.id === item.id
                                    ? endMateriaToVote
                                    : initMateriaToVote
                                  : undefined,
                              parameterToFunction: item,
                              value:
                                materiaToVote && materiaToVote.id === item.id
                                  ? "Finalizar votação"
                                  : "Iniciar votação",
                            },
                            {
                              color: "warning",
                              icon: "pen",
                              functionToExecute: handleBtnEditarMateria,
                              parameterToFunction: item,
                              value: "Editar",
                            },
                            item.Path && {
                              color: "success",
                              icon: "file",
                              functionToExecute: handleBtnAbrirPdf,
                              parameterToFunction: item,
                              value: "Abrir PDF",
                            },
                            {
                              color: "danger",
                              icon: "close",
                              functionToExecute: handleBtnExcluirMateria,
                              parameterToFunction: item,
                              value: "Excluir",
                            },
                          ]}
                        />
                      </MDBCol>
                    ))}
                  {materias.filter((materia) => !materia.OrdemDia).length >
                    0 && (
                    <h4 className="text-center fw-bold mt-5">
                      <MDBIcon fas icon="book" /> PAUTAS DA SESSÃO
                    </h4>
                  )}
                  {materias
                    .filter((materia) => !materia.OrdemDia)
                    .map((item, index) => (
                      <MDBCol md="12" className="mt-3">
                        <Card
                          key={item.id}
                          color={"#14a44d"}
                          title={item.Numero}
                          description={item.Resumo}
                          notes={[
                            sessao.Situacao === "Fechada" &&
                              (item.Approved
                                ? {
                                    value: "Aprovada",
                                    color: "#10813d",
                                  }
                                : {
                                    value: "Reprovada",
                                    color: "#dc4c64",
                                  }),
                            {
                              icon: "calendar-check",
                              value: new Date(item.Data).toLocaleDateString(
                                "pt-BR",
                                {
                                  timeZone: "UTC",
                                }
                              ),
                            },
                            {
                              icon: "",
                              value: item.Tipo,
                            },
                          ]}
                          buttons={[
                            {
                              color:
                                sessao.Situacao === "Fechada"
                                  ? "primary disabled"
                                  : materiaReading &&
                                    materiaReading.id === item.id
                                  ? "danger"
                                  : "primary",
                              icon:
                                materiaReading && materiaReading.id === item.id
                                  ? "eye-slash"
                                  : "eye",
                              functionToExecute:
                                sessao.Situacao !== "Fechada"
                                  ? materiaReading &&
                                    materiaReading.id === item.id
                                    ? handleBtnEncerrarLeituraMateria
                                    : handleBtnLeituraMateria
                                  : undefined,
                              parameterToFunction: item,
                              value:
                                materiaReading && materiaReading.id === item.id
                                  ? "Finalizar leitura"
                                  : "Iniciar leitura",
                            },
                            {
                              color: "warning",
                              icon: "pen",
                              functionToExecute: handleBtnEditarMateria,
                              parameterToFunction: item,
                              value: "Editar",
                            },
                            item.Path && {
                              color: "success",
                              icon: "file",
                              functionToExecute: handleBtnAbrirPdf,
                              parameterToFunction: item,
                              value: "Abrir PDF",
                            },
                            {
                              color: "danger",
                              icon: "close",
                              functionToExecute: handleBtnExcluirMateria,
                              parameterToFunction: item,
                              value: "Excluir",
                            },
                          ]}
                        />
                      </MDBCol>
                    ))}
                </>
              )}
            </MDBRow>
            <MDBRow style={{ marginBottom: "8vh" }}>
              <MDBCol md={12} className="mt-5 d-flex justify-content-center">
                <MDBBtn
                  onClick={toggleShowRemoveSessaoModal}
                  className="mt-4 btnRemoveSessao"
                  color="danger"
                >
                  <MDBIcon fas icon="close" /> Excluir Sessão
                </MDBBtn>
              </MDBCol>

              {/* MODAL INIT*/}
              <MDBModal
                show={removeSessaoModal}
                setShow={setRemoveSessaoModal}
                tabIndex="-1"
              >
                <MDBModalDialog>
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Excluir Sessão</MDBModalTitle>
                      <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShowRemoveSessaoModal}
                      ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                      Todos os PDFs, dados de votação e presença associados a
                      essa Sessão serão deletados. Realmente deseja excluir a
                      sessão "{sessao.Numero}"?
                    </MDBModalBody>

                    <MDBModalFooter>
                      <MDBBtn
                        onClick={removeSessao}
                        color="danger"
                        className="btnConfirmRemoveSessao"
                      >
                        <MDBIcon fas icon="close" /> Excluir
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
              {/* MODAL END */}

              {/* MODAL INIT*/}
              <MDBModal
                show={removeMateriaModal}
                setShow={setRemoveMateriaModal}
                tabIndex="-1"
              >
                <MDBModalDialog>
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Excluir Matéria</MDBModalTitle>
                      <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShowRemoveMateriaModal}
                      ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                      Os arquivos PDFs e os dados referentes a votação e
                      presença dessa matéria serão perdidos. Realmente deseja
                      excluir"?
                    </MDBModalBody>

                    <MDBModalFooter>
                      <MDBBtn onClick={removeMateria} color="danger">
                        <MDBIcon fas icon="close" /> Excluir
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
              {/* MODAL END */}

              {/* MODAL INIT*/}
              <MDBModal
                show={editMateriaModal}
                setShow={setEditMateriaModal}
                tabIndex="-1"
              >
                <MDBModalDialog>
                  <MDBModalContent>
                    <MDBModalHeader>
                      <MDBModalTitle>Editar Matéria</MDBModalTitle>
                      <MDBBtn
                        className="btn-close"
                        color="none"
                        onClick={toggleShowEditMateriaModal}
                      ></MDBBtn>
                    </MDBModalHeader>
                    <MDBModalBody>
                      {materiaToEdit && (
                        <MDBRow>
                          <MDBCol sm="12" md="12" className="mb-2">
                            <label>
                              <MDBIcon fas icon="spell-check" /> Número
                            </label>
                            <input
                              value={materiaToEdit.Numero}
                              onChange={handleformEditMateriaChange}
                              name="Numero"
                              type="text"
                              className="form-control"
                            />
                          </MDBCol>
                          <MDBCol sm="12" md="12" className="mb-2">
                            <label>
                              <MDBIcon fas icon="caret-down" /> Autores
                            </label>
                            <MultiSelect
                              options={parlamentares.map((item) => {
                                return {
                                  label: item.nickname ?? item.name,
                                  value: item.id,
                                };
                              })}
                              value={selectedParlamentaresToEditMateria}
                              onChange={setSelectedParlamentaresToEditMateria}
                              labelledBy={"Select"}
                              isCreatable={true}
                              overrideStrings={{
                                search: "Pesquisar",
                                selectAll: "Selecionar todos",
                                selectSomeItems: "Selecione...",
                                create: "Adicionar autor externo",
                              }}
                              hasSelectAll={false}
                              onCreateOption={handleNewField}
                            />
                          </MDBCol>
                          <MDBCol sm="6" md="6" className="mb-2">
                            <label>
                              <MDBIcon fas icon="caret-down" /> Tipo
                            </label>
                            <select
                              value={materiaToEdit.Tipo}
                              onChange={handleformEditMateriaChange}
                              name="Tipo"
                              className="form-select"
                              aria-label="Default select example"
                              defaultValue={""}
                            >
                              <option value="">Selecione</option>
                              <option value="ATA">ATA</option>
                              <option value="Convocações">Convocações</option>
                              <option value="Edital de convocação">
                                Edital de convocação
                              </option>
                              <option value="Emendas">Emendas</option>
                              <option value="Indicação">Indicação</option>
                              <option value="Moções">Moções</option>
                              <option value="Ofícios Expedidos">
                                Ofícios Expedidos
                              </option>
                              <option value="Ofícios Recebidos">
                                Ofícios Recebidos
                              </option>
                              <option value="Parecer Prévio - Contas de Governo">
                                Parecer Prévio - Contas de Governo
                              </option>
                              <option value="Pedido de Providências">
                                Pedido de Providências
                              </option>
                              <option value="Projeto de Decreto Legislativo">
                                Projeto de Decreto Legislativo
                              </option>
                              <option value="Projeto de Indicativo">
                                Projeto de Indicativo
                              </option>
                              <option value="Projeto de Lei - Executivo">
                                Projeto de Lei - Executivo
                              </option>
                              <option value="14">
                                Projeto de Lei - Legislativo
                              </option>
                              <option value="Projeto de Lei - Legislativo">
                                Projeto de resolução
                              </option>
                              <option value="Proposta de Emendas">
                                Proposta de Emendas
                              </option>
                              <option value="Requerimentos">
                                Requerimentos
                              </option>
                              <option value="Resolução">Resolução</option>
                            </select>
                          </MDBCol>
                          <MDBCol sm="6" md="6" className="mb-2">
                            <label>
                              <MDBIcon fas icon="calendar-check" /> Data
                            </label>
                            <input
                              value={materiaToEdit.Data}
                              onChange={handleformEditMateriaChange}
                              name="Data"
                              type="date"
                              className="form-control"
                              placeholder="00/00"
                            />
                          </MDBCol>
                          <MDBCol sm="12" md="12" className="mb-2">
                            <label>
                              <MDBIcon far icon="file" /> Documento PDF
                              (Opcional)
                            </label>
                            <input
                              onChange={handleFileUpdateChange}
                              name="pdfUpdateFile"
                              type="file"
                              id="pdfUpdateFile"
                              ref={inputUpdateFileRef}
                              className="form-control"
                              placeholder="00/00"
                            />
                          </MDBCol>
                          <MDBCol sm="12" md="12" className="mb-2">
                            <label>
                              <MDBIcon far icon="comment-alt" /> Resumo
                            </label>
                            <textarea
                              value={materiaToEdit.Resumo}
                              onChange={handleformEditMateriaChange}
                              name="Resumo"
                              className="form-control"
                              rows="2"
                            />
                          </MDBCol>
                          <MDBCol sm="12" md="12" className="m-2">
                            <MDBRadio
                              name="ordemDia"
                              onClick={() => handleOrdemDiaEditMateria(true)}
                              label="Ordem do dia"
                              defaultChecked={materiaToEdit.OrdemDia}
                            />

                            <MDBRadio
                              name="ordemDia"
                              onClick={() => handleOrdemDiaEditMateria(false)}
                              label="pautas da sessão"
                              defaultChecked={materiaToEdit.OrdemDia ?? true}
                            />
                          </MDBCol>
                        </MDBRow>
                      )}
                    </MDBModalBody>

                    <MDBModalFooter>
                      <MDBBtn onClick={editMateria} color="warning">
                        <MDBIcon fas icon="pen" /> Salvar Edição
                      </MDBBtn>
                    </MDBModalFooter>
                  </MDBModalContent>
                </MDBModalDialog>
              </MDBModal>
              {/* MODAL END */}
              <VotingControlModal
                showModal={materiaToVote}
                functionToExecute={
                  materiaToVote ? endMateriaToVote : initMateriaToVote
                }
                materiaToVote={materiaToVote}
                camara={camara}
              />
            </MDBRow>
          </>
        </MDBTabsPane>
        <MDBTabsPane show={currentTab === "roteiros"}>
          <MDBRow>
            <MDBCol md="8" className="mt-3">
              <h5 className="title pt-4">
                <MDBIcon fas icon="file" /> Roteiros
              </h5>
            </MDBCol>
            <MDBCol md="4" className="mt-3 mb-2 d-flex justify-content-end">
              <MDBBtn
                onClick={handleBtnCriarRoteiro}
                className="mt-4"
                color={showFormRoteiro ? "danger" : "success"}
              >
                <MDBIcon fas icon={showFormRoteiro ? "close" : "plus"} />
                {showFormRoteiro ? " Fechar" : " Criar Roteiro"}
              </MDBBtn>
            </MDBCol>

            {showFormRoteiro && (
              <div className="criarMaterias">
                <MDBRow>
                  <MDBCol sm="6" md="4">
                    <label>
                      <MDBIcon fas icon="spell-check" /> N° do Roteiro
                    </label>
                    <input
                      value={formValuesRoteiro.numero}
                      onChange={handleformCriarRoteiroChange}
                      name="numero"
                      type="text"
                      className="form-control"
                      placeholder="00/00"
                    />
                  </MDBCol>
                  <MDBCol sm="6" md="4">
                    <label>
                      <MDBIcon fas icon="caret-down" /> Responsável
                    </label>
                    <select
                      className="form-select"
                      name="parlamentarId"
                      value={formValuesRoteiro.parlamentarId}
                      onChange={handleformCriarRoteiroChange}
                    >
                      <option selected>selecione</option>
                      {parlamentares.map((item) => (
                        <option value={item.id}>
                          {item.nickname ?? item.name}
                        </option>
                      ))}
                    </select>
                  </MDBCol>
                  <MDBCol sm="6" md="4">
                    <label>
                      <MDBIcon fas icon="file" /> Documento PDF (Opcional)
                    </label>
                    <input
                      onChange={handleFileChange}
                      name="file"
                      type="file"
                      id="pdfFile"
                      ref={inputFileRef}
                      className="form-control"
                      placeholder="00/00"
                    />
                  </MDBCol>
                  <MDBCol sm="12" md="12" className="mt-2">
                    <label>
                      <MDBIcon far icon="comment-alt" /> Resumo
                    </label>
                    <textarea
                      value={formValuesRoteiro.resumo}
                      onChange={handleformCriarRoteiroChange}
                      name="resumo"
                      className="form-control"
                      rows="2"
                    />
                  </MDBCol>
                  <MDBCol sm="6" md="12" className="d-flex justify-content-end">
                    <MDBBtn
                      onClick={handleBtnClearNovoRoteiro}
                      className="me-1 mt-4"
                      color="warning"
                    >
                      <MDBIcon fas icon="times" /> Limpar Campos
                    </MDBBtn>
                    <MDBBtn
                      onClick={handleBtnSalvarRoteiro}
                      className="me-1 mt-4"
                      color="success"
                    >
                      <MDBIcon fas icon="save" /> CRIAR
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </div>
            )}

            {roteiros.length === 0 ? (
              <MDBCol
                md={12}
                className="mb-2 m-4 mb-0 fs-3 d-flex justify-content-center"
              >
                <MDBBadge className="mx-2 d-block" color="info" light>
                  <MDBIcon fas icon="info-circle" /> Ainda não foram cadastrados
                  roteiros nesta sessão
                </MDBBadge>
              </MDBCol>
            ) : (
              roteiros.map((item, index) => (
                <MDBCol md="12" className="mt-3">
                  <Card
                    key={item.id}
                    color={"#54b4d3"}
                    title={item.Numero}
                    description={item.Resumo}
                    notes={[
                      {
                        icon: "",
                        value: item.Parlamentar
                          ? "Responsável: " +
                            loadParlamentarName(item.Parlamentar)
                          : "Responsável não identificado",
                      },
                    ]}
                    buttons={[
                      {
                        color: "warning",
                        icon: "pen",
                        functionToExecute: handleBtnEditarRoteiro,
                        parameterToFunction: item,
                        value: "Editar",
                      },
                      item.Path && {
                        color: "success",
                        icon: "file",
                        functionToExecute: handleBtnAbrirPdf,
                        parameterToFunction: item,
                        value: "Abrir PDF",
                      },
                      {
                        color: "danger",
                        icon: "close",
                        functionToExecute: handleBtnExcluirRoteiro,
                        parameterToFunction: item,
                        value: "Excluir",
                      },
                    ]}
                  />
                </MDBCol>
              ))
            )}
          </MDBRow>
          {/* MODAL INIT*/}
          <MDBModal
            show={removeRoteiroModal}
            setShow={setRemoveRoteiroModal}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Excluir Roteiro</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleShowRemoveRoteiroModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  Os arquivos PDFs e os dados referentes ao roteiro serão
                  perdidos. Realmente deseja excluir"?
                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn onClick={removeRoteiro} color="danger">
                    <MDBIcon fas icon="close" /> Excluir
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          {/* MODAL END */}

          {/* MODAL INIT*/}
          <MDBModal
            show={editRoteiroModal}
            setShow={setEditRoteiroModal}
            tabIndex="-1"
          >
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Editar Roteiro</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={toggleShowEditRoteiroModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  {roteiroToEdit && (
                    <MDBRow>
                      <MDBCol sm="12" md="12" className="mb-2">
                        <label>
                          <MDBIcon fas icon="spell-check" /> Número
                        </label>
                        <input
                          value={roteiroToEdit.Numero}
                          onChange={handleformEditRoteiroChange}
                          name="Numero"
                          type="text"
                          className="form-control"
                        />
                      </MDBCol>
                      <MDBCol sm="12" md="12" className="mb-2">
                        <label>
                          <MDBIcon fas icon="caret-down" /> Responsável
                        </label>
                        <select
                          className="form-select"
                          name="ParlamentarId"
                          value={roteiroToEdit.ParlamentarId}
                          onChange={handleformEditRoteiroChange}
                        >
                          {parlamentares.map((item) => (
                            <option value={item.id}>
                              {item.nickname ?? item.name}
                            </option>
                          ))}
                        </select>
                      </MDBCol>
                      <MDBCol sm="12" md="12" className="mb-2">
                        <label>
                          <MDBIcon far icon="file" /> Documento PDF (Opcional)
                        </label>
                        <input
                          onChange={handleFileUpdateChange}
                          name="pdfUpdateFile"
                          type="file"
                          id="pdfUpdateFile"
                          ref={inputUpdateFileRef}
                          className="form-control"
                          placeholder="00/00"
                        />
                      </MDBCol>
                      <MDBCol sm="12" md="12" className="mb-2">
                        <label>
                          <MDBIcon far icon="comment-alt" /> Resumo
                        </label>
                        <textarea
                          value={roteiroToEdit.Resumo}
                          onChange={handleformEditRoteiroChange}
                          name="Resumo"
                          className="form-control"
                          rows="2"
                        />
                      </MDBCol>
                    </MDBRow>
                  )}
                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn onClick={editRoteiro} color="warning">
                    <MDBIcon fas icon="pen" /> Salvar Edição
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
          {/* MODAL END */}
        </MDBTabsPane>
      </MDBTabsContent>
    </div>
  );
};

export default Sessao;
