import "./Presenca.css";
import React, { useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBBadge,
} from "mdb-react-ui-kit";
import { useCamaraContext } from "../../../context/camaraContext";

const Presenca = (props) => {
  const { camara } = useCamaraContext();
  const presenca = props.presenca;

  return (
    <>
      {camara && (
        <>
          {presenca && (
            <MDBRow className="presenca-container">
              <MDBCol md="12" className="mb-2">
                <MDBRow className="mx-2 d-flex justify-content-center">
                  <MDBCol md="12" className="mb-3 mb-1 title-page">
                    <h3 className="fw-bold text-center">
                      PARLAMENTARES PRESENTES
                    </h3>
                  </MDBCol>
                  {presenca.map((item) => (
                    <MDBCol
                      key={item.id}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={4}
                      xl={3}
                      className="mb-3"
                    >
                      <MDBCard
                        border={(() => {
                          switch (item.Situacao) {
                            case "Presente":
                              return "success";
                            case "Ausente":
                              return "warning";
                            case "Licença":
                              return "primary";
                          }
                        })()}
                        background="#dddddd"
                      >
                        <MDBCardBody
                          className={
                            item.Situacao === "Presente"
                              ? "bg-success text-white"
                              : ""
                          }
                        >
                          <div className="d-flex align-items-center">
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${item.Parlamentar.image}`}
                              alt=""
                              style={{ width: "45px", height: "45px" }}
                              className="rounded-circle"
                            />
                            <div className={`ms-3 text-overflow`}>
                              <p className="fw-bold mb-1 text-overflow">
                                {item.Parlamentar.nickname ??
                                  item.Parlamentar.name}
                              </p>
                              <p className="fw-bold mb-1 text-overflow">
                                {item.Parlamentar.partido ?? "partido"}
                              </p>
                              <p className="text-muted mb-0 text-overflow">
                                {item.Situacao && (
                                  <MDBBadge
                                    pill
                                    color={(() => {
                                      switch (item.Situacao) {
                                        case "Presente":
                                          return "success";
                                        case "Ausente":
                                          return "warning";
                                        case "Licença":
                                          return "primary";
                                      }
                                    })()}
                                    light
                                  >
                                    {item.Situacao}
                                  </MDBBadge>
                                )}{" "}
                                {item.Parlamentar.occupation}
                              </p>
                            </div>
                          </div>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          )}
        </>
      )}
    </>
  );
};

export default Presenca;
