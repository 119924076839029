import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { api, createSession, getCamaraByCity } from "../services/api";
import { notify } from "../common/toastConfig";
import { getCityByUrl, goToLogin, isPainel } from "../common/util";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const recoveredUser = localStorage.getItem("user");
    const token = localStorage.getItem("token");

    if (recoveredUser && token) {
      setUser(JSON.parse(recoveredUser));
      api.defaults.headers.Authorization = "Bearer " + token;
    }
    setLoading(false);
    checkLoginByCity();
  }, []);

  async function checkLoginByCity() {
    const city = getCityByUrl();
    const camara = await getCamaraByCity(city);
    const user = JSON.parse(localStorage.getItem("controllerUser"));

    const fullUrl = window.location.href;
    const isRealTimerReport = fullUrl
      .toLowerCase()
      .includes("relatoriotemporeal");

    if (!isRealTimerReport && user && camara && user.camaraId !== camara.id) {
      if (!isPainel()) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        api.defaults.headers.Authorization = null;
        goToLogin();
      }
    } else return true;
  }

  const login = async (email, password) => {
    setLoading(true);
    const response = await createSession(email, password);
    setLoading(false);
    if (response.data.status != "success")
      return notify(response.data.message, "danger");

    const token = response.data.token;

    const loggedUser = {
      email: email,
      id: response.data.id,
      camaraId: response.data.camaraId,
    };

    localStorage.setItem("user", JSON.stringify(loggedUser));
    localStorage.setItem("token", token);

    api.defaults.headers.Authorization = "Bearer " + token;

    setUser({
      id: loggedUser.id,
      email: loggedUser.email,
      camaraId: loggedUser.camaraId,
    });
    navigate(`/${getCityByUrl()}/vereador`);
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    api.defaults.headers.Authorization = null;
    navigate(`/${getCityByUrl()}/login`);
  };

  return (
    <AuthContext.Provider
      value={{ authenticated: !!user, user, loading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
