import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const notify = (message, type = 'info') => {
  switch (type) {
    case 'success':
      toast.success(message, toastConfig);
      break;
    case 'warning':
      toast.warning(message, toastConfig);
      break;
    case 'danger':
      toast.error(message, toastConfig);
      break;
    default:
      toast(message, toastConfig);
      break;
  }
};  