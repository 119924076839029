import "./ParlamentarSidebar.css";
import React, { useState, useContext, useEffect } from "react";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBIcon,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBListGroup,
  MDBListGroupItem,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBTypography,
  MDBBadge,
  MDBInput,
} from "mdb-react-ui-kit";
import {
  changePasswordUser,
  getAllMateriasFromParlamentarId,
  getMateriasBySessaoId,
  getParlamentarsByCamaraId,
  getRoteiroByParlamentarIdAndSessaoId,
  getStatisticsByParlamentarId,
} from "../../services/api";
import { notify } from "../../common/toastConfig";
import { AuthContext } from "../../context/auth";
import TinyBarChart from "../../components/Charts/TinyBarChart/TinyBarChart";
import PieChart from "../../components/Charts/PieChart/PieChart";
import { useCamaraContext } from "../../context/camaraContext";
import {
  convertStringDateForBrDate,
  useFullScreenToggle,
} from "../../common/util";
import PdfView from "../PdfView/PdfView";

const ParlamentarSidebar = (props) => {
  const { parlamentar, sessao } = props;
  const [parlamentarMateriaModal, setParlamentarMateriaModal] = useState(false);
  const [sessaoMateriaModal, setSessaoMateriaModal] = useState(false);
  const [parlamentarMaterias, setParlamentarMaterias] = useState([]);
  const [sessaoMaterias, setSessaoMaterias] = useState([]);
  const [logoutModal, setLogoutModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [parlamentarInfo, setParlamentarInfo] = useState(null);
  const [materiasParlamentar, setMateriasParlamentar] = useState([]);
  const [statisticsParlamentar, setStatisticsParlamentar] = useState(null);
  const [statisticsParlamentarModal, setStatisticsParlamentarModal] =
    useState(null);
  const [parlamentars, setParlamentars] = useState([]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");
  const [roteiros, setRoteiros] = useState([]);
  const [roteirosModal, setRoteirosModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [pathPdfView, setPathPdfView] = useState(null);
  const { isFullScreen, toggleFullScreen } = useFullScreenToggle();
  const [ordemDiaModal, setOrdemDiaModal] = useState(false);

  const { camara } = useCamaraContext();

  const { logout } = useContext(AuthContext);

  const toggleShowLogoutModal = () => setLogoutModal(!logoutModal);
  const toggleShowSettingsModal = () => setSettingsModal(!settingsModal);
  const toggleShowRoteirosModal = () => setRoteirosModal(!roteirosModal);
  const toggleShowPdfModal = () => {
    toggleFullScreen();
    setPdfModal(!pdfModal);
    pathPdfView(null);
  };

  function exitFullscreenMode() {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  function enterFullscreenMode() {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  const handleOpenPdf = (item) => {
    setPdfModal(true);
    setRoteirosModal(false);
    setPathPdfView(item.Path);
    exitFullscreenMode();
  };

  const handleLogout = () => {
    logout();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") {
      setOldPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setconfirmNewPassword(value);
    }
  };

  const handleChangePassword = async () => {
    if (!oldPassword || !newPassword || !confirmNewPassword)
      return notify("Preencha todos os campos", "warning");

    if (
      oldPassword.length < 4 ||
      newPassword.length < 4 ||
      confirmNewPassword.length < 4
    )
      return notify(
        "Preencha os campos com pelo menos 4 caracteres",
        "warning"
      );

    if (newPassword !== confirmNewPassword)
      return notify(
        "A nova senha não corresponde com a confirmação",
        "warning"
      );

    await changePasswordUser({
      oldPassword,
      newPassword,
    });

    setOldPassword("");
    setNewPassword("");
    setconfirmNewPassword("");
    setSettingsModal(false);
  };

  const toggleShowParlamentarMateriaModal = async () => {
    const response = await getAllMateriasFromParlamentarId(parlamentar.id);
    if (response.length === 0) {
      notify("O parlamentar ainda não propôs nenhuma matéria.", "warning");
    } else {
      setParlamentarMaterias(response);
      setParlamentarMateriaModal(!parlamentarMateriaModal);
    }
  };

  const toggleShowSessaoMateriaModal = async () => {
    if (sessao) {
      const response = await getMateriasBySessaoId(sessao.id);

      if (response) {
        setSessaoMaterias(response.filter((materia) => !materia.OrdemDia));
      } else notify("Não há nenhuma sessão em andamento.", "warning");

      setSessaoMateriaModal(!sessaoMateriaModal);
    } else {
      notify("Não há nenhuma sessão em andamento.", "warning");
    }
    setOrdemDiaModal(false);
  };

  const handleOrdemDiaBtn = async () => {
    if (sessao) {
      const response = await getMateriasBySessaoId(sessao.id);

      if (response) {
        setSessaoMaterias(response.filter((materia) => materia.OrdemDia == true));
      } else notify("Não há nenhuma sessão em andamento.", "warning");

      setOrdemDiaModal(!ordemDiaModal);
    } else {
      notify("Não há nenhuma sessão em andamento.", "warning");
    }
  };

  const toggleShowParlamentarStatistic = async () => {
    const response = await getAllMateriasFromParlamentarId(parlamentar.id);
    setStatisticsParlamentar(response);
    loadParlamentarInfo(parlamentar);
    setStatisticsParlamentarModal(!statisticsParlamentarModal);
  };

  useEffect(() => {
    if (camara) fetchData();
  }, [camara]);

  async function loadRoteiros() {
    if (sessao && parlamentar) {
      const roteirosResponse = await getRoteiroByParlamentarIdAndSessaoId(
        parlamentar.id,
        sessao.id
      );

      setRoteiros(roteirosResponse ?? []);
    }
  }

  useEffect(() => {
    loadRoteiros();
  }, [sessao, parlamentar]);

  async function fetchData() {
    const parlamentarsReponse = await getParlamentarsByCamaraId(camara.id);

    setParlamentars(parlamentarsReponse);
  }

  async function loadParlamentarInfo(parlamentar) {
    setParlamentarInfo(parlamentar);

    const response = await getAllMateriasFromParlamentarId(parlamentar.id);
    const responseMapped = calculePercentageVoteFromResponse(response);
    responseMapped.forEach((e, index) => {
      if (e.Votos) {
        const parlamentarVoto = e.Votos.find(
          (voto) => voto.ParlamentarId === parlamentar.id
        );

        responseMapped[index].ParlamentarVoto = parlamentarVoto;
      }
    });

    setMateriasParlamentar(responseMapped);

    const statisticsParlamentarResponse = await getStatisticsByParlamentarId(
      parlamentar.id
    );

    setStatisticsParlamentar(statisticsParlamentarResponse);
  }

  function calculePercentageVoteFromResponse(response) {
    response &&
      response.forEach((e, index) => {
        if (e.Votos && e.Votos.length > 0) {
          const totalVotos = e.Votos.length;
          const totalAfavor = e.Votos.filter(
            (v) => v.Status === "A favor"
          ).length;
          const totalContra = e.Votos.filter(
            (v) => v.Status === "Contra"
          ).length;
          const totalSeAbsteve = e.Votos.filter(
            (v) => v.Status === "Se absteve"
          ).length;

          let porcentagemAfavor = (totalAfavor / totalVotos) * 100;
          let porcentagemContra = (totalContra / totalVotos) * 100;
          let porcentagemSeAbsteve = (totalSeAbsteve / totalVotos) * 100;

          response[index].TotalAfavor = totalAfavor;
          response[index].TotalContra = totalContra;
          response[index].TotalSeAbsteve = totalSeAbsteve;

          response[index].PorcentagemAfavor =
            porcentagemAfavor % 1 === 0
              ? porcentagemAfavor.toFixed(0)
              : porcentagemAfavor.toFixed(2);
          response[index].PorcentagemContra =
            porcentagemContra % 1 === 0
              ? porcentagemContra.toFixed(0)
              : porcentagemContra.toFixed(2);
          response[index].PorcentagemSeAbsteve =
            porcentagemSeAbsteve % 1 === 0
              ? porcentagemSeAbsteve.toFixed(0)
              : porcentagemSeAbsteve.toFixed(2);
        }
      });

    return response;
  }

  return (
    <>
      <MDBDropdown dropleft>
        <MDBDropdownToggle
          tag="a"
          className="d-flex align-items-center"
          href="#"
          id="iconDropdown"
        >
          <img
            src={
              parlamentar.image
                ? `${process.env.REACT_APP_API_URL}/${parlamentar.image}`
                : `/user.png`
            }
            className="parlamentarIcon"
            height="55"
            alt="Avatar"
          />
        </MDBDropdownToggle>
        <MDBDropdownMenu style={{ width: "19rem" }}>
          <MDBDropdownItem link childTag="span" id="nameDropdown">
            <MDBRow className="me-1">
              <MDBCol md="8">
                <div className="ms-3 text-overflow">
                  <p className="fw-bold mb-1">
                    {parlamentar.nickname ?? parlamentar.name}
                  </p>
                  <p className="text-muted mb-0">{parlamentar.partido}</p>
                  <p className="text-muted mb-0">{parlamentar.occupation}</p>
                </div>
              </MDBCol>
              <MDBCol md="2" className="d-flex align-self-center">
                <MDBIcon
                  onClick={toggleShowSettingsModal}
                  id="btnSettings"
                  fas
                  icon="gear"
                  size="2x"
                  title="Configurações"
                />
              </MDBCol>
              <MDBCol md="2" className="d-flex align-self-center">
                <MDBIcon
                  onClick={toggleShowLogoutModal}
                  id="btnLogout"
                  fas
                  icon="sign-out-alt"
                  size="2x"
                  color="danger"
                  title="Sair da conta"
                />
              </MDBCol>
            </MDBRow>
          </MDBDropdownItem>
          <MDBDropdownItem
            onClick={toggleShowParlamentarMateriaModal}
            link
            className="bg-primary"
            id="verMateriaDropdown"
          >
            <div className="text-white">
              <MDBIcon fas icon="eye" /> TODAS MATÉRIAS PROPOSTAS <br /> POR{" "}
              {parlamentar.nickname ?? parlamentar.name}
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem
            onClick={handleOrdemDiaBtn}
            link
            className="bg-warning"
            id="verMateriaSessaoDropdown"
          >
            <div className="text-white">
              <MDBIcon fas icon="eye" /> ORDEM DO DIA
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem
            onClick={toggleShowSessaoMateriaModal}
            link
            className="bg-info"
            id="verMateriaSessaoDropdown"
          >
            <div className="text-white">
              <MDBIcon fas icon="eye" /> PAUTAS DESTA SESSÃO
            </div>
          </MDBDropdownItem>
          <MDBDropdownItem
            onClick={toggleShowParlamentarStatistic}
            link
            className="bg-secondary"
            id="verEstatísticas"
          >
            <div className="text-white">
              <MDBIcon fas icon="eye" /> VER ESTATÍSTICAS
            </div>
          </MDBDropdownItem>
          {roteiros && roteiros.length > 0 ? (
            <MDBDropdownItem
              onClick={toggleShowRoteirosModal}
              link
              className="bg-success"
              id="verEstatísticas"
            >
              <div className="text-white">
                <MDBIcon fas icon="file" /> MEUS ROTEIROS
              </div>
            </MDBDropdownItem>
          ) : (
            <></>
          )}
        </MDBDropdownMenu>
      </MDBDropdown>
      <MDBModal
        show={parlamentarMateriaModal}
        setShow={setParlamentarMateriaModal}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                TODAS MATÉRIAS PROPOSTAS POR{" "}
                {parlamentar.nickname ?? parlamentar.name}
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowParlamentarMateriaModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {parlamentarMaterias.map((item) => (
                <MDBListGroupItem key={item.id}>
                  <MDBRow>
                    <MDBCol size={10}>
                      <h5 className="fw-bold">
                        {item.Tipo} - {item.Numero}
                      </h5>
                    </MDBCol>
                    {item.Path && (
                      <MDBCol size={2}>
                        <MDBBtn
                          onClick={() => handleOpenPdf(item)}
                          color="danger"
                        >
                          <MDBIcon fas size={"2x"} icon="file-pdf" />
                        </MDBBtn>
                      </MDBCol>
                    )}
                  </MDBRow>

                  {item.hasOwnProperty("Approved") &&
                    (item.Approved ? (
                      <MDBBadge color="success" light>
                        APROVADA
                      </MDBBadge>
                    ) : (
                      <MDBBadge color="danger" light>
                        REPROVADA
                      </MDBBadge>
                    ))}
                  <p className="text-muted mb-2 fw-bold">
                    {new Date(item.Data).toLocaleDateString("pt-BR", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>
                  <p className="text-muted mb-0">{item.Resumo}</p>
                </MDBListGroupItem>
              ))}
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={sessaoMateriaModal}
        setShow={setSessaoMateriaModal}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                PAUTAS DESTA SESSÃO
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowSessaoMateriaModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBListGroup
                style={{ minWidth: "22rem" }}
                light
                className="mb-3"
              >
                {sessaoMaterias.map((item) => (
                  <MDBListGroupItem key={item.id}>
                    <MDBRow>
                      <MDBCol size={10}>
                        <h5 className="fw-bold">
                          {item.Tipo} - {item.Numero}
                        </h5>
                      </MDBCol>
                      {item.Path && (
                        <MDBCol size={2}>
                          <MDBBtn
                            onClick={() => handleOpenPdf(item)}
                            color="danger"
                          >
                            <MDBIcon fas size={"2x"} icon="file-pdf" />
                          </MDBBtn>
                        </MDBCol>
                      )}
                    </MDBRow>
                    <p className="text-muted mb-2 fw-bold">
                      {new Date(item.Data).toLocaleDateString("pt-BR", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                    <p className="text-muted mb-0">{item.Resumo}</p>
                  </MDBListGroupItem>
                ))}
              </MDBListGroup>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={ordemDiaModal}
        setShow={setOrdemDiaModal}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                ORDEM DO DIA
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={handleOrdemDiaBtn}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBListGroup
                style={{ minWidth: "22rem" }}
                light
                className="mb-3"
              >
                {sessaoMaterias.map((item) => (
                  <MDBListGroupItem key={item.id}>
                    <MDBRow>
                      <MDBCol size={10}>
                        <h5 className="fw-bold">
                          {item.Tipo} - {item.Numero}
                        </h5>
                      </MDBCol>
                      {item.Path && (
                        <MDBCol size={2}>
                          <MDBBtn
                            onClick={() => handleOpenPdf(item)}
                            color="danger"
                          >
                            <MDBIcon fas size={"2x"} icon="file-pdf" />
                          </MDBBtn>
                        </MDBCol>
                      )}
                    </MDBRow>
                    <p className="text-muted mb-2 fw-bold">
                      {new Date(item.Data).toLocaleDateString("pt-BR", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                    <p className="text-muted mb-0">{item.Resumo}</p>
                  </MDBListGroupItem>
                ))}
              </MDBListGroup>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal
        show={statisticsParlamentarModal}
        setShow={setStatisticsParlamentarModal}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>ESTATÍSTICAS</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowParlamentarStatistic}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBListGroup
                style={{ minWidth: "22rem" }}
                light
                className="mb-3"
              >
                {statisticsParlamentar && (
                  <>
                    <MDBTypography
                      tag="div"
                      className="mt-4 fs-5 pb-3 mb-3 border-bottom text-center"
                    >
                      QUANTIDADE DE VOTOS EM MATÉRIAS
                    </MDBTypography>
                    <MDBCol
                      size={12}
                      lg={12}
                      className="d-flex justify-content-center"
                    >
                      {statisticsParlamentar &&
                        statisticsParlamentar.CountVotesParlamentar && (
                          <TinyBarChart
                            countVotes={
                              statisticsParlamentar.CountVotesParlamentar
                            }
                          />
                        )}
                    </MDBCol>

                    <MDBTypography
                      tag="div"
                      className="mt-4 fs-5 pb-3 mb-3 border-bottom text-center"
                    >
                      PRESENÇAS POR SESSÃO
                    </MDBTypography>
                    <MDBCol
                      size={12}
                      lg={12}
                      className="d-flex justify-content-center"
                    >
                      <PieChart
                        data={[
                          {
                            name: "Presente",
                            value: statisticsParlamentar.CountPresence,
                            color: "#28a744",
                          },
                          {
                            name: "Ausente",
                            value:
                              statisticsParlamentar.CountSessao -
                              statisticsParlamentar.CountPresence,
                            color: "#dc3546",
                          },
                        ]}
                      />
                    </MDBCol>
                    <MDBTypography
                      tag="div"
                      className="mt-4 fs-5 pb-3 mb-3 border-bottom text-center"
                    >
                      QUANTIDADE DE MATÉRIAS PROPOSTAS
                    </MDBTypography>
                    <MDBCol
                      size={12}
                      lg={12}
                      className="d-flex justify-content-center"
                    >
                      <PieChart
                        data={[
                          {
                            name: "Propostas",
                            value:
                              statisticsParlamentar.CountMateriasByParlamentar,
                            color: "#28a744",
                          },
                          {
                            name: "Total",
                            value:
                              statisticsParlamentar.CountMaterias -
                              statisticsParlamentar.CountMateriasByParlamentar,
                            color: "#e4a11c",
                          },
                        ]}
                      />
                    </MDBCol>
                  </>
                )}
              </MDBListGroup>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal show={settingsModal} setShow={setSettingsModal} tabIndex="">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Alterar senha</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowSettingsModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {" "}
              <MDBCol className="d-flex flex-column">
                <MDBInput
                  label="Senha Atual"
                  type="password"
                  required={true}
                  className="mt-3"
                  style={{ minWidth: "20rem" }}
                  name="oldPassword"
                  onChange={handleInputChange}
                  value={oldPassword}
                />
                <MDBInput
                  label="Nova Senha"
                  type="password"
                  required={true}
                  className="mt-3"
                  style={{ minWidth: "20rem" }}
                  name="newPassword"
                  onChange={handleInputChange}
                  value={newPassword}
                />
                <MDBInput
                  label="Repetir Senha"
                  type="password"
                  required={true}
                  className="mt-3"
                  style={{ minWidth: "20rem" }}
                  name="confirmNewPassword"
                  onChange={handleInputChange}
                  value={confirmNewPassword}
                />
              </MDBCol>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn onClick={handleChangePassword} color="warning">
                <MDBIcon fas icon="pen" /> Alterar senha
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal show={logoutModal} setShow={setLogoutModal} tabIndex="">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Sair da conta</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowLogoutModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>Realmente deseja sair?</MDBModalBody>

            <MDBModalFooter>
              <MDBBtn onClick={handleLogout} color="danger">
                <MDBIcon fas icon="sign-out-alt" /> Sair
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal show={roteirosModal} setShow={setRoteirosModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>ROTEIROS PARA A SESSÃO ATUAL</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShowRoteirosModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBListGroup
                style={{ minWidth: "22rem" }}
                light
                className="mb-3"
              >
                {roteiros.map((item) => (
                  <MDBListGroupItem key={item.id}>
                    <MDBRow className="">
                      <MDBCol size={10}>
                        <h5 className="fw-bold">{item.Numero}</h5>
                      </MDBCol>
                      {item.Path && (
                        <MDBCol size={2}>
                          <MDBBtn
                            onClick={() => handleOpenPdf(item)}
                            color="danger"
                          >
                            <MDBIcon fas size={"2x"} icon="file-pdf" />
                          </MDBBtn>
                        </MDBCol>
                      )}
                    </MDBRow>
                    <p className="text-muted mb-0">{item.Resumo}</p>
                  </MDBListGroupItem>
                ))}
              </MDBListGroup>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      <MDBModal tabIndex="-1" show={pdfModal} setShow={setPdfModal}>
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBBtn
                type="button"
                className="btn-close"
                color="none"
                onClick={toggleShowPdfModal}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {pathPdfView && (
                <PdfView
                  key={pathPdfView}
                  url={`${process.env.REACT_APP_API_URL}/${pathPdfView}`}
                />
              )}
            </MDBModalBody>
            <MDBModalFooter>
              <MDBBtn
                type="button"
                color="primary"
                onClick={toggleShowPdfModal}
              >
                Fechar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default ParlamentarSidebar;
