import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { api, createSessionController, getCamaraByCity } from "../services/api";
import { getCityByUrl, goToLogin, isPainel } from "../common/util";
import { notify } from "../common/toastConfig";

export const AuthContextController = createContext();

export const AuthProviderController = ({ children }) => {
  const navigate = useNavigate();
  const [controllerUser, setControllerUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const recoveredUser = localStorage.getItem("controllerUser");
    const token = localStorage.getItem("controllerToken");

    if (recoveredUser && token) {
      setControllerUser(JSON.parse(recoveredUser));
      api.defaults.headers.Authorization = "Bearer " + token;
    }

    setLoading(false);
    checkLoginByCity();
  }, []);

  async function checkLoginByCity() {
    const city = getCityByUrl();
    const camara = await getCamaraByCity(city);
    const user = JSON.parse(localStorage.getItem("controllerUser"));

    const fullUrl = window.location.href;
    const isRealTimerReport = fullUrl
      .toLowerCase()
      .includes("relatoriotemporeal");

    if (!isRealTimerReport && user && camara && user.camaraId !== camara.id) {
      if (!isPainel()) {
        localStorage.removeItem("controllerUser");
        localStorage.removeItem("controllerToken");
        api.defaults.headers.Authorization = null;
        goToLogin();
      }
    } else return true;
  }

  const login = async (email, password) => {
    setLoading(true);
    const response = await createSessionController(email, password);
    setLoading(false);

    if (response.data.status != "success")
      return notify(response.data.message, "danger");

    const token = response.data.token;

    const loggedUser = {
      email: email,
      id: response.data.id,
      camaraId: response.data.camaraId,
    };

    localStorage.setItem("controllerUser", JSON.stringify(loggedUser));
    localStorage.setItem("controllerToken", token);

    api.defaults.headers.Authorization = "Bearer " + token;

    setControllerUser({
      id: loggedUser.id,
      email: loggedUser.email,
      camaraId: loggedUser.camaraId,
    });

    if (checkLoginByCity()) navigate(`/${getCityByUrl()}/controlador`);
  };

  const logout = () => {
    setControllerUser(null);
    localStorage.removeItem("controllerUser");
    localStorage.removeItem("controllerToken");
    api.defaults.headers.Authorization = null;
    navigate(`/${getCityByUrl()}/controlador/login`);
  };

  return (
    <AuthContextController.Provider
      value={{
        authenticated: !!controllerUser,
        controllerUser,
        loading,
        login,
        logout,
      }}
    >
      {children}
    </AuthContextController.Provider>
  );
};
