import React, { useState, useContext, useEffect } from "react";
import "../../index.css";
import "./Account.css";
import {
  MDBBtn,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBCardTitle,
  MDBIcon,
} from "mdb-react-ui-kit";
import { notify } from "../../common/toastConfig";
import { changePasswordControlador } from "../../services/api";

const Account = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") {
      setOldPassword(value);
    } else if (name === "newPassword") {
      setNewPassword(value);
    } else if (name === "confirmNewPassword") {
      setconfirmNewPassword(value);
    }
  };

  const handlePasswords = async (e) => {
    e.preventDefault();

    if (newPassword === "" || confirmNewPassword === "" || oldPassword === "")
      return notify("Todos os campos são obrigatórios", "warning");

    if (
      oldPassword.length < 4 ||
      newPassword.length < 4 ||
      confirmNewPassword.length < 4
    )
      return notify(
        "Preencha os campos com pelo menos 4 caracteres",
        "warning"
      );

    if (newPassword !== confirmNewPassword)
      return notify(
        "A nova senha não corresponde com a confirmação",
        "warning"
      );

    const body = {
      oldPassword,
      newPassword,
      confirmNewPassword,
      controladorId: 1,
    };

    await changePasswordControlador(body);

    setOldPassword("");
    setNewPassword("");
    setconfirmNewPassword("");
  };

  return (
    <>
      <form>
        <MDBContainer className="d-flex align-items-center ">
          <MDBCol md="6" className="imgChangePassword">
            <img
              src="/change-password.jpg"
              alt="Ilustração"
              height={"80%"}
              width={"93%"}
            />
          </MDBCol>
          <MDBCol className="d-flex flex-column align-items-center">
            <MDBCardTitle className="text-center">
              Atualização de Senha
            </MDBCardTitle>
            <MDBCol classname="d-flex flex-column">
              <MDBInput
                label="Senha Atual"
                type="password"
                required={true}
                className="mt-3"
                style={{ minWidth: "20rem" }}
                name="oldPassword"
                onChange={handleInputChange}
                value={oldPassword}
              />
              <MDBInput
                label="Nova Senha"
                type="password"
                required={true}
                className="mt-3"
                style={{ minWidth: "20rem" }}
                name="newPassword"
                onChange={handleInputChange}
                value={newPassword}
              />
              <MDBInput
                label="Repetir Senha"
                type="password"
                required={true}
                className="mt-3"
                style={{ minWidth: "20rem" }}
                name="confirmNewPassword"
                onChange={handleInputChange}
                value={confirmNewPassword}
              />
            </MDBCol>
            <MDBBtn className="mt-3" color="warning" onClick={handlePasswords}>
              <MDBIcon icon="key" className="m-1" />
              Alterar Senha
            </MDBBtn>
          </MDBCol>
        </MDBContainer>
      </form>
    </>
  );
};

export default Account;
