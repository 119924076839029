import "./SessaoInfo.css";
import React, { useState } from "react";
import { MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";
import { useCamaraContext } from "../../../context/camaraContext";

const SessaoInfo = (props) => {
  const { camara } = useCamaraContext();
  const sessao = props.sessao;

  return (
    <>
      {camara && (
        <>
          {sessao ? (
            <span className="d-flex justify-content-center d-flex align-items-center">
              <MDBRow className="sessaoInfo infoSessaoRealTime m-4">
                <MDBCol md="12" className="mb-3">
                  <h3 className="fw-bold text-center">
                    SESSÃO {sessao.Numero}
                  </h3>
                </MDBCol>
                <MDBCol sm="6" md="6" className="mb-4">
                  <label>
                    <MDBIcon fas icon="spell-check" /> Número da sessão
                  </label>
                  <input
                    disabled
                    value={sessao.Numero}
                    type="text"
                    className="custom-input form-control"
                  />
                </MDBCol>
                <MDBCol sm="6" md="6" className="mb-4">
                  <label>
                    <MDBIcon fas icon="calendar-check" /> Data da sessão
                  </label>
                  <input
                    disabled
                    value={sessao.Data}
                    type="date"
                    className="custom-input form-control"
                  />
                </MDBCol>
                <MDBCol sm="6" md="6" className="mb-4">
                  <label>
                    <MDBIcon fas icon="caret-down" /> Tipo da sessão
                  </label>
                  <input
                    disabled
                    value={sessao.Tipo}
                    type="text"
                    className="custom-input form-control"
                  />
                </MDBCol>
                <MDBCol sm="6" md="6" className="mb-4">
                  <label>
                    <MDBIcon fas icon="caret-down" /> Situação da sessão
                  </label>
                  <input
                    disabled
                    value={sessao.Situacao}
                    type="text"
                    className="custom-input form-control"
                  />
                </MDBCol>
                <MDBCol sm="12" md="12" className="mb-4">
                  <label>
                    <MDBIcon far icon="comment-alt" /> Descrição da sessão
                  </label>
                  <textarea
                    value={sessao.Descricao}
                    disabled
                    className="custom-input form-control"
                    rows="9"
                  />
                </MDBCol>
              </MDBRow>
            </span>
          ) : (
            <MDBRow className="sessao-not-found-container">
              <MDBRow className="sessaoInfo d-flex align-items-center justify-content-center">
                <MDBCol
                  md="12"
                  className="shadow-sm rounded-9 bg-light text-center d-flex justify-content-center d-flex align-items-center"
                  style={{
                    width: "90vw",
                    height: "40vh",
                    borderTop: "2px solid #ffc107",
                    borderBottom: "2px solid #ffc107",
                  }}
                >
                  <h1 className="pt-4 fs-1 text-warning">
                    <MDBIcon fas icon="exclamation-triangle" />
                    &nbsp; NÃO HÁ SESSÃO EM ANDAMENTO
                  </h1>
                </MDBCol>
              </MDBRow>
            </MDBRow>
          )}
        </>
      )}
    </>
  );
};

export default SessaoInfo;
