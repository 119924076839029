import "./TinyBarChart.css";
import React from "react";
import {
  BarChart,
  Bar,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Label,
} from "recharts";
import { MDBIcon } from "mdb-react-ui-kit";

const colors = ["#00C49F", "#FFBB28", "#dc4c64"];

const TinyBarChart = (props) => {
  const { InFavor, Abstained, Against } = props.countVotes;

  if (!InFavor && !Abstained && !Against)
    return (
      <span>
        <MDBIcon fas icon="exclamation-circle" color="warning" /> Nenhuma
        matéria foi votada por este parlamentar
      </span>
    );

  const data = [
    {
      name: "A favor",
      total: InFavor ?? 0,
    },
    {
      name: "Se absteve",
      total: Abstained ?? 0,
    },
    {
      name: "Contra",
      total: Against ?? 0,
    },
  ];

  return (
    <BarChart
      width={350}
      height={200}
      data={data}
      barSize={40}
      margin={{ left: -20 }}
    >
      <XAxis dataKey="name"></XAxis>
      <YAxis />
      <Tooltip />
      <Legend />
      <CartesianGrid strokeDasharray="3 3" />
      <Bar dataKey="total" fill="none">
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % 20]} />
        ))}
      </Bar>
    </BarChart>
  );
};

export default TinyBarChart;
