import "./MateriaInfo.css";
import React, { useEffect, useState } from "react";
import {
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBBadge,
} from "mdb-react-ui-kit";
import { useCamaraContext } from "../../../context/camaraContext";
import { getMateriaSituationById } from "../../../services/api";

const MateriaInfo = (props) => {
  const [situation, setSituation] = useState(null);
  const { camara } = useCamaraContext();
  const { materia, presenca } = props;

  async function loadSituation() {
    if (materia) {
      const materiaSituationResponse = await getMateriaSituationById(
        materia.id
      );

      setSituation(materiaSituationResponse.situation);
    }
  }

  useEffect(() => {
    loadSituation();
  }, [materia]);

  return (
    <MDBRow className="materiaInfo m-4 p-2 py-1 shadow">
      <MDBCol key={materia.id} xl={12} lg={12} className="mb-3">
        <MDBRow className="mx-1">
          <MDBCol md="12" className="mb-3">
            <h3 className="fw-bold text-center">MATÉRIA {materia.Numero}</h3>
          </MDBCol>
          <MDBCol sm="4" md="4">
            <label>
              <MDBIcon fas icon="spell-check" /> Número da matéria
            </label>
            <input
              disabled
              value={materia.Numero}
              type="text"
              className="custom-input form-control"
            />
          </MDBCol>
          <MDBCol sm="4" md="4">
            <label>
              <MDBIcon fas icon="calendar-check" /> Data da matéria
            </label>
            <input
              disabled
              value={materia.Data}
              type="date"
              className="custom-input form-control"
            />
          </MDBCol>
          <MDBCol sm="4" md="4" className="">
            <label>
              <MDBIcon fas icon="caret-down" /> Tipo da matéria
            </label>
            <input
              disabled
              value={materia.Tipo}
              type="text"
              className="custom-input form-control"
            />
          </MDBCol>
          <MDBCol sm="12" md="12" className="mt-3">
            <label>
              <MDBIcon far icon="comment-alt" /> Resumo da matéria
            </label>
            <textarea
              value={materia.Resumo}
              disabled
              className="custom-input form-control"
              rows="2"
            />
          </MDBCol>
          <MDBCol md="12" className="mb-3">
            <h4 className="text-center">AUTORES DA MATÉRIA</h4>
          </MDBCol>
          <MDBCol md="12" className="mb-3">
            <MDBRow className="d-flex justify-content-center mb-3">
              {materia.ParlamentarMateria.map((materia) => (
                <MDBCol key={materia.id} xl={3} lg={6} className="mb-3">
                  <MDBCard
                    border={(() => {
                      switch (materia.Situacao) {
                        case "Presente":
                          return "success";
                        case "Ausente":
                          return "warning";
                        case "Licença":
                          return "primary";
                      }
                    })()}
                    background="#dddddd"
                  >
                    <MDBCardBody>
                      {materia.Name ? (
                        <div className="d-flex align-materias-center">
                          <img
                            src="/user.png"
                            alt=""
                            style={{
                              width: "45px",
                              height: "45px",
                            }}
                            className="rounded-circle"
                          />
                          <div className={`ms-3 text-overflow`}>
                            <p className="fw-bold mb-1 text-overflow">
                              {materia.Name}
                            </p>
                            <p className="text-muted mb-0 text-overflow">
                              {materia.Situacao && (
                                <MDBBadge
                                  pill
                                  color={(() => {
                                    switch (materia.Situacao) {
                                      case "Presente":
                                        return "success";
                                      case "Ausente":
                                        return "warning";
                                      case "Licença":
                                        return "primary";
                                    }
                                  })()}
                                  light
                                >
                                  {materia.Situacao}
                                </MDBBadge>
                              )}{" "}
                              Autor Externo
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex align-materias-center">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/${materia.Parlamentar.image}`}
                            alt=""
                            style={{
                              width: "45px",
                              height: "45px",
                            }}
                            className="rounded-circle"
                          />
                          <div className={`ms-3 text-overflow`}>
                            <p className="fw-bold mb-1 text-overflow">
                              {materia.Parlamentar.nickname ??
                                materia.Parlamentar.name}
                            </p>
                            <p className="fw-bold mb-1 text-overflow">
                              {materia.Parlamentar.partido}
                            </p>
                            <p className="text-muted mb-0 text-overflow">
                              {materia.Situacao && (
                                <MDBBadge
                                  pill
                                  color={(() => {
                                    switch (materia.Situacao) {
                                      case "Presente":
                                        return "success";
                                      case "Ausente":
                                        return "warning";
                                      case "Licença":
                                        return "primary";
                                    }
                                  })()}
                                  light
                                >
                                  {materia.Situacao}
                                </MDBBadge>
                              )}{" "}
                              {materia.Parlamentar.occupation}
                            </p>
                          </div>
                        </div>
                      )}
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              ))}
            </MDBRow>
          </MDBCol>
          <MDBCol md="12" className="mb-3">
            <h4 className="text-center">RESULTADO DA VOTAÇÃO</h4>
          </MDBCol>
          <MDBCol md="12" className="mb-3 d-flex justify-content-center fs-3">
            {materia && situation === "Reprovada" && (
              <MDBBadge color={"danger"} light>
                REPROVADA
              </MDBBadge>
            )}
            {materia && situation === "Aprovada" && (
              <MDBBadge color={"success"} light>
                APROVADA
              </MDBBadge>
            )}
            {materia && situation === "Em votação" && (
              <MDBBadge color={"primary"} light>
                EM VOTAÇÃO
              </MDBBadge>
            )}
            {materia && situation === "Não votada" && (
              <MDBBadge color={"primary"} light>
                NÃO VOTADA
              </MDBBadge>
            )}
          </MDBCol>
          {materia.Votos.length === 0 ? (
            ""
          ) : (
            <>
              <MDBCol md="4" className="mb-3">
                <MDBBadge
                  className="fs-4 d-flex justify-content-center"
                  pill
                  color={"success"}
                  light
                >
                  {(() => {
                    const favorCount = materia.Votos.filter(
                      (voto) => voto.Status === "A favor"
                    ).length;
                    const totalCount = materia.Votos.length;
                    const percentage = (favorCount / totalCount) * 100;

                    if (percentage % 1 === 0) {
                      return percentage.toFixed(0);
                    } else {
                      return percentage.toFixed(2);
                    }
                  })().replace(".", ",")}
                  % A favor
                </MDBBadge>
              </MDBCol>
              <MDBCol md="4" className="mb-3">
                <MDBBadge
                  className="fs-4 d-flex justify-content-center"
                  pill
                  color={"warning"}
                  light
                >
                  {(() => {
                    const abstainCount = materia.Votos.filter(
                      (voto) => voto.Status === "Se absteve"
                    ).length;
                    const totalCount = materia.Votos.length;
                    const percentage = (abstainCount / totalCount) * 100;

                    if (percentage % 1 === 0) {
                      return percentage.toFixed(0);
                    } else {
                      return percentage.toFixed(2);
                    }
                  })().replace(".", ",")}
                  % Se absteve
                </MDBBadge>
              </MDBCol>
              <MDBCol md="4" className="mb-3">
                <MDBBadge
                  className="fs-4 d-flex justify-content-center"
                  pill
                  color={"danger"}
                  light
                >
                  {(() => {
                    const againstCount = materia.Votos.filter(
                      (voto) => voto.Status === "Contra"
                    ).length;
                    const totalCount = materia.Votos.length;
                    const percentage = (againstCount / totalCount) * 100;

                    if (percentage % 1 === 0) {
                      return percentage.toFixed(0);
                    } else {
                      return percentage.toFixed(2);
                    }
                  })().replace(".", ",")}
                  % Contra
                </MDBBadge>
              </MDBCol>
            </>
          )}
          <MDBCol md="12" className="mb-3">
            <MDBRow className="d-flex justify-content-center">
              {materia.Votos.map((voto) => {
                const parlamentarVoto = presenca.find(
                  (e) => e.ParlamentarId == voto.ParlamentarId
                );

                return (
                  <MDBCol xl={3} lg={6} className="d-flex mb-3" key={voto.id}>
                    <div className="d-inline-flex position-relative">
                      <MDBBadge
                        className={(() => {
                          switch (voto.Status) {
                            case "A favor":
                              return "bg-success position-absolute top-50 start-1 translate-middle p-1 border border-light rounded-circle";
                            case "Se absteve":
                              return "bg-warning position-absolute top-50 start-1 translate-middle p-1 border border-light rounded-circle";
                            case "Contra":
                              return "bg-danger position-absolute top-50 start-1 translate-middle p-1 border border-light rounded-circle";
                          }
                        })()}
                        style={{ width: "1.4rem", zIndex: "9999" }}
                      >
                        <MDBIcon
                          fas
                          icon={(() => {
                            switch (voto.Status) {
                              case "A favor":
                                return "check";
                              case "Se absteve":
                                return "circle";
                              case "Contra":
                                return "times";
                            }
                          })()}
                        />
                      </MDBBadge>
                      {parlamentarVoto && (
                        <MDBCol>
                          <MDBCard>
                            <MDBCardBody>
                              <div className="d-flex align-materias-center">
                                <img
                                  src={`${process.env.REACT_APP_API_URL}/${parlamentarVoto.Parlamentar.image}`}
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                  }}
                                  className="rounded-circle "
                                />
                                <div className={`ms-3 text-overflow`}>
                                  <p className="fw-bold mb-1 text-overflow">
                                    {parlamentarVoto.Parlamentar.name}
                                  </p>
                                  <p className="text-muted mb-0 text-overflow">
                                    {parlamentarVoto.Parlamentar.occupation}
                                  </p>
                                </div>
                              </div>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      )}
                    </div>
                  </MDBCol>
                );
              })}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBCol>
    </MDBRow>
  );
};

export default MateriaInfo;
